import { HttpRequest, HttpResponse } from '../../http-request/model';
import {
	ICreateTimeTrackingBody,
	IOrderStatusBody,
	IOrderStatusService,
	IParamsTimeTracker,
	IParamsTimeTrackerWithBody,
	ITimeTrackingResponse,
	IUpdateManyStatusBody,
	TOrderStatus,
	TOrderStatusById,
} from './model';

export class OrderStatusService implements IOrderStatusService {
	private readonly strictHttpRequest: HttpRequest;

	constructor(strictHttpRequest: HttpRequest) {
		this.strictHttpRequest = strictHttpRequest;
	}

	getAll(): HttpResponse<Array<TOrderStatus>> {
		return this.strictHttpRequest
			.get<Array<TOrderStatus>>('/statuses/');
	}

	getByID(id: number): HttpResponse<TOrderStatusById> {
		return this.strictHttpRequest.get<TOrderStatusById>(`/statuses/${id}/`);
	}

	postStatus(body: IOrderStatusBody): HttpResponse<TOrderStatus> {
		return this.strictHttpRequest
			.post('/statuses/', body);
	}

	deleteStatus(pk: number): HttpResponse<void> {
		return this.strictHttpRequest
			.delete(`/statuses/${pk}/`);
	}

	putStatus(pk: number, body: Partial<IOrderStatusBody>): HttpResponse<TOrderStatus> {
		return this.strictHttpRequest
			.put(`/statuses/${pk}/`, body);
	}

	updateALLStatus(body: IUpdateManyStatusBody): HttpResponse<string> {
		return this.strictHttpRequest.patch('/credits/status/', body);
	}

	createTimeTracking({
		statusID,
		body,
	}: { statusID: number; body: ICreateTimeTrackingBody }): HttpResponse<ITimeTrackingResponse> {
		return this.strictHttpRequest.post(`/statuses/${statusID}/time_tracking/`, body);
	}

	deleteTimeTracking({
		statusID,
		pk,
	}: IParamsTimeTracker): HttpResponse<void> {
		return this.strictHttpRequest.delete(`/statuses/${statusID}/time_tracking/${pk}`);
	}

	getTimeTracking(statusID: number): HttpResponse<Array<ITimeTrackingResponse>> {
		return this.strictHttpRequest.get(`/statuses/${statusID}/time_tracking/`);
	}

	updateTimeTracking({
		statusID,
		pk,
		body,
	}: IParamsTimeTrackerWithBody): HttpResponse<ICreateTimeTrackingBody> {
		return this.strictHttpRequest.put(`/statuses/${statusID}/time_tracking/${pk}`, body);
	}
}
