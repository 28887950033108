import { AuthMetaServiceImpl } from '@/services/auth-meta-service/auth-meta-service-impl';
import { TOrganizationUserInfo } from '@/models/permission';
import { isSpectatorRole } from './user-role-check';

const Auth = new AuthMetaServiceImpl();

export const enum EPages {
  ORDERS = 'ORDERS',
  HANDBOOK = 'HANDBOOK',
  SETTINGS = 'SETTINGS',
  DASHBOARD = 'DASHBOARD',
  ADMIN = 'ADMIN',
  ERRROR = 'ERRROR',
}

export const hasUserPermissionToPages: () => () => boolean = () => () => Auth.hasOrgAuthMeta();
export const hasUserPermissionToAdminPage: () => boolean = () => Auth.hasOrgAuthMeta();

export const hasUserPermissionToOrders = hasUserPermissionToPages();
export const hasUserPermissionToUsers = hasUserPermissionToPages();
// eslint-disable-next-line max-len
export const hasUserPermissionToHandbooks = (organizationUserInfo?: TOrganizationUserInfo): boolean => Auth.hasOrgAuthMeta() && !isSpectatorRole(organizationUserInfo?.role?.slug);
export const hasUserPermissionToReports = hasUserPermissionToPages();
export const hasUserPermissionToSettings = hasUserPermissionToPages();
export const hasUserPermissionToDashboard = hasUserPermissionToPages();
export const hasUserPermissionToError = hasUserPermissionToPages();

/*
* @params pageName {EPages} возвращает boolean;
* */
export const checkPermission = (user: TOrganizationUserInfo) => (pageName: EPages) : boolean => {
  switch (pageName) {
    case EPages.DASHBOARD: {
      return true;
    }
    case EPages.ORDERS: {
      return true;
    }
    case EPages.HANDBOOK: {
      return !isSpectatorRole(user?.role?.slug);
    }
    case EPages.SETTINGS: {
      return user?.role?.is_admin;
    }
    case EPages.ADMIN: {
      return Auth.hasOrgAuthMeta();
    }
    case EPages.ERRROR: {
      return true;
    }
    default: return false;
  }
};
