import React from 'react';

export const BadgeCheckIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.26686 3.45498C6.91012 3.40362 7.52077 3.15061 8.01186 2.73198C8.56652 2.25957 9.27129 2.00012 9.99986 2.00012C10.7284 2.00012 11.4332 2.25957 11.9879 2.73198C12.4789 3.15061 13.0896 3.40362 13.7329 3.45498C14.4593 3.51304 15.1413 3.82794 15.6566 4.34325C16.1719 4.85855 16.4868 5.54055 16.5449 6.26698C16.5959 6.90998 16.8489 7.52098 17.2679 8.01198C17.7403 8.56664 17.9997 9.27141 17.9997 9.99998C17.9997 10.7286 17.7403 11.4333 17.2679 11.988C16.8492 12.4791 16.5962 13.0897 16.5449 13.733C16.4868 14.4594 16.1719 15.1414 15.6566 15.6567C15.1413 16.172 14.4593 16.4869 13.7329 16.545C13.0896 16.5963 12.4789 16.8493 11.9879 17.268C11.4332 17.7404 10.7284 17.9998 9.99986 17.9998C9.27129 17.9998 8.56652 17.7404 8.01186 17.268C7.52077 16.8493 6.91012 16.5963 6.26686 16.545C5.54043 16.4869 4.85843 16.172 4.34313 15.6567C3.82782 15.1414 3.51292 14.4594 3.45486 13.733C3.4035 13.0897 3.15049 12.4791 2.73186 11.988C2.25945 11.4333 2 10.7286 2 9.99998C2 9.27141 2.25945 8.56664 2.73186 8.01198C3.15049 7.52089 3.4035 6.91024 3.45486 6.26698C3.51292 5.54055 3.82782 4.85855 4.34313 4.34325C4.85843 3.82794 5.54043 3.51304 6.26686 3.45498ZM13.7069 8.70698C13.889 8.51838 13.9898 8.26578 13.9875 8.00358C13.9853 7.74138 13.8801 7.49057 13.6947 7.30516C13.5093 7.11976 13.2585 7.01459 12.9963 7.01231C12.7341 7.01003 12.4815 7.11082 12.2929 7.29298L8.99986 10.586L7.70686 9.29298C7.51826 9.11082 7.26565 9.01003 7.00346 9.01231C6.74126 9.01459 6.49045 9.11976 6.30504 9.30516C6.11963 9.49057 6.01446 9.74138 6.01219 10.0036C6.00991 10.2658 6.1107 10.5184 6.29286 10.707L8.29286 12.707C8.48039 12.8945 8.7347 12.9998 8.99986 12.9998C9.26502 12.9998 9.51933 12.8945 9.70686 12.707L13.7069 8.70698Z"
    />
  </svg>
);
