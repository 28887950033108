import React from 'react';
import ReactDOM from 'react-dom';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter } from 'react-router-dom';
import { ApplicationContextProvider } from '@/application-context';
import ThemeContextProvider from '@/containers/theme-context';
import { FeatureProvider } from '@/containers/feature-container/feature-context';
import { GlobalStateProvider } from './containers/global-state';
import { Pages } from './pages';
import { AppTheme } from './containers/app-theme';
import { CustomToasts } from './components/custom-toasts';
import contextCombiner from './helpers/combine-components/context-combiner';
import { SocketDataProvider } from './containers/socket-container';
import './stylings/bootstrap-grid.scss';
import './index.scss';

// Уведомления веб сокет обрабатываются в компоненте
// смотри src/components/socket-notification/index.tsx
// SocketDataProvider прокидывает данные веб сокет через контекст.
const AppContextsProvider = contextCombiner(
  ...[
    FeatureProvider,
    GlobalStateProvider,
    ApplicationContextProvider,
    SocketDataProvider,
    ThemeContextProvider,
  ],
);

ReactDOM.render(
  <BrowserRouter>
    <AppContextsProvider>
      <ToastProvider
        placement="top-right"
        components={{ Toast: CustomToasts }}
        autoDismiss
        autoDismissTimeout={6000}
      >
        <AppTheme />
        <Pages />
      </ToastProvider>
    </AppContextsProvider>
  </BrowserRouter>,
  document.getElementById('app'),
);
