import { MutableRefObject, useEffect, useRef } from 'react';
import { useStateIfMounted } from './useStateIfMounted';

export enum ESocketReadyState {
  CONNECTING = 'CONNECTING',
  OPEN = 'OPEN',
  CLOSING = 'CLOSING',
  CLOSED = 'CLOSED',
  IDLE = 'IDLE',
}

export interface useSocketProps {
  onopen?: () => string;
  onSend?: () => string;
  reloadTimer?: number;
  withReconnect?: boolean;
  reconnectCount?: number;
}

export interface ISocketError {
  reason: string;
  code: number;
}

export interface ISocketDate {
  readyState: ESocketReadyState;
  socketError: ISocketError;
  connect: any;
  socket: WebSocket | undefined;
  socketData?: MutableRefObject<any[]>;
  lastSocketData?: MessageEvent;
}

// Если надо использовать методы сокета вне хука,
// проверить wsRef.current перед методами send и message. (Использовать readyState === 'OPEN')
// Пример: readyState === 'OPEN' ? socket.send() : null;
export const useSocket = ({
  reloadTimer = 5000,
  withReconnect = true,
  reconnectCount = 0,
}: useSocketProps): ISocketDate => {
  const [readyState, setReadyState] = useStateIfMounted<
    ESocketReadyState>(ESocketReadyState.CLOSING);

  const [socketError, setSocketError] = useStateIfMounted<ISocketError | undefined>(undefined);
  const wsRef = useRef<WebSocket>();
  const [lastSocketData, setLastSocketData] = useStateIfMounted<MessageEvent>();
  const socketData = useRef<any[]>([]);
  const count = useRef(0);

  const getReadyState = (state: number): void => {
    switch (state) {
      case 0: {
        setReadyState(ESocketReadyState.CONNECTING);
        break;
      }
      case 1: {
        setReadyState(ESocketReadyState.OPEN);
        break;
      }
      case 2: {
        setReadyState(ESocketReadyState.CLOSING);
        break;
      }
      case 3: {
        setReadyState(ESocketReadyState.CLOSED);
        break;
      }
      default: {
        setReadyState(ESocketReadyState.IDLE);
        break;
      }
    }
  };

  useEffect(() => {
    getReadyState(-1);

    return () => {
      wsRef.current.close(1000, 'Работа закончена');
      setLastSocketData(null);
    };
  }, []);

  const connect = (url: string): void => {
    if (window.WebSocket && url) {
      // wsRef.current = new WebSocket(url);
      wsRef.current = new WebSocket(`${process.env.API_WSS}${url}`);
      const ws = wsRef.current;

      ws.onopen = () => {
        getReadyState(ws.readyState);
      };

      ws.onmessage = (eventMessage) => {
        getReadyState(ws.readyState);
        setLastSocketData(eventMessage);
        socketData.current = [...socketData.current, eventMessage?.data];
      };

      ws.onclose = (event) => {
        getReadyState(ws.readyState);
        let reason;

        if (event.wasClean) {
          setSocketError({
            reason: event.reason,
            code: event.code,
          });
          console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
        }

        if (event.code === 1000) {
          reason = 'Normal closure, meaning that the purpose for which the connection was established has been fulfilled.';
        } else if (event.code === 1001) {
          reason = 'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
        } else if (event.code === 1002) {
          reason = 'An endpoint is terminating the connection due to a protocol error';
        } else if (event.code === 1003) {
          reason = 'An endpoint is terminating the connection because it has received a type '
            + 'of data it cannot accept (e.g., an endpoint that understands only text data'
            + ' MAY send this if it receives a binary message).';
        } else if (event.code === 1004) {
          reason = 'Reserved. The specific meaning might be defined in the future.';
        } else if (event.code === 1005) {
          reason = 'No status code was actually present.';
        } else if (event.code === 1006) {
          reason = 'The connection was closed abnormally, e.g., without sending or receiving a Close control frame';
        } else if (event.code === 1007) {
          reason = 'An endpoint is terminating the connection because it has received data '
            + 'within a message that was not consistent with the type of the message '
            + '(e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).';
        } else if (event.code === 1008) {
          reason = 'An endpoint is terminating the connection because it has received a'
            + ' message that "violates its policy". This reason is given either if there is no'
            + ' other sutible reason, or if there is a need to hide specific details about the'
            + ' policy.';
        } else if (event.code === 1009) {
          reason = 'An endpoint is terminating the connection because it has received a message that is too big for it to process.';
        } else if (event.code === 1010) {
          reason = `An endpoint (client) is terminating the
                    connection because it has expected the server to negotiate one or
                    more extension, but the server didn't return them in the response
                    message of the WebSocket handshake. <br /> Specifically, the extensions that
                    are needed are: ${event.reason}`;
        } else if (event.code === 1011) {
          reason = 'A server is terminating the connection because it encountered an unexpected '
            + 'condition that prevented it from fulfilling the request.';
        } else if (event.code === 1015) {
          reason = 'The connection was closed due to a failure to perform a TLS handshake '
            + '(e.g., the server certificate can\'t be verified).';
        } else {
          reason = 'Unknown reason';
        }

        setSocketError({
          reason,
          code: event.code,
        });

        ws.close();
        console.error(`[close] Соединение закрыто с ошибкой, код=${event.code} причина=${reason}`);

        if (withReconnect && count.current <= reconnectCount) {
          const $timer = setTimeout(() => {
            clearTimeout($timer);
            count.current += 1;
            connect(url);
          }, (reloadTimer ?? 5000));
        }
      };

      ws.onerror = (error) => {
        getReadyState(ws.readyState);
        console.error(`[error] ${error.cancelable}`);
        ws.close();
      };
    }
  };

  useEffect(() => {
    console.log('[ws ready state]: ', readyState);
  }, [readyState]);

  return {
    readyState,
    socketError,
    connect,
    socket: wsRef.current,
    lastSocketData,
    socketData,
  };
};
