import React from 'react';
import './index.scss';
import { WarningIcon } from '../icons/warning-icon';

export interface NotPermission {
  icon?: JSX.Element;
}

export const NotPermission = (props: NotPermission): JSX.Element => {
  const { icon = <WarningIcon /> } = props;
  return (
    <div className="not-permission row justify-content-center no-gutters">
      <div className="col-4 not-permission__container">
        <div className="not-permission__icon mb-2">
          {icon}
        </div>
        <p className="not-permission__title">У Вас нет доступа к этой странице!</p>
        <div className="not-permission__description">Для получения доступа к странице, <br /> свяжитесь с
          администратором
        </div>
      </div>
    </div>
  );
};
