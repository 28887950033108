import React from 'react';

export const XIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16191 3.25537C3.94866 3.25537 3.72566 3.32704 3.56291 3.48954C3.23749 3.81538 3.23749 4.36203 3.56291 4.68787L8.79733 9.92204L3.56291 15.1562C3.23749 15.482 3.23749 16.0287 3.56291 16.3545C3.88841 16.6795 4.43541 16.6795 4.76091 16.3545L9.99524 11.1204L15.2296 16.3545C15.5551 16.6795 16.1021 16.6795 16.4276 16.3545C16.753 16.0287 16.753 15.482 16.4276 15.1562L11.1932 9.92204L16.4276 4.68787C16.753 4.36203 16.753 3.81538 16.4276 3.48954C16.2648 3.32704 16.0417 3.25537 15.8286 3.25537C15.6153 3.25537 15.3924 3.32704 15.2296 3.48954L9.99524 8.7237L4.76091 3.48954C4.59816 3.32704 4.37516 3.25537 4.16191 3.25537Z"
    />
  </svg>
);
