import React from 'react';
import { ToastProps } from 'react-toast-notifications';
import { CustomSuccessToast } from './custom-success-toast';

export const CustomToasts = (props: ToastProps): React.JSX.Element => {
  const { onDismiss, appearance, children } = props;

  return (
    <CustomSuccessToast
      appearance={appearance}
      onDismiss={onDismiss}
    >
      {children}
    </CustomSuccessToast>
  );
};
