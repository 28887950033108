import { IBorrowerData, IBorrowerResponse, OrderIdPersonalDataService } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class OrderIdPersonalDataServiceImpl implements OrderIdPersonalDataService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  get(orderID: string): HttpResponse<IBorrowerData> {
    return this.strictHttpRequest.get<IBorrowerData>(`/credits/${orderID}/borrower/`);
  }

  patchBorrowerRegion(pk: string, body: { region_id: string }): HttpResponse<IBorrowerResponse> {
    return this.strictHttpRequest.patch<
      IBorrowerResponse, {region_id: string}>(`/borrowers/${pk}/region/`, body);
  }

  updateGbdfl(pk: string): HttpResponse<void> {
    return this.strictHttpRequest.post(`/credits/${pk}/update-gbdfl/`);
  }
}
