import React from 'react';
import { Typography, TypographyProps } from '../typography';

interface TextProps {
  extraClass?: string;
}
export const Text: React.FC<TextProps & TypographyProps> = (
  props,
): React.JSX.Element => {
  const { extraClass, children, ...typographyProps } = props;
  return (
    <Typography
      variant="bodyRegular"
      extraClass={`display-sub-body-1-entity display-sub-title-1-entity order-wrapper-text  ${extraClass}`}
      {...typographyProps}
    >
      {children}
    </Typography>
  );
};

Text.defaultProps = {
  extraClass: '',
};
