import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import './index.scss';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';
import { Error } from '@/components/order-wrapper/OrderWrapperError';
import { Title } from './OrderWrapperTitle';
import { Text } from './OrderWrapperText';
import { Label } from './OrderWrapperLabel';

type TypeOrderWrapperExtensions = {
  Title: typeof Title;
  Text: typeof Text;
  Label: typeof Label;
		Error: typeof Error;
};

type TypeOrderWrapper = {
  extraClass?: string;
  padding?: string;
  children: React.ReactNode;
}

export const OrderWrapper: React.FC<TypeOrderWrapper & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
& TypeOrderWrapperExtensions = ({
	extraClass = '', padding = 'p-30px', className, children, ...divProps
}): JSX.Element => {
	const { isDark, isP2P, isLight } = useThemeContext();

	return (
		<div
			className={classnames('container-fluid order-wrapper', {
				'container--bg-dark': isDark,
				'container--bg-light': isLight,
				'container--bg-p2p': isP2P,
			}, [extraClass, padding, className])}
			{...divProps}
		>
			{children}
		</div>
	);
};

OrderWrapper.Title = Title;
OrderWrapper.Text = Text;
OrderWrapper.Label = Label;
OrderWrapper.Error = Error;
