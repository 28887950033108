import { IGetProductsQuery, IProduct, IProductsService } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class ProductServiceImpl implements IProductsService {
  private http: HttpRequest;

  constructor(http: HttpRequest) {
    this.http = http;
  }

  getProducts(query?: IGetProductsQuery): HttpResponse<IProduct[]> {
    let params: string;
    if (query?.creditor_id) {
      params = new URLSearchParams(query).toString();
    }
    return this.http.get(`/products/${params ? `?${params}` : ''}`);
  }
}
