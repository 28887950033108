import React from 'react';

import AlertContainer from '../../containers/alert-container/alert-container';
import { NotificationWrapper } from './shared';

import './notification.scss';

const NotificationComponent: React.FC = () => (
  <AlertContainer>
    <NotificationWrapper />
  </AlertContainer>
);

export default NotificationComponent;
