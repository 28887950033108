import { IRequestQueryBuilder, TOrderServiceQuery } from './model';

export class OrderGetAllQueryBuilder implements IRequestQueryBuilder {
  private readonly size = 10;

  private readonly page: number;

  private readonly search: string;

  private readonly executorIDList: Array<number>;

  private readonly regionIDList: Array<number>;

  private readonly statusIDList: Array<number>;

  private readonly rolesIDList: Array<number>;

  private readonly creditorsIDList: Array<number>;

  private readonly cessionDate: string;

  private readonly hasContract: string;

  constructor(orderServiceQuery: TOrderServiceQuery) {
    this.search = orderServiceQuery?.search;
    this.executorIDList = orderServiceQuery?.executorIDList;
    this.regionIDList = orderServiceQuery?.regionIDList;
    this.statusIDList = orderServiceQuery?.statusIDList;
    this.rolesIDList = orderServiceQuery?.rolesIDList;
    this.rolesIDList = orderServiceQuery?.rolesIDList;
    this.creditorsIDList = orderServiceQuery?.creditorsIDList;
    this.page = orderServiceQuery?.page;
    this.cessionDate = orderServiceQuery?.cessionDate;
    this.hasContract = orderServiceQuery?.hasContract;
  }

  build(): string {
    const queryList: Array<string> = this.getQueryList();
    return queryList?.length ? `?${queryList.join('&')}` : '';
  }

  private getQueryList(): Array<string> {
    const queryList: Array<string> = [];

    if (this.page) {
      queryList.push(`page=${this.page}`);
    }

    if (this.size) {
      queryList.push(`size=${this.size}`);
    }

    if (this.search) {
      queryList.push(`search=${this.search}`);
    }

    if (this.executorIDList?.length) {
      queryList.push(`responsible_id=${this.executorIDList.join(',')}`);
    }

    if (this.regionIDList?.length) {
      queryList.push(`region_id=${this.regionIDList.join(',')}`);
    }

    if (this.statusIDList?.length) {
      queryList.push(`status_id=${this.statusIDList.join(',')}`);
    }

    if (this.rolesIDList?.length) {
      queryList.push(`role_id=${this.rolesIDList.join(',')}`);
    }

    if (this.creditorsIDList?.length) {
      queryList.push(`creditor_id=${this.creditorsIDList.join(',')}`);
    }

    if (this.cessionDate) {
      queryList.push(`cession_date=${this.cessionDate ?? ''}`);
    }

    if (this.hasContract) {
      queryList.push(`has_contract=${this.hasContract === 'true'}`);
    }

    return queryList;
  }
}
