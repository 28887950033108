/* eslint-disable react/jsx-props-no-spreading,react/display-name */
import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { ERelatedElement, RelatedTriggerDropProps } from './model';
import './index.scss';
import useOnClickOutside from '@/hooks/useClickOutside';

// Документация https://popper.js.org/react-popper/v2/
// Note: the usePopper hook intentionally takes the DOM node,
// not refs, in order to be able to update when the nodes change.
// A callback ref is used here to permit this behaviour,
// and useState is an appropriate way to implement this.
// PS: Возможно понадобится оптимизировать

export const RelatedTriggerDrop = (props: RelatedTriggerDropProps): React.JSX.Element => {
  const {
    placement = 'top',
    drop: Drop,
    trigger: Trigger,
  } = props;
  const [popperElement, setPopperElement] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);

  const {
    styles,
    attributes,
    update,
  } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: { offset: [0, 6] },
        enabled: true,
      },
    ],
  });

  const [visible, setVisible] = useState(false);
  const [checkedRegions, setCheckedRegions] = useState(false);
  const [checkedStatuses, setCheckedStatuses] = useState(false);
  const [checkedExcutors, setCheckedExcutors] = useState(false);
  const [checkedRoles, setCheckedRoles] = useState(false);
  const [checkedCession, setCheckedCession] = useState(false);
  const [checkedCreditor, setCheckedCreditor] = useState(false);

  const onCheck = (type: ERelatedElement, isActive: boolean): void => {
    switch (type) {
      case (ERelatedElement?.REGION): {
        setCheckedRegions(() => isActive);
        break;
      }
      case (ERelatedElement.STATUS): {
        setCheckedStatuses(isActive);
        break;
      }
      case (ERelatedElement.EXCUTOR): {
        setCheckedExcutors(isActive);
        break;
      }
      case (ERelatedElement.ROLES): {
        setCheckedRoles(isActive);
        break;
      }
      case (ERelatedElement.CESSION): {
        setCheckedCession(isActive);
        break;
      }
      case (ERelatedElement.CREDITOR): {
        setCheckedCreditor(isActive);
        break;
      }
      default: {
        break;
      }
    }
  };

  const getCheckedInfo = (name: string): boolean => {
    switch (name) {
      case ERelatedElement.STATUS: {
        return checkedStatuses;
      }
      case ERelatedElement.REGION: {
        return checkedRegions;
      }
      case ERelatedElement.EXCUTOR: {
        return checkedExcutors;
      }
      case ERelatedElement.ROLES: {
        return checkedRoles;
      }
      case (ERelatedElement.CESSION): {
        return checkedCession;
      }
      case (ERelatedElement.CREDITOR): {
        return checkedCreditor;
      }
      default: {
        return undefined;
      }
    }
  };

  useEffect(() => {
    if (visible) {
      update()
        .then(() => {
        });
    }
  }, [visible]);

  useOnClickOutside(popperElement, () => setVisible(false), referenceElement);

  return (
    <div style={{ position: 'relative' }}>
      <Trigger
        triggerRef={setReferenceElement}
        visible={visible}
        setVisible={setVisible}
        getCheckedInfo={getCheckedInfo}
      />
      <div
        className={`related-trigger-drop related-trigger-drop__visible-${visible}`}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        <Drop
          visible={visible}
          onCheck={onCheck}
          setVisible={setVisible}
        />
      </div>
    </div>
  );
};
