import { IApplicationContext, TApplicationContextBeanMap } from './model';

export class ApplicationContextImpl implements IApplicationContext {
    private readonly beanMap: TApplicationContextBeanMap = new Map<string, unknown>();

    constructor(beanMap: TApplicationContextBeanMap) {
      this.beanMap = beanMap;
    }

    getBean<T>(beanName: string): T {
      const maybeBean = this.beanMap.get(beanName);

      if (!maybeBean) {
        throw new Error(`Bean: '${beanName}' does not exist.`);
      }

      return maybeBean as T;
    }
}
