import {
  IApplicationForNotaryData,
  IApplicationToCourtData,
  IEnforcementProceedingApplication,
  IEnforcementProceedingTerminateAppData,
  IExecutiveDocumentData,
  IExecutiveInscriptionData,
  IExecutiveWritData,
  IHeirData,
  IHeirNotaryData,
  IInscriptionCancelDecisionData,
  ILoanDetailsData,
  IWritCancelDecisionData,
  OrderAdditionalDataService,
} from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class OrderAdditionalDataServiceImpl implements OrderAdditionalDataService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getEnforcementApplication(creditID: string): HttpResponse<
    IEnforcementProceedingApplication> {
    return this.strictHttpRequest.get<
      IEnforcementProceedingApplication>(`/credits/${creditID}/data/enforcement_proceeding_acceptance_app/`);
  }

  // eslint-disable-next-line max-len
  putEnforcementApplication(creditID: string, body: IEnforcementProceedingApplication): HttpResponse<
    IEnforcementProceedingApplication> {
    return this.strictHttpRequest.put(`/credits/${creditID}/data/enforcement_proceeding_acceptance_app/`, body);
  }

  getHeir(orderID: string): HttpResponse<IHeirData[]> {
    return this.strictHttpRequest.get<IHeirData[]>(`/credits/${orderID}/data/heir/`);
  }

  putHeir(orderID: string, body: IHeirData, heirID: string): HttpResponse<void> {
    return this.strictHttpRequest.put(
      `/credits/${orderID}/data/heir/${heirID}/`,
      body,
    );
  }

  postHeir(orderID: string, body: IHeirData): HttpResponse<void> {
    return this.strictHttpRequest.post(
      `/credits/${orderID}/data/heir/`,
      body,
    );
  }

  deleteHeir(orderID: string, id: string): HttpResponse<void> {
    return this.strictHttpRequest.delete(`/credits/${orderID}/data/heir/${id}/`);
  }

  getHeirNotary(orderID: string): HttpResponse<IHeirNotaryData> {
    return this.strictHttpRequest.get<IHeirNotaryData>(`/credits/${orderID}/data/heir_notary/`);
  }

  putHeirNotary(orderID: string, body: IHeirNotaryData): HttpResponse<void> {
    return this.strictHttpRequest.put(`/credits/${orderID}/data/heir_notary/`, body);
  }

  getExecutiveInscription(orderID: string): HttpResponse<IExecutiveInscriptionData> {
    return this.strictHttpRequest.get<IExecutiveInscriptionData>(`/credits/${orderID}/data/executive_inscription/`);
  }

  putExecutiveInscription(orderID: string, body: IExecutiveInscriptionData): HttpResponse<void> {
    return this.strictHttpRequest.put(
      `/credits/${orderID}/data/executive_inscription/`,
      body,
    );
  }

  getWritCancelDecision(orderID: string): HttpResponse<IWritCancelDecisionData> {
    return this.strictHttpRequest.get(`/credits/${orderID}/data/writ_cancel_decision/`);
  }

  putWritCancelDecision(orderID: string, body: IWritCancelDecisionData): HttpResponse<
    IWritCancelDecisionData> {
    return this.strictHttpRequest.put(
      `/credits/${orderID}/data/writ_cancel_decision/`,
      body,
    );
  }

  getApplicationForNotary(orderID: string): HttpResponse<IApplicationForNotaryData> {
    return this.strictHttpRequest.get(`/credits/${orderID}/data/application_for_notary/`);
  }

  getEnforcementProceedingTerminateApp(orderID: string): HttpResponse<
    IEnforcementProceedingTerminateAppData> {
    return this.strictHttpRequest.get(`/credits/${orderID}/data/enforcement_proceeding_terminate_app/`);
  }

  getExecutiveDocument(orderID: string): HttpResponse<IExecutiveDocumentData> {
    return this.strictHttpRequest.get(`/credits/${orderID}/data/executive_document/`);
  }

  getInscriptionCancelDecision(orderID: string): HttpResponse<IInscriptionCancelDecisionData> {
    return this.strictHttpRequest.get(`/credits/${orderID}/data/inscription_cancel_decision/`);
  }

  putApplicationForNotary(orderID: string, body: IApplicationForNotaryData): HttpResponse<
    IApplicationForNotaryData> {
    return this.strictHttpRequest.put(`/credits/${orderID}/data/application_for_notary/`, body);
  }

  putEnforcementProceedingTerminateApp(
    orderID: string,
    body: IEnforcementProceedingTerminateAppData,
  ): HttpResponse<
    IEnforcementProceedingTerminateAppData> {
    return this.strictHttpRequest.put(`/credits/${orderID}/data/enforcement_proceeding_terminate_app/`, body);
  }

  putExecutiveDocument(orderID: string, body: IExecutiveDocumentData): HttpResponse<
    IExecutiveDocumentData> {
    return this.strictHttpRequest.put(`/credits/${orderID}/data/executive_document/`, body);
  }

  putInscriptionCancelDecision(orderID: string, body: IInscriptionCancelDecisionData): HttpResponse<
    IInscriptionCancelDecisionData> {
    return this.strictHttpRequest.put(`/credits/${orderID}/data/inscription_cancel_decision/`, body);
  }

  getLoanDetails(orderID: string): HttpResponse<ILoanDetailsData> {
    return this.strictHttpRequest.get(`/credits/${orderID}/data/additional_data/`);
  }

  putLoanDetails(orderID: string, body: ILoanDetailsData): HttpResponse<void> {
    return this.strictHttpRequest.put(`/credits/${orderID}/data/additional_data/`, body);
  }

  getCourtApplication(creditID: string): HttpResponse<IApplicationToCourtData> {
    return this.strictHttpRequest.get(`/credits/${creditID}/data/court_application/`);
  }

  putCourtApplication(creditID: string, body: IApplicationToCourtData): HttpResponse<
    IApplicationToCourtData> {
    return this.strictHttpRequest.put(`/credits/${creditID}/data/court_application/`, body);
  }

  getExecutiveWrit(orderID: string): HttpResponse<IExecutiveWritData> {
    return this.strictHttpRequest.get<IExecutiveWritData>(`/credits/${orderID}/data/executive_writ/`);
  }

  // eslint-disable-next-line max-len
  putExecutiveWrit(orderID: string, body: IExecutiveWritData): HttpResponse<void> {
    return this.strictHttpRequest.put(
      `/credits/${orderID}/data/executive_writ/`,
      body,
    );
  }
}
