import { IAuthMetaService, TAuthMeta, TAuthOrgToken } from './model';
import { b64DecodeUnicode, b64EncodeUnicode } from '../../utils/base-64-encode-decode';
import { TOrganizationUserInfo } from '../../models/permission';
import { getItemStorage, removeItemStorage, setItemStorage } from '../../helpers/storage-helper';

export class AuthMetaServiceImpl implements IAuthMetaService {
  private user: TOrganizationUserInfo;

  private authMeta: TAuthMeta;

  private orgMeta: TAuthOrgToken;

  /* constructor() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!AuthMetaServiceImpl.instance) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      AuthMetaServiceImpl.instance = this;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return AuthMetaServiceImpl.instance;
  } */

  getAuthMeta(): TAuthMeta {
    this.validateAuthMeta();
    return this.authMeta || getItemStorage<TAuthMeta>('authMeta');
  }

  setAuthMeta(authMeta: TAuthMeta): void {
    this.authMeta = authMeta;
    setItemStorage('authMeta', authMeta);
  }

  setOrgAuthMeta(orgMeta: TAuthOrgToken): void {
    this.orgMeta = orgMeta;
    setItemStorage('orgMeta', orgMeta.token);
  }

  getOrgAuthMeta(): TAuthOrgToken {
    this.validateOrgMeta();
    return this.orgMeta || getItemStorage('orgMeta');
  }

  hasOrgAuthMeta(): boolean {
    return !!this.orgMeta || Boolean(getItemStorage('orgMeta'));
  }

  hasAuthMeta(): boolean {
    return !!this.authMeta || Boolean(getItemStorage('authMeta'));
  }

  isAuthMetaExpired(): boolean {
    this.validateAuthMeta();
    return false;
  }

  getUser(): TOrganizationUserInfo {
    this.validateOrgUser();
    return this.user || JSON.parse(b64DecodeUnicode(getItemStorage('organizationUserInfo')));
  }

  setUser(user: TOrganizationUserInfo): void {
    this.user = user;
    setItemStorage('organizationUserInfo', b64EncodeUnicode(JSON.stringify(user)));
  }

  hasUser(): boolean {
    return !!this.user || Boolean(getItemStorage('organizationUserInfo'));
  }

  removeUser(): void {
    this.user = undefined;
    removeItemStorage('organizationUserInfo');
  }

  hasAllMetas(): boolean {
    return this.hasAuthMeta() && this.hasUser() && this.hasOrgAuthMeta();
  }

  private validateAuthMeta(): void {
    if (!this.hasAuthMeta() || !getItemStorage('authMeta')) {
      throw new Error('no auth meta');
    }
  }

  private validateOrgMeta(): void {
    if (!this.hasOrgAuthMeta() || !getItemStorage('orgMeta')) {
      throw new Error('no org meta');
    }
  }

  private validateOrgUser(): void {
    if (!this.hasUser() || !getItemStorage('organizationUserInfo')) {
      throw new Error('no organization user info');
    }
  }
}
