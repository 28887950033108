import { IReportBody, IReportsService } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class ReportServiceImpl implements IReportsService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getReport(): HttpResponse<Blob> {
    return this.strictHttpRequest.getFile<any>('/reports/general/');
  }

  getReportPortfolioHistory(body: IReportBody): HttpResponse<Blob> {
    return this.strictHttpRequest.getFile(`/reports/portfolio-history/${ReportServiceImpl.withParams(body)}`);
  }

  getReportPaymentHistory(body: IReportBody): HttpResponse<Blob> {
    return this.strictHttpRequest.getFile(`/reports/payment-history/${ReportServiceImpl.withParams(body)}`);
  }

  getReportEnisWorking(body: IReportBody): HttpResponse<Blob> {
    return this.strictHttpRequest.getFile(`/reports/enis-work/${ReportServiceImpl.withParams(body)}`);
  }

  private static withParams(objQuery: Record<string, any>): string {
    const searchParams = new URLSearchParams(objQuery);
    return `${searchParams ? `?${searchParams}` : ''}`;
  }
}
