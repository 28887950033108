import { IErrorResponseBody, IErrorResponseChangePassword } from '@/types/errorResponseType';

// eslint-disable-next-line max-len
export function isErrorStringTypeGuard(detail: string | IErrorResponseChangePassword | IErrorResponseBody[]): detail is string {
  return typeof detail === 'string';
}

// eslint-disable-next-line max-len
export function isErrorArrayTypeGuard(detail: string | IErrorResponseChangePassword | IErrorResponseBody[]): detail is IErrorResponseBody[] {
  return Array.isArray(detail);
}

export function isErrorChangePasswordTypeGuard(
  detail: string | IErrorResponseChangePassword | IErrorResponseBody[],
): detail is IErrorResponseChangePassword {
  return !Array.isArray(detail) && typeof detail === 'object' && 'token' in detail;
}

export const isDateOrStringTypeGuard = (date: string | Date): date is string => typeof date === 'string';

export const isArray = (elem: string | string[]): elem is string[] => Array.isArray(elem);
export const isString = (elem: string | string[]): elem is string => typeof elem === 'string';
