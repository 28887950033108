import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { AuthMetaServiceImpl } from '@/services/auth-meta-service/auth-meta-service-impl';
import { logout } from '@/utils/logout';

const instance = axios.create({
  baseURL: process.env.BASE_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

console.log('process.env.BASE_URL => ', process.env.BASE_URL);

instance.interceptors.request.use(
  (config): AxiosRequestConfig => {
    const { headers } = config;
    const authMetaService = new AuthMetaServiceImpl();
    if (authMetaService?.hasAuthMeta()) {
      const token = authMetaService.getAuthMeta();
      headers.authorization = `${token?.token_type} ${token?.access_token}`;
    }
    if (authMetaService.hasOrgAuthMeta()) {
      const orgToken = authMetaService.getOrgAuthMeta();
      headers['org-token'] = `${orgToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

const STATUSES = [401];

instance.interceptors.response.use((response) => {
  if (STATUSES?.includes(response?.status)) {
    logout();
  }
  return response;
},
(error: AxiosError) => {
  if (STATUSES?.includes(error?.response?.status)) {
    logout();
  }
  return Promise.reject(error);
});

export default instance;
