import { TPermissionDetail } from '../../models/permission';

export const reduceUserPermissions = (
  mapPermissions: Map<string, string[]>,
  permissions: Array<TPermissionDetail>,
): void => {
  permissions?.reduce((acc, permission) => {
    const { code, entity_type } = permission;
    if (mapPermissions.has(entity_type)) {
      const permissionCodeMap = mapPermissions.get(entity_type);
      mapPermissions.set(entity_type, [...permissionCodeMap, code]);
    } else {
      mapPermissions.set(entity_type, [code]);
    }
    return [...acc, permission];
  }, []);
};
