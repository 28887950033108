import { IPaymentScheduleService, IPaymentScheduleServiceResponse } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class OrderIdPaymentScheduleServiceImpl implements IPaymentScheduleService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getPaymentSchedule(orderID: string): HttpResponse<IPaymentScheduleServiceResponse[]> {
    return this.strictHttpRequest.get<IPaymentScheduleServiceResponse[]>(`/credits/${orderID}/schedulepayments/`);
  }
}
