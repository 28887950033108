import { Placement } from '@popperjs/core';
import { FunctionComponent, LegacyRef, MutableRefObject } from 'react';

export interface RelatedDropElement {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    style?: React.CSSProperties;
    onCheck?: (type: any, isActive: boolean) => void;
}

export interface RelatedTriggerElement extends RelatedDropElement {
    triggerRef?: LegacyRef<any> | MutableRefObject<any>;
    getCheckedInfo?: (name: string) => boolean;
}

export enum ERelatedElement {
  REGION = 'Регион',
  EXCUTOR = 'Исполнитель',
  STATUS = 'Статус',
  ROLES = 'Роль',
  CESSION = 'Дата реестра',
  CREDITOR = 'Кредитор',
}

export interface RelatedTriggerDropProps {
    trigger: FunctionComponent<RelatedTriggerElement>;
    drop: FunctionComponent<RelatedDropElement>;
    placement?: Placement;
    isActive?: boolean
}
