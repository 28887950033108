import React, { ReactNode } from 'react';
import { AppearanceTypes } from 'react-toast-notifications';
import { IconBoxWrapper, IconButtonWrapper } from '../../icon-wrapper';
import { XIcon } from '../../icons/x';
import { BadgeCheckIcon } from '../../icons/badge-check';
import { BanIcon } from '../../icons/ban';
import { ExclamationIcon } from '../../icons/exclamation';

export interface CustomToastProps {
  children?: ReactNode;
  onDismiss: () => void;
  appearance: AppearanceTypes;
}

export const CustomSuccessToast = (props: CustomToastProps): React.JSX.Element => {
  const { children, onDismiss, appearance } = props;

  return (
    <div className="paper mb-2">
      <div className="container-fluid p-3">
        <div className="row flex-nowrap">
          <div className="col-auto flex-grow-0">
            {
              appearance === 'success' ? (
                <IconBoxWrapper size="20x20" color="72BF44">
                  <BadgeCheckIcon />
                </IconBoxWrapper>
              ) : <></>
            }
            {
              appearance === 'error' ? (
                <IconBoxWrapper size="20x20" color="C24141">
                  <BanIcon />
                </IconBoxWrapper>
              ) : <></>
            }
            {
              appearance === 'warning' ? (
                <IconBoxWrapper size="20x20" color="CF9E54">
                  <ExclamationIcon />
                </IconBoxWrapper>
              ) : <></>
            }
          </div>
          <div className="col-auto flex-grow-1">
            {children}
          </div>
          <div className="col-auto flex-grow-0 tc-ffffff_50">
            <IconButtonWrapper onClick={onDismiss} size="20x20">
              <XIcon />
            </IconButtonWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
