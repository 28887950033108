import React, { FC } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { NotFound } from '../../components/not-found';
import { WarningIcon } from '../../components/icons/warning-icon';
import ErrorNotification from '../../components/error-notification';
import { WarningSolidIcon } from '../../components/icons/warning-solid';
import { NotPermission } from '../../components/not-permission';

interface IErrorPage {
  title?: string;
  navigatePath?: To | number;
  description?: string | React.ReactNode;
  isNotPermission?: boolean;
}

export const ErrorPage: FC<IErrorPage> = ({
  title = 'Страница не найдена (404)',
  navigatePath = -1,
  description,
  isNotPermission,
}): JSX.Element => {
  const navigate = useNavigate();

  const onClickRedirect = (): void => {
    if (typeof navigatePath === 'number') {
      navigate(navigatePath);
    } else {
      navigate(navigatePath);
    }
  };

  const notification = (
    <p>
      Что-то пошло не так... Пожалуйста,
      <button
        type="button"
        onClick={onClickRedirect}
        style={{
          textDecoration: 'underline',
          color: '#4f9d3a',
        }}
      >
        обновите страницу
      </button>
    </p>
  );

  const permissionNotification = (
    <p>
      <button
        type="button"
        onClick={onClickRedirect}
        style={{
          textDecoration: 'underline',
          color: '#4f9d3a',
        }}
      >
        Вернуться назад
      </button>
    </p>
  );

  if (window.performance) {
    if (performance.navigation.type === 1) {
      navigate(-1);
    }
  }

  return (
    <>
      {
        isNotPermission ? (
          <>
            <NotPermission icon={<WarningIcon />} />
            <ErrorNotification icon={<WarningSolidIcon />} text={permissionNotification} />
          </>
        ) : (
          <>
            <NotFound title={title} description={description} icon={<WarningIcon />} />
            <ErrorNotification icon={<WarningSolidIcon />} text={notification} />
          </>
        )
      }
    </>
  );
};

ErrorPage.defaultProps = {
  title: 'Страница не найдена (404)',
  navigatePath: '/',
  description: <> Возможно в адресе есть ошибка <br /> или страница была удалена </>,
  isNotPermission: false,
};
