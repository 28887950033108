import { isArray, isString } from '../../utils/typeGuard';
import {
  hasEveryEntity,
  hasEveryTypesAndCodes,
  hasSomeCodesInTypes,
  isNotEmptyArray,
} from './check-permissions-types';
import { userPermissionsMap } from './permissions-map';

const hasEntityTypeAndNotCode = (
  entityType: string[],
  permissionCode: string[],
  map: Map<string, string[]>,
): boolean => {
  // eslint-disable-next-line max-len
  const isHasEntity: boolean = isNotEmptyArray(entityType) ? hasEveryEntity(entityType, map) : false;
  return (isHasEntity && !isNotEmptyArray(permissionCode));
};

const stringToArray = (value: string | string[]): string[] => {
  let newArrayValue: string[];

  if (isString(value)) {
    newArrayValue = [value];
  } else {
    newArrayValue = value;
  }

  return newArrayValue;
};

/*
Проверяет есть ли доступные разрешения у текущего пользователя!
@param { string | string[] } entityType - Наименование разрешения
@param { string | string[] } permissionCode - Доступное свойство разрешения ('view', 'create' e.t.c)
@param { boolean } someCode - Проверять на совпадение всех свойств разрешения или нет
@return { boolean } true/false - Статус разрешения по текущим параметрам
  */
export const checkUserHasPermission = (
  entityType: string | string[],
  permissionCode: string | string[],
  someCode?: boolean,
): boolean => {
  const newEntityType: string[] = stringToArray(entityType);
  const newPermissionCode: string[] = stringToArray(permissionCode);

  if (hasEntityTypeAndNotCode(newEntityType, newPermissionCode, userPermissionsMap)) return true;

  if (isArray(newEntityType) && isArray(newPermissionCode)) {
    return someCode ? hasSomeCodesInTypes(newEntityType, newPermissionCode, userPermissionsMap)
      : hasEveryTypesAndCodes(newEntityType, newPermissionCode, userPermissionsMap);
  }

  return false;
};
