import React, {
  createContext, useCallback, useContext, useEffect, useState,
} from 'react';
import { getItemStorage, setItemStorage } from '@/helpers/storage-helper';
import { EThemeType, IThemeContext } from './model';

export const ThemeContext = createContext<IThemeContext>({
  currentTheme: EThemeType.DARK,
  changeTheme: () => {},
  isDark: true,
  isLight: false,
  isP2P: false,
});

export function useThemeContext(): IThemeContext {
  return useContext(ThemeContext);
}

const THEME = 'theme_fd';

const checkIsTheme = (
  {
    themes,
    theme,
  }: {
    themes: EThemeType[],
    theme: EThemeType
  },
): boolean => themes.some((themeItem) => themeItem === theme);

const ThemeContextProvider: React.FC = (props) => {
  const { children } = props;

  const [currentTheme, setCurrentTheme] = useState<EThemeType>(EThemeType.DARK);

  const changeTheme = useCallback((theme: EThemeType): void => {
    setCurrentTheme(theme);
    setItemStorage(THEME, theme);
  }, [setCurrentTheme]);

  useEffect(() => {
    const theme: EThemeType = getItemStorage(THEME);
    if (theme && checkIsTheme({ theme, themes: [EThemeType.DARK, EThemeType.LIGHT, EThemeType.P2P] })) {
      setCurrentTheme(theme);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        changeTheme,
        isLight: currentTheme === EThemeType.LIGHT,
        isDark: currentTheme === EThemeType.DARK,
        isP2P: currentTheme === EThemeType.P2P,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
