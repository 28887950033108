import React, { FC } from 'react';
import {
  Navigate, Outlet, useLocation,
} from 'react-router-dom';

const PrivateRoutes: FC<{isAuthenticated: boolean}> = ({ isAuthenticated }) => {
  const location = useLocation();
  return (
    isAuthenticated ? <Outlet context={{ isAuthenticated }} /> : <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoutes;
