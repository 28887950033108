import { ICreditorsResponse, ICreditorsService } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class CreditorsServiceImpl implements ICreditorsService {
  // eslint-disable-next-line no-useless-constructor
  constructor(private strictHttp: HttpRequest) {}

  getCreditors(): HttpResponse<ICreditorsResponse[]> {
    return this.strictHttp.get<ICreditorsResponse[]>('/credits/creditors/');
  }
}
