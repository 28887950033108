import React from 'react';

export const WarningSolidIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 96 82"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.9646 66.4129L57.7101 6.11457C56.7503 4.52212 55.3327 3.19281 53.6066 2.2666C51.8806 1.3404 49.9099 0.851562 47.9021 0.851562C45.8942 0.851562 43.9236 1.3404 42.1975 2.2666C40.4714 3.19281 39.0539 4.52212 38.094 6.11457L1.80236 66.4129C0.852464 67.9525 0.366044 69.69 0.391581 71.4523C0.417119 73.2145 0.953722 74.9399 1.9479 76.4566C2.94209 77.9732 4.35912 79.228 6.05776 80.096C7.7564 80.964 9.67731 81.4148 11.629 81.4035H84.1379C86.0736 81.4051 87.9765 80.9523 89.66 80.0895C91.3435 79.2267 92.7497 77.9836 93.7406 76.4821C94.7316 74.9805 95.2732 73.2722 95.3125 71.5246C95.3518 69.7771 94.8873 68.0503 93.9646 66.5137V66.4129ZM42.3373 23.3907C42.3373 22.0535 42.9256 20.7712 43.9727 19.8257C45.0197 18.8802 46.4399 18.349 47.9207 18.349C49.4015 18.349 50.8216 18.8802 51.8687 19.8257C52.9158 20.7712 53.504 22.0535 53.504 23.3907V46.5487C53.504 47.8859 52.9158 49.1682 51.8687 50.1137C50.8216 51.0592 49.4015 51.5904 47.9207 51.5904C46.4399 51.5904 45.0197 51.0592 43.9727 50.1137C42.9256 49.1682 42.3373 47.8859 42.3373 46.5487V23.3907ZM48.0696 68.7993C46.8033 68.7993 45.5655 68.4602 44.5127 67.825C43.4598 67.1898 42.6393 66.2869 42.1547 65.2305C41.6701 64.1741 41.5433 63.0118 41.7904 61.8903C42.0374 60.7689 42.6472 59.7388 43.5425 58.9303C44.4379 58.1218 45.5786 57.5712 46.8206 57.3481C48.0625 57.1251 49.3497 57.2396 50.5196 57.6771C51.6894 58.1147 52.6893 58.8557 53.3928 59.8064C54.0963 60.7571 54.4718 61.8748 54.4718 63.0182C54.4718 64.5514 53.7973 66.0219 52.5966 67.106C51.396 68.1902 49.7675 68.7993 48.0696 68.7993Z"
    />
  </svg>
);
