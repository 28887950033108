import React, {
  FC, Suspense, useCallback, useContext, useEffect,
} from 'react';
import {
  Route, Routes, useLocation, useNavigate, useOutletContext,
} from 'react-router-dom';
import { v4 } from 'uuid';
import { PageGuard } from '@/pages/utils/page-guard';
import { checkPermission } from '@/pages/utils/page-permission-validators';
import { globalStateContext } from '@/containers/global-state';
import { GlobalLoader } from '@/components/global-loader';
import { ErrorPageLazy } from '@pages/page-404';
import {
  FEATURE_ORG_THEME, useFeatureToggle,
} from '@/containers/feature-container/feature-context';
import { useThemeContext } from '@/containers/theme-context';
import { EThemeType } from '@/containers/theme-context/model';
import routes from '../routes-list';
import { IPrivateRoutesOutletContext } from '../private-routes/model';
/*
 * Смотри страницу routes/routes-list.ts.
 * Там добавляются страницы для рендера роутинга с необходимыми данными
 * */
const ProtectedRoutes: FC = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const { isAuthenticated } = useOutletContext<IPrivateRoutesOutletContext>();
  const { organizationUserInfo } = useContext(globalStateContext);
  const featureTheme = useFeatureToggle(FEATURE_ORG_THEME);
  const { changeTheme } = useThemeContext();
  // eslint-disable-next-line max-len
  const hasPermission = useCallback(checkPermission(organizationUserInfo), [organizationUserInfo?.id]);

  useEffect(() => {
    if (isAuthenticated && location?.pathname === '/') {
      navigate('/dashboard');
    } else if (!isAuthenticated) {
      navigate('/login');
    }
  }, [location, isAuthenticated, navigate]);

  useEffect(() => {
    if (featureTheme === 'P2P') {
      changeTheme(EThemeType.P2P);
    }
    if (featureTheme === 'FD') {
      changeTheme(EThemeType.DARK);
    }
  }, [featureTheme]);

  return (
    <Routes>
      {
        routes.map(({
          component: Component,
          path,
          pageName,
          canActivate,
        }) => (
          hasPermission(pageName) ? (
            <Route
              key={path}
              path={path}
              element={(
                <Suspense fallback={<GlobalLoader visible />}>
                  <PageGuard
                    page={Component}
                    canActivate={canActivate}
                  />
                </Suspense>
              )}
            />
          ) : (
            <Route
              path="*"
              key={v4()}
              element={(
                <Suspense fallback={<GlobalLoader visible />}>
                  <ErrorPageLazy navigatePath="/" />
                </Suspense>
            )}
            />
          )
        ))
      }
    </Routes>
  );
};

export default ProtectedRoutes;
