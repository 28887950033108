import { FunctionComponent } from 'react';
import { OrderV2PageLazy } from '@/pages/order-v2';
import { HandbookPageLazy } from '@/pages/handbook';
import { SettingsPageLazy } from '@/pages/settings';
import { DashboardPageLazy } from '@/pages/dashboard';
import {
  EPages,
  hasUserPermissionToAdminPage,
  hasUserPermissionToDashboard,
  hasUserPermissionToError,
  hasUserPermissionToHandbooks,
  hasUserPermissionToOrders,
  hasUserPermissionToSettings,
} from '@/pages/utils/page-permission-validators';
import { ErrorPageLazy } from '@/pages/page-404';
import FeatureToggleComponent from '@/components/feature-toggle/ui/feature-toggle-component';

interface IRoutesList {
  path: string;
  component: FunctionComponent;
  canActivate: () => boolean;
  pageName: EPages;
}

/* Список с url для роутинга, компонентом для монтирования страницы в роутинг,
* @params path {string} - url компонента для роутингов
* @params component {FunctionComponent} компонент для монтирования страницы
* @params canActivate {Function} функция для PageGuard компонента,
* которая проверяет есть ли доступ к странице.
* @params checkPermission {Function} функция создает область видимости с данными пользователя
* которые нужны для проверки role.
* @return checkPermission => Function которая параметром принимает Enum списка страниц
* возвращает boolean
*/
const routes: Array<IRoutesList> = [
  {
    path: 'order/*',
    component: OrderV2PageLazy,
    canActivate: hasUserPermissionToOrders,
    pageName: EPages.ORDERS,
  },
  {
    path: 'handbook/*',
    component: HandbookPageLazy,
    canActivate: hasUserPermissionToHandbooks,
    pageName: EPages.HANDBOOK,
  },
  {
    path: 'settings/*',
    component: SettingsPageLazy,
    canActivate: hasUserPermissionToSettings,
    pageName: EPages.SETTINGS,
  },
  {
    path: 'dashboard',
    component: DashboardPageLazy,
    canActivate: hasUserPermissionToDashboard,
    pageName: EPages.DASHBOARD,
  },
  {
    path: 'admin',
    component: FeatureToggleComponent,
    canActivate: hasUserPermissionToAdminPage,
    pageName: EPages.ADMIN,
  },
  {
    path: '*',
    component: ErrorPageLazy,
    canActivate: hasUserPermissionToError,
    pageName: EPages.ERRROR,
  },
];

export default routes;
