import React, {
	forwardRef, MouseEvent, ReactNode, useMemo,
} from 'react';
import './index.scss';

// Элементы из @/components/icons svg без ширины и высоты. Также наследуется цвет от обертки.
// Этот компонент служит оберткой.

export type IconWrapperSize = '20x20' | '16x16' | '18x18' | '30x30';
export type IconWrapperColor = '72BF44'
| '4F9D3A'
| '2D7D2F'
| 'FB5555'
| 'C24141'
| 'E3ECDE'
| 'FFFFFF'
| 'F5F6F7'
| 'E8EAEB'
| '8E8E93'
| '17191A'
| 'EEFAEB'
| 'FFFFFF_87'
| 'FFFFFF_50'
| 'B7BBBD'
| '454545'
| '282828'
| '1A1A1A'
| '7B54CF'
| 'CF9E54'
| 'ABABAB'
| 'CFD4DA';

export interface IconBoxWrapperProps {
size?: IconWrapperSize;
color?: IconWrapperColor;
children?: ReactNode;
extraClass?: string;
small?: boolean,
}

export interface IconButtonWrapperProps {
size?: IconWrapperSize;
color?: IconWrapperColor;
children?: ReactNode;
onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
disabled?: boolean;
extraClass?: string;
small?: boolean,
}

export const IconBoxWrapper = (props: IconBoxWrapperProps): JSX.Element => {
	const {
		size = '20x20',
		color = '#1A1A1A',
		children,
		extraClass,
		small = false,
	} = props;

	const classes = useMemo(() => [
		'icon-box-wrapper',
		`icon-wrapper__size-${size}`,
		` ghost-button-v2__size-small_${small}`,
		`icon-wrapper__color-${color}`,
		'unselectable-text',
		`${extraClass ?? ''}`,
	].join(' '), [size, color]);

	return (
		<div className={classes}>{children}</div>
	);
};

export const IconButtonWrapper = forwardRef<
HTMLButtonElement, IconButtonWrapperProps>((props: IconButtonWrapperProps, ref): JSX.Element => {
	const {
		size = '20x20',
		color = '#1A1A1A',
		children,
		disabled,
		onClick,
		extraClass,
		small = false,
		...rest
	} = props;

	const classes = useMemo(() => [
		'icon-button-wrapper',
		`icon-wrapper__size-${size}`,
		`ghost-button-v2__size-small_${small}`,
		`icon-wrapper__color-${color}`,
		extraClass ?? '',
	].join(' '), [size, color]);

	return (
		<button
			ref={ref}
			onClick={onClick}
			disabled={disabled}
			className={`${classes}`}
			type="button"
			{...rest}
		>
			{children}
		</button>
	);
});

IconButtonWrapper.displayName = 'IconButtonWrapper';
