import { OrderIdStatusService, TOrderEvent, TOrderIdStatus } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class OrderIdStatusServiceImpl implements OrderIdStatusService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  get(orderID: string): HttpResponse<TOrderIdStatus> {
    return this.strictHttpRequest.get<TOrderIdStatus>(`/credits/${orderID}/status/`);
  }

  getEvents(orderID: string): HttpResponse<Array<TOrderEvent>> {
    return this.strictHttpRequest.get(`/credits/${orderID}/events/`);
  }
}
