import React from 'react';
import { NoDataIcon } from '../icons/no-data';
import { Typography } from '../typography';
import './index.scss';

export interface NoDataProps {
  text?: string;
  extraClass?: string;
}

export const NoData = (props: NoDataProps): JSX.Element => {
  const { text = 'Ничего не найдено', extraClass = '' } = props;

  return (
    <div className={`no-data container-fluid p-0 unselectable-text ${extraClass}`}>
      <div className="row justify-content-center mb-4">
        <div className="col-auto">
          <NoDataIcon />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <Typography variant="bodyRegular" color="grey" extraClass="no-data-text">
            {text}
          </Typography>
        </div>
      </div>
    </div>
  );
};
