import { HandbookRegionService, THandbookRegion } from './model';
import { HttpRequestErrorResponse, HttpRequestOkResponse } from '../../http-request/model';

export class HandbookRegionMockService implements HandbookRegionService {
  getAll(): Promise<HttpRequestOkResponse<Array<THandbookRegion>> | HttpRequestErrorResponse> {
    return new Promise<HttpRequestOkResponse<Array<THandbookRegion>> | HttpRequestErrorResponse>(
      (resolve) => {
        const to = setTimeout(() => {
          clearTimeout(to);
          resolve({
            status: 'OK',
            data: [
              {
                id: 1,
                region: 'region 1',
                collector: 'collector 1',
              },
              {
                id: 2,
                region: 'region 2',
                collector: 'collector 2',
              },
              {
                id: 3,
                region: 'region 3',
                collector: 'collector 3',
              },
              {
                id: 4,
                region: 'region 4',
                collector: 'collector 4',
              },
              {
                id: 5,
                region: 'region 5',
                collector: 'collector 5',
              },
            ],
          });
        }, 1000);
      },
    );
  }
}
