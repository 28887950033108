import React, { useEffect, useMemo, useRef } from 'react';
import './index.scss';

interface ILoadingIndicator {
  // eslint-disable-next-line react/require-default-props
    title?: string;
    // eslint-disable-next-line react/require-default-props
    icon?: JSX.Element;
  // eslint-disable-next-line react/require-default-props
    loaded?: number;
  // eslint-disable-next-line react/require-default-props
    total?: number;
}

export const LoadingIndicator = (props: ILoadingIndicator): JSX.Element => {
  const {
    title,
    total,
    icon,
    loaded,
  } = props;
  const progressbar = useRef(null);
  const changeProgress = (): void => {
    progressbar.current.style.width = `${getPercentage}%`;
  };

  useEffect(() => {
    /* change progress after 1 second (only for showcase) */
    const timerID = setTimeout(() => {
      changeProgress();
      clearTimeout(timerID);
    }, 0);
    return () => {
      clearTimeout(timerID);
    };
  }, [loaded]);

  const getPercentage = useMemo(() => `${Math.round((loaded * 100) / total)}`, [loaded, total]);

  return (
    <div className="loading-indicator">
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              {
                icon ? (
                  <div className="loading-indicator__icon">
                    {icon}
                  </div>
                ) : <></>
              }
              <div className="loading-indicator__desc">
                {
                  title ? (
                    <span>{title}</span>
                  ) : <></>
                }
                <h3>{getPercentage}%</h3>
              </div>
            </div>
            <div
              className="progress-container"
              style={{
                width: '250px',
              }}
            >
              <div className="progress" ref={progressbar} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
