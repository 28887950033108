export enum EThemeType {
  LIGHT = 'light',
  DARK = 'dark',
  P2P = 'p2p',
}

export interface IThemeContext {
  currentTheme: EThemeType;
  isLight: boolean;
  isDark: boolean;
  isP2P: boolean;

  changeTheme(theme: EThemeType): void;
}
