/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { TooltipProps } from './model';
import { RelatedTriggerDrop } from '../related-trigger-drop';
import { factoryRelatedTriggerOnHover } from '../related-trigger-drop/related-trigger-drop-factories';

export const Tooltip = (props: TooltipProps): JSX.Element => {
  const { children, tip, placement = 'top' } = props;

  return (
    <RelatedTriggerDrop
      placement={placement}
      trigger={factoryRelatedTriggerOnHover(children)}
      drop={() => <>{tip}</>}
    />
  );
};
