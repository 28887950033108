import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
import { TOrganizationUserInfo } from '../../models/permission';
import { OrganizationUserShortInfo } from '../organization-short-info';
import { LogoutButton } from '../logout-button';

/* import { RenderIf } from '../render-if';
import { ButtonWithIcon } from '../button-with-icon';
import { isOrganizationUserAdmin } from '../../utils/organization-user-util';
import { SettingsIcon } from '../icons/setting-icon'; */

export interface LogoutControlProps {
  organizationUser: TOrganizationUserInfo;
  onLogout: () => void;
}

export const LogoutControl = (props: LogoutControlProps): JSX.Element => {
  const { organizationUser, onLogout } = props;
  // const navigate = useNavigate();
  // const location = useLocation();

  /* const redirectTo: (_pathname: string) => () => void = (_pathname) => () => {
    navigate(_pathname);
  }; */

  // const isSettingsPage = new RegExp('settings').test(location.pathname);

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters align-items-center flex-nowrap">
        {/* <div className="col-auto mr-3">
          <RenderIf clause={isOrganizationUserAdmin(organizationUser)}>
            <ButtonWithIcon
              extraClass={isSettingsPage ? '' : 'settings-page-not-active'}
              onClick={redirectTo('/settings/user-data/')}
            >
              <SettingsIcon />
            </ButtonWithIcon>
          </RenderIf>
        </div> */}
        <div className="col-auto mr-5">
          <OrganizationUserShortInfo organizationUser={organizationUser} />
        </div>
        <div className="col-auto">
          <LogoutButton onClick={onLogout} />
        </div>
      </div>
    </div>
  );
};
