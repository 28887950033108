import React, { ReactNode } from 'react';
import { Typography, TypographyColor, TypographyVariant } from '@/components/typography';
import './index.scss';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';

export type TextButtonVariant = 'regular' | 'important' | 'subtitleBold';
export type TextButtonSize = 'medium' | 'small' | 'none';

export interface TextButtonProps {
	children?: ReactNode;
	onClick?: () => void;
	disabled?: boolean;
	variant?: TextButtonVariant,
	size?: TextButtonSize,
	fluid?: boolean,
	textClasses?: string
	btnClasses?: string
	type?: 'submit' | 'reset' | 'button' | undefined;
	color?: TypographyColor;
}

const TEXT_BUTTON_TO_TYPOGRAPHY: { [key: string]: TypographyVariant } = {
	regular: 'bodyRegular',
	important: 'bodyBold',
	subtitleBold: 'subtitleBold',
};

export const TextButton = (props: TextButtonProps): JSX.Element => {
	const {
		children,
		onClick,
		disabled,
		variant = 'regular',
		size = 'medium',
		fluid = false,
		textClasses = '',
		btnClasses = '',
		type = 'button',
		color,
	} = props;

	const { currentTheme } = useThemeContext();

	return (
		<button
			className={classnames('text-button', {
				[`text-button__variant-${variant}`]: variant,
				[`text-button__size-${size}`]: size,
				[`text-button__fluid-${fluid}`]: fluid,
				'text-button__theme-light': currentTheme === 'light',
				'text-button__theme-dark': currentTheme === 'dark',
				'text-button__theme-p2p': currentTheme === 'p2p',
			}, [btnClasses])}
			onClick={onClick}
			disabled={disabled}
			/* eslint-disable-next-line react/button-has-type */
			type={type}
		>
			<Typography
				as="div"
				extraClass={classnames(textClasses)}
				variant={TEXT_BUTTON_TO_TYPOGRAPHY[variant]}
				color={color}
			>
				{children}
			</Typography>
		</button>
	);
};
