import { IOrderIdPledgeResponse, OrderIdPledgeDataService } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class OrderIdPledgesDataServiceImpl implements OrderIdPledgeDataService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getPledgeData(orderID: string): HttpResponse<IOrderIdPledgeResponse[]> {
    return this.strictHttpRequest.get<IOrderIdPledgeResponse[]>(`/credits/${orderID}/pledges/`);
  }
}
