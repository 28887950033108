import { AuthServiceImpl } from '@/services/auth-service/auth-service-impl';
import { OrganizationsServiceImpl } from '@/services/organizations-service/organizations-service-impl';
import { AuthMetaServiceImpl } from '@/services/auth-meta-service/auth-meta-service-impl';
import { OrganizationMetaServiceImpl } from '@/services/organization-meta-service/organization-meta-service-impl';
import { WeakAxiosHttpRequest } from '@/http-request/axios-implementations/weak-axios-http-request';
import { StrictAxiosHttpRequest } from '@/http-request/axios-implementations/strict-axios-http-request';
import { OrderServiceImpl } from '@/services/order-service/order-service-impl';
import { OrderCommentsServiceImpl } from '@/services/order-comments-service/order-comments-service-impl';
import { OrderIdStatusServiceImpl } from '@/services/order-id-status-service/order-id-status-service-impl';
import { OrderIdPersonalDataServiceImpl } from '@/services/order-id-personal-data-service/order-id-personal-data-service-impl';
import { OrderIdCreditDataServiceImpl } from '@/services/order-id-credit-data-service/order-id-credit-data-service-impl';
import { HandbookStatusMockService } from '@/services/handbook-status-service/handbook-status-mock-service';
import { HandbookRoleMockService } from '@/services/handbook-role-service/handbook-role-mock-service';
import { HandbookSignatoryMockService } from '@/services/handbook-signatory-service/handbook-signatory-mock-service';
import { HandbookRegionMockService } from '@/services/handbook-region-service/handbook-region-mock-service';
import { MediatorAttachmentsImpl } from '@/services/mediator-attachments/mediator-attachments-impl';
import { DataCollectionServiceImpl } from '@/services/data-collection-service/data-collection-service-impl';
import { OrderIdDocumentDataServiceImpl } from '@/services/order-id-document-data-service/order-id-document-data-service-impl';
import { ReportServiceImpl } from '@/services/report-service/report-service-impl';
import { OrderStatusService } from '@/services/order-status-service/order-status-service';
import { OtpServiceImpl } from '@/services/otp-service/otp-service-impl';
import { OrderAdditionalDataServiceImpl } from '@/services/order-additional-data/order-additional-data-impl';
import { OrganizationsAttachmentsImpl } from '@/services/handbook-documents-service/handbook-documents-service-impl';
import { PrintFormServiceImpl } from '@/services/print-form-service/print-form-service-impl';
import { DashboardServiceImpl } from '@/services/dashboard-service/dashboard-service-impl';
import { OrderIdPaymentScheduleServiceImpl } from '@/services/order-id-payment-schedule/order-id-payment-schedule-service-impl';
import { OrderIdPledgesDataServiceImpl } from '@/services/order-id-pledges-data-service/order-id-pledges-data-service-impl';
import { BorrowerServiceImpl } from '@/services/borrowers-service/borrower-service-impl';
import { AdministrationServiceImpl } from '@/services/administration-service/administration-service-impl';
import { CreditorsServiceImpl } from '@/services/creditors-service/creditors-service-impl';
import { ProductServiceImpl } from '@/services/products-service/product-service-impl';
import { AlertService } from '@/services/alerts-service/alert-service-impl';
import { ConfigServiceImpl } from '@/services/config-service/config-service-impl';
import { ArbitrationServiceImpl } from '@/services/arbitration-service/arbitration-service-impl';
import { CreditsDownloadAttachmentImpl } from '@/services/credits-download-attachment/credits-download-attachment-impl';
import { TApplicationContextBeanMap } from './model';

export const bootstrapApplicationContext = (): TApplicationContextBeanMap => {
  const beanMap: TApplicationContextBeanMap = new Map<string, unknown>();

  const handbookStatusService = new HandbookStatusMockService();
  const handbookRoleService = new HandbookRoleMockService();
  const handbookSignatoryService = new HandbookSignatoryMockService();
  const handbookRegionService = new HandbookRegionMockService();

  // SIMPLE BEANS (without constructor arguments)
  const authMetaService = new AuthMetaServiceImpl();
  const organizationMetaService = new OrganizationMetaServiceImpl();

  // COMPLEX BEANS (with constructor arguments)
  const weakHttpRequest = new WeakAxiosHttpRequest();
  const strictHttpRequest = new StrictAxiosHttpRequest();

  const authService = new AuthServiceImpl(authMetaService, strictHttpRequest);
  const otpService = new OtpServiceImpl(authMetaService);
  // eslint-disable-next-line max-len
  const organizationsService = new OrganizationsServiceImpl(
    strictHttpRequest,
    weakHttpRequest,
    authMetaService,
  );
  const orderService = new OrderServiceImpl(strictHttpRequest);
  const orderCommentService = new OrderCommentsServiceImpl(strictHttpRequest);
  const orderIdStatusService = new OrderIdStatusServiceImpl(strictHttpRequest);
  const orderIdPersonalDataService = new OrderIdPersonalDataServiceImpl(
    strictHttpRequest,
  );
  const orderIdCreditDataService = new OrderIdCreditDataServiceImpl(
    strictHttpRequest,
  );
  const orderIdDocumentDataService = new OrderIdDocumentDataServiceImpl(
    strictHttpRequest,
  );
  const dataCollectionService = new DataCollectionServiceImpl(
    strictHttpRequest,
  );
  const reportService = new ReportServiceImpl(strictHttpRequest);
  const orderStatusService = new OrderStatusService(strictHttpRequest);
  const orderAdditionalDataService = new OrderAdditionalDataServiceImpl(
    strictHttpRequest,
  );
  const organizationsAttachmentsService = new OrganizationsAttachmentsImpl(
    strictHttpRequest,
  );
  const printFormServiceImpl = new PrintFormServiceImpl(strictHttpRequest);
  const dashboardServiceImpl = new DashboardServiceImpl(strictHttpRequest);
  const paymentScheduleService = new OrderIdPaymentScheduleServiceImpl(
    strictHttpRequest,
  );
  const orderIdPledgesDataServiceImpl = new OrderIdPledgesDataServiceImpl(
    strictHttpRequest,
  );
  const borrowerServiceImpl = new BorrowerServiceImpl(strictHttpRequest);
  const administrationServiceImpl = new AdministrationServiceImpl(
    strictHttpRequest,
  );
  const creditorsServiceImpl = new CreditorsServiceImpl(strictHttpRequest);
  const productServiceImpl = new ProductServiceImpl(strictHttpRequest);
  const alertService = new AlertService(strictHttpRequest);
  const mediatorAttachments = new MediatorAttachmentsImpl(strictHttpRequest);
  const configService = new ConfigServiceImpl(strictHttpRequest);
  const arbitrationService = new ArbitrationServiceImpl(strictHttpRequest);
  const creditsDownloadAttachment = new CreditsDownloadAttachmentImpl(strictHttpRequest);

  // BINDING BEANS
  beanMap.set('authMetaService', authMetaService);
  beanMap.set('otpService', otpService);
  beanMap.set('organizationMetaService', organizationMetaService);
  beanMap.set('weakHttpRequest', weakHttpRequest);
  beanMap.set('strictHttpRequest', strictHttpRequest);
  beanMap.set('authService', authService);
  beanMap.set('organizationsService', organizationsService);
  beanMap.set('orderService', orderService);
  beanMap.set('orderCommentService', orderCommentService);
  beanMap.set('orderIdStatusService', orderIdStatusService);
  beanMap.set('orderIdPersonalDataService', orderIdPersonalDataService);
  beanMap.set('orderIdCreditDataService', orderIdCreditDataService);
  beanMap.set('orderIdDocumentDataService', orderIdDocumentDataService);
  beanMap.set('orderAdditionalDataService', orderAdditionalDataService);
  beanMap.set('paymentScheduleService', paymentScheduleService);
  beanMap.set('orderIdPledgesDataServiceImpl', orderIdPledgesDataServiceImpl);
  beanMap.set('borrowerServiceImpl', borrowerServiceImpl);

  beanMap.set('administrationService', administrationServiceImpl);
  beanMap.set('creditorsService', creditorsServiceImpl);
  beanMap.set('productService', productServiceImpl);

  beanMap.set('handbookStatusService', handbookStatusService);
  beanMap.set('handbookSignatoryService', handbookSignatoryService);
  beanMap.set('handbookRegionService', handbookRegionService);
  beanMap.set('handbookRoleService', handbookRoleService);
  beanMap.set('dataCollectionService', dataCollectionService);
  beanMap.set('orderStatusService', orderStatusService);

  beanMap.set(
    'organizationsAttachmentsService',
    organizationsAttachmentsService,
  );

  beanMap.set('reportService', reportService);
  beanMap.set('printFormServiceImpl', printFormServiceImpl);
  beanMap.set('dashboardServiceImpl', dashboardServiceImpl);
  beanMap.set('alertService', alertService);
  beanMap.set('mediatorAttachmentService', mediatorAttachments);
  beanMap.set('configService', configService);
  beanMap.set('arbitrationService', arbitrationService);
  beanMap.set('creditsDownloadAttachment', creditsDownloadAttachment);

  return beanMap;
};
