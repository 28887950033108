import { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponseType } from '@/types/errorResponseType';
import { isErrorArrayTypeGuard, isErrorChangePasswordTypeGuard, isErrorStringTypeGuard } from './typeGuard';

const statusMap = new Map()
  .set(401, 'Пользователь не авторизован!')
  .set(403, 'Нет прав к доступу этих данных, статус ошибки 403!')
  .set(503, 'Ошибка сервера, статус ошибки 503!')
  .set(500, 'Внутренняя ошибка сервера, статус ошибки 500!');

const checkResponseStatus = (error: AxiosResponse<ErrorResponseType>): string => {
  if (error?.status && statusMap.has(error?.status)) {
    return statusMap.get(error?.status);
  }
  return 'Ошибка сети';
};

interface IErrorResponseWithTextAndType {
  frame: string;
  text: string;
}

export interface IErrorObjWithCodeAndErrorType {
  code: string | number;
  error: Array<IErrorResponseWithTextAndType> | string;
  data?: any;
}

export const getErrorTextFromResponse = (error: AxiosError<ErrorResponseType>): string => {
  const response: AxiosResponse<ErrorResponseType, any> = error?.response;

  checkResponseStatus(response);

  if (!response?.data?.detail) {
    return error?.message;
  }

  if (isErrorChangePasswordTypeGuard(response?.data?.detail)) {
    return response?.data?.detail?.detail;
  }

  if (isErrorStringTypeGuard(response?.data?.detail)) {
    return response?.data?.detail ?? error?.message;
  }

  if (isErrorArrayTypeGuard(response?.data?.detail) && response?.data?.detail?.length > 0
  ) {
    const newError = response?.data?.detail.map((err) => {
      const { loc, msg } = err;
      const frame = loc[loc.length - 1];
      return `[${frame}]: ${msg}!`;
    });
    return newError.join('\n\n');
  }

  return error?.message;
};

// eslint-disable-next-line max-len
export const getErrorObjWithCodeTextAndBody = (error: AxiosError<ErrorResponseType>): IErrorObjWithCodeAndErrorType | undefined => {
  if (statusMap.has(error?.response?.status)) {
    return {
      code: error?.response?.status,
      error: [{
        frame: '',
        text: statusMap.get(error?.response?.status),
      }],
      data: error?.response?.data?.detail,
    };
  }
  if (error?.response?.status >= 500
    || error?.response?.status >= 400) {
    if (isErrorChangePasswordTypeGuard(error?.response?.data?.detail)) {
      return {
        code: error?.response?.status,
        error: error?.response?.data?.detail?.detail,
        data: error?.response?.data?.detail,
      };
    }
    if (isErrorArrayTypeGuard(error?.response?.data?.detail)
      && error?.response?.data?.detail?.length > 0
    ) {
      const errorWithTextAndLoc = error?.response?.data?.detail?.map((err) => {
        const { loc, msg } = err;
        return {
          frame: loc[loc.length - 1],
          text: msg,
          data: error?.response?.data?.detail,
        };
      });

      return {
        code: error?.response?.status,
        error: [...errorWithTextAndLoc],
      };
    }
  }

  return {
    code: error?.response?.status,
    error: [
      {
        frame: error?.name,
        text: error?.message,
      },
    ],
    data: error?.response?.data?.detail,
  };
};
