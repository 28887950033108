import { HttpRequest, HttpResponse } from '@/http-request/model';
import { ICreditsDownloadAttachment } from '@/services/credits-download-attachment/model';

export class CreditsDownloadAttachmentImpl implements ICreditsDownloadAttachment {
  private readonly api: HttpRequest;

  constructor(api: HttpRequest) {
    this.api = api;
  }

  public async getDownloadAttachment(creditId: number, attachmentID: number): HttpResponse<File | Blob> {
    return this.api.getFile<Blob>(`/credits/${creditId}/download-doc/${attachmentID}/`);
  }
}
