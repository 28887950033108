import React, { useEffect, useState } from 'react';
import './index.scss';
import classnames from 'classnames';

type ToggleButtonProps = {
  icons?: TIcons,
  defaultChecked: boolean,
  onChange: (state: boolean) => void,
  disabled?: boolean,
  className?: string,
}

type TIcons = {
  checked: JSX.Element,
  unchecked: JSX.Element,
}

const CheckedIcon = (): JSX.Element => <div className="defaultCircleIcon" />;

export const ToggleButton = (props: ToggleButtonProps): JSX.Element => {
  const {
    defaultChecked, onChange, disabled, className,
  } = props;
  const [toggle, setToggle] = useState(defaultChecked);

  useEffect(() => {
    setToggle(defaultChecked);
  }, [defaultChecked]);

  const triggerToggle = (): void => {
    if (disabled) {
      return;
    }

    onChange?.(!toggle);
  };

  const getIcon = (type: 'checked' | 'unchecked'): JSX.Element => {
    const { icons } = props;
    if (!icons) {
      return null;
    }

    return icons[type] === undefined
      ? ToggleButton.defaultProps.icons[type]
      : icons[type];
  };

  return (
    <button
      type="button"
      onClick={triggerToggle}
      className={classnames('button-toggle', {
        'button-toggle--checked': toggle || defaultChecked,
        'button-toggle--disabled': disabled,
      }, [className])}
    >
      <div className="button-toggle-container">
        <div className="button-toggle-check">
          <span>{ getIcon('checked') }</span>
        </div>
        <div className="button-toggle-uncheck">
          <span>{ getIcon('unchecked') }</span>
        </div>
      </div>
      <div className="button-toggle-circle" />
      <input type="checkbox" aria-label="Toggle Button" className="button-toggle-input" />
    </button>
  );
};

ToggleButton.defaultProps = {
  icons: {
    checked: <CheckedIcon />,
    unchecked: <CheckedIcon />,
  },
  className: '',
  disabled: false,
  defaultChecked: false,
};
