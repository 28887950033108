import React, { createContext, useContext, useState } from 'react';
import { useService, useServiceToast } from '../../hooks/useService';
import { IAlert, IAlertService } from '../../services/alerts-service/model';
import { HttpResponse } from '../../http-request/model';
import { getErrorTextFromResponse } from '../../utils/getErrorTextFromResponse';

interface IAlertContext {
  isLoading: boolean;
  unreadAlerts: IAlert[];
  readAlerts: IAlert[];
  unseenAlerts: IAlert[];

  getUnreadAlerts(): HttpResponse<IAlert[]>;

  markAsReadAlerts(alertIds: number[]): HttpResponse<void>;

  getReadAlerts(): HttpResponse<IAlert[]>;

  getUnseenAlerts(): HttpResponse<IAlert[]>;

  markAsSeenAlerts(alertIds: number[]): HttpResponse<void>;
}

const AlertContext = createContext<IAlertContext>({
  isLoading: false,
  unseenAlerts: [],
  unreadAlerts: [],
  readAlerts: [],
  getReadAlerts: () => null,
  getUnreadAlerts: () => null,
  getUnseenAlerts: () => null,
  markAsReadAlerts: () => null,
  markAsSeenAlerts: () => null,
});

const AlertContainer: React.FC = ({ children }) => {
  const alertService = useService<IAlertService>('alertService');
  const toast = useServiceToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [unreadAlerts, setUnreadAlerts] = useState<IAlert[]>([]);
  const [readAlerts, setReadAlerts] = useState<IAlert[]>([]);
  const [unseenAlerts, setUnseenAlerts] = useState<IAlert[]>([]);

  const getUnreadAlerts = (): HttpResponse<IAlert[]> => {
    setIsLoading(true);
    return alertService.getUnreadAlerts()
      .then((response) => {
        if (response.status === 'ERROR') {
          toast({ message: getErrorTextFromResponse(response.error) });
        }

        if (response.status === 'OK') {
          setUnreadAlerts(response.data);
        }

        return response;
      })
      .finally(() => setIsLoading(false));
  };

  const getReadAlerts = (): HttpResponse<IAlert[]> => {
    setIsLoading(true);
    return alertService.getReadAlerts()
      .then((response) => {
        if (response.status === 'ERROR') {
          toast({ message: getErrorTextFromResponse(response.error) });
        }

        if (response.status === 'OK') {
          setReadAlerts(response.data);
        }

        return response;
      })
      .finally(() => setIsLoading(false));
  };

  const getUnseenAlerts = (): HttpResponse<IAlert[]> => {
    setIsLoading(true);
    return alertService.getUnseenAlerts()
      .then((response) => {
        if (response.status === 'ERROR') {
          toast({ message: getErrorTextFromResponse(response.error) });
        }

        if (response.status === 'OK') {
          setUnseenAlerts(response.data);
        }

        return response;
      })
      .finally(() => setIsLoading(false));
  };

  const markAsReadAlerts = (alertIds: number[]): HttpResponse<void> => {
    setIsLoading(true);
    return alertService.markAlertsAsRead(alertIds)
      .then((response) => {
        if (response.status === 'ERROR') {
          toast({ message: getErrorTextFromResponse(response.error) });
        }
        return response;
      })
      .finally(() => setIsLoading(false));
  };

  const markAsSeenAlerts = (alertIds: number[]): HttpResponse<void> => {
    setIsLoading(true);
    return alertService.markAlertsAsSeen(alertIds)
      .then((response) => {
        if (response.status === 'ERROR') {
          toast({ message: getErrorTextFromResponse(response.error) });
        }
        return response;
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <AlertContext.Provider
      value={{
        isLoading,
        readAlerts,
        unreadAlerts,
        unseenAlerts,
        getReadAlerts,
        getUnreadAlerts,
        getUnseenAlerts,
        markAsReadAlerts,
        markAsSeenAlerts,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContainer;

export const useAlertContainer = (): IAlertContext => useContext(AlertContext);
