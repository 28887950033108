import { AxiosError, AxiosRequestHeaders } from 'axios';
import {
  ErrorResponseType,
} from '@/types/errorResponseType';
import {
  strictOrgHeader,
} from '@/api/http-header';
import {
  getErrorTextFromResponse,
} from '@/utils/getErrorTextFromResponse';
import instance
  from '../../api/http-axios';
import {
  HttpRequest, HttpRequestErrorResponse, HttpRequestOkResponse, HttpResponse, TypeResponseType,
} from '../model';

export class StrictAxiosHttpRequest implements HttpRequest {
  get<T>(url: string): HttpResponse<T> {
    return instance.get<T>(url, { headers: strictOrgHeader() })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      }, (reject) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...reject,
          status: 'ERROR',
          error: reject as AxiosError<ErrorResponseType>,
          axiosError: reject,
          statusCode: reject?.status,
        };

        return errorResponse;
      })
      .catch((error) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: error as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  getFile<T>(url: string, responseType: TypeResponseType): HttpResponse<T> {
    return instance.get<T>(url, {
      headers: strictOrgHeader(),
      responseType: responseType || 'blob',
    })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const maybeErrorDetail = getErrorTextFromResponse(error);
        const normalizedError = maybeErrorDetail ? new Error(maybeErrorDetail) : error;
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: normalizedError as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  post<T, U>(url: string, body?: U, config?: AxiosRequestHeaders): HttpResponse<T> {
    return instance.post<T>(url, body, { headers: strictOrgHeader(), ...config })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: error as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  /*  const maybeErrorDetail = error?.response?.data?.detail;
   const normalizedError = maybeErrorDetail ? new Error(maybeErrorDetail) : error; */

  delete<T>(url: string): HttpResponse<T> {
    return instance.delete<T>(url, { headers: strictOrgHeader() })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: undefined,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: error as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  patch<T, U>(url: string, body: U): HttpResponse<T> {
    return instance.patch(url, body, { headers: strictOrgHeader() })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: error as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  put<T, U>(url: string, body: U, config?: AxiosRequestHeaders): HttpResponse<T> {
    return instance.put(url, body, { headers: strictOrgHeader(), ...config })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const maybeErrorDetail = getErrorTextFromResponse(error);
        const normalizedError = maybeErrorDetail ? new Error(maybeErrorDetail) : error;
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: normalizedError as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  /*  private getConfig(contentType?: string): AxiosRequestConfig {
   const authMeta = this.authMetaService.getAuthMeta();
   const organizationMeta = this.organizationMetaService.getOrganizationMeta();
   return {
   headers: {
   authorization: `${authMeta.token_type} ${authMeta.access_token}`,
   'org-token': organizationMeta.token,
   'Content-Type': contentType,
   },
   };
   } */
}
