import React, { useMemo } from 'react';
import { ButtonV2Props } from '../primary-button';
import { IconBoxWrapper } from '../icon-wrapper';
import { Typography } from '../typography';
import './index.scss';

export const GhostButton = (props: ButtonV2Props): JSX.Element => {
  const {
    children,
    onClick,
    disabled,
    icon: IconComponent,
    fluid = false,
    outlined = false,
    small = false,
    type = 'button',
    extraClass = '',
  } = props;

  const hasIcon = useMemo<boolean>(() => Boolean(IconComponent), [IconComponent]);

  const extra = extraClass || '';

  return (
    <button
      className={`ghost-button-v2
      ghost-button-v2__size-small_${small}
      ghost-button-v2__fluid-${fluid}
      ghost-button-outlined-${outlined}
      ghost-button-v2__size
      ghost-button-v2__color
      ${extra}
      `}
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {hasIcon ? (
        <div className="ghost-button-v2_icon-holder">
          <IconBoxWrapper size="20x20">
            <IconComponent />
          </IconBoxWrapper>
        </div>
      ) : (<></>)}
      <Typography
        extraClass="ghost-button-v2_text-holder"
        as="p"
        variant="bodyBold"
        color="default"
        weight="500"
      >
        {children}
      </Typography>
    </button>
  );
};
