import React from 'react';
import { PageHeaderComponent } from '@/components/page-header';
import { useThemeContext } from '@/containers/theme-context';
import './layout-head.scss';

const LayoutHead: React.FC = (): React.JSX.Element => {
  const { currentTheme } = useThemeContext();
  return (
    <div className={`layout-head layout-head-theme__${currentTheme}`}>
      <div className="container-fluid container-fluid-custom-limiter px-5">
        <PageHeaderComponent />
      </div>
    </div>
  );
};

export default LayoutHead;
