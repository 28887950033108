import React, { createContext, useState } from 'react';
import { WebSocketResponse } from '../../components/socket-notification/model';

interface ISocketContext {
  socketData?: WebSocketResponse;
  setSocketData?: (data?: WebSocketResponse) => void;
}

const contextDefault: ISocketContext = {
  socketData: null,
  setSocketData: () => {
  },
};

export const socketContext = createContext<ISocketContext>(contextDefault);

export const SocketDataProvider: React.FC = (props) => {
  const { children } = props;
  const [socketData, setSocketData] = useState<WebSocketResponse>(null);

  return (
    <socketContext.Provider
      value={{
        socketData,
        setSocketData,
      }}
    >
      {children}
    </socketContext.Provider>
  );
};
