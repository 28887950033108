import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { useLocation } from 'react-router-dom';
import { useAlertContainer } from '@/containers/alert-container/alert-container';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';
import { IconButtonWrapper } from '../../icon-wrapper';
import { BellIcon } from '../../icons/bell';
import NotificationList from './notification-list';
import useClickOutside from '../../../hooks/useClickOutside';

const NotificationWrapper: React.FC = () => {
	const location = useLocation();
	const {
		getUnseenAlerts,
		unseenAlerts,
	} = useAlertContainer();

	const { currentTheme } = useThemeContext();

	useEffect(() => {
		getUnseenAlerts();
	}, [location]);

	const [referenceElement, setReferenceElement] = useState(null);
	const [popperElement, setPopperElement] = useState(null);
	const { styles, attributes } = usePopper(referenceElement, popperElement);

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const onClickNotificationBtn = (): void => {
		setIsOpen((prevState) => !prevState);
	};

	useClickOutside(popperElement, () => {
		setIsOpen(false);
	}, referenceElement);

	return (
		<>
			<IconButtonWrapper
				data-alerts={unseenAlerts?.length}
				ref={setReferenceElement}
				onClick={onClickNotificationBtn}
				extraClass={classnames('notification-popper__badge', {
					'notification-popper__badge_visible': unseenAlerts?.length > 0,
					[`notification-popper__badge_theme-${currentTheme}`]: currentTheme,
				})}
			>
				<BellIcon />
			</IconButtonWrapper>
			<div
				{...attributes.popper}
				style={styles.popper}
				ref={setPopperElement}
				className={classnames('notification-popper', {
					[`notification-popper_${isOpen}`]: isOpen,
					[`notification-popper_theme-${currentTheme}`]: currentTheme,
				})}
			>
				{
					isOpen && <NotificationList isOpen={isOpen} />
				}
			</div>
		</>
	);
};

export default NotificationWrapper;
