import { EUserRole } from '@/models/permission';

export const isAdminRole = (slug: EUserRole): boolean => slug === EUserRole.ADMIN;
export const isBailiffRole = (slug: EUserRole): boolean => slug === EUserRole.BAILIFF;
export const isNotaryRole = (slug: EUserRole): boolean => slug === EUserRole.NOTARY;
export const isLawyerRole = (slug: EUserRole): boolean => slug === EUserRole.LAWYER;
export const isSecurityRole = (slug: EUserRole): boolean => slug === EUserRole.SECURITY;
export const isCollectorRole = (slug: EUserRole): boolean => slug === EUserRole.COLLECTOR;
export const isSupervisorRole = (slug: EUserRole): boolean => slug === EUserRole.SUPERVISOR;
export const isSpectatorRole = (slug: EUserRole): boolean => slug === EUserRole.SPECTATOR;
export const isMediatorRole = (slug: EUserRole): boolean => slug === EUserRole.MEDIATOR;
export const isArbitrationRole = (slug: EUserRole): boolean => slug === EUserRole.ARBITRATION;
export const isJudgeRole = (slug: EUserRole): boolean => slug === EUserRole.JUDGE;
