import React from 'react';

export const FilterIcon = (): JSX.Element => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.29289 1.29289C1.10536 1.48043 1 1.73478 1 2V4.586C1.00006 4.85119 1.10545 5.10551 1.293 5.293L7.707 11.707C7.89455 11.8945 7.99994 12.1488 8 12.414V19L12 15V12.414C12.0001 12.1488 12.1055 11.8945 12.293 11.707L18.707 5.293C18.8946 5.10551 18.9999 4.85119 19 4.586V2C19 1.73478 18.8946 1.48043 18.7071 1.29289C18.5196 1.10536 18.2652 1 18 1H2C1.73478 1 1.48043 1.10536 1.29289 1.29289Z"
      stroke="currentColor"
      strokeOpacity="0.5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
