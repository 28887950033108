/* eslint-disable react/display-name */
import React, { ReactNode } from 'react';
import { RelatedTriggerElement } from './model';
import { Typography } from '../typography';
import { IconBoxWrapper, IconWrapperColor } from '../icon-wrapper';
import { FilterIcon } from '../icons/filter-icon';

export const factoryRelatedTriggerOnHover: (children?: ReactNode) => (
    props: RelatedTriggerElement
) => JSX.Element = (children) => (props) => {
  const { triggerRef, setVisible } = props;

  return (
    <div
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      className="related-trigger-drop__on-hover-trigger"
      ref={triggerRef}
    >
      {children}
    </div>
  );
};

export const factoryRelatedTriggerTypography: (title?: string) => (
    props: RelatedTriggerElement
) => JSX.Element = (title) => (props) => {
  const {
    triggerRef, setVisible, visible, getCheckedInfo,
  } = props;

  return (
    <button
      style={{ width: '100%' }}
      type="button"
      onClick={() => setVisible(!visible)}
      className="related-trigger-drop__typography"
      ref={triggerRef}
    >
      <div className="row m-0 flex-nowrap align-items-center">
        <Typography
          variant="subtitleRegular"
          extraClass="display-sub-title-1-entity mr-2"
        >
          {title}
        </Typography>
        <IconBoxWrapper
          extraClass="order-filter-icon"
          color={visible || getCheckedInfo(title) ? '72BF44' as IconWrapperColor : null}
        >
          <FilterIcon />
        </IconBoxWrapper>
      </div>
    </button>
  );
};
