import { AxiosError, AxiosRequestHeaders } from 'axios';
import { ErrorResponseType } from '@/types/errorResponseType';
import { weakHeader } from '@/api/http-header';
import { getErrorTextFromResponse } from '@/utils/getErrorTextFromResponse';
import {
  HttpRequest, HttpRequestErrorResponse, HttpRequestOkResponse, HttpResponse,
} from '../model';
import instance from '../../api/http-axios';

export class WeakAxiosHttpRequest implements HttpRequest {
  get<T>(url: string): HttpResponse<T> {
    return instance.get<T>(url, { headers: weakHeader() })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      }, (reject) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...reject,
          status: 'ERROR',
          error: reject as AxiosError<ErrorResponseType>,
          axiosError: reject,
          statusCode: reject?.status,
        };

        return errorResponse;
      })
      .catch((error) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: error as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  post<T, U>(url: string, body: U, config?: AxiosRequestHeaders): HttpResponse<T> {
    return instance.post<T>(url, body, { headers: { ...weakHeader(), ...config } })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: error as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  put<T, U>(url: string, body: U, config?: AxiosRequestHeaders): HttpResponse<T> {
    return instance.put(url, body, { headers: weakHeader(), ...config })
      .then((response) => {
        const okResponse: HttpRequestOkResponse<T> = {
          ...response,
          status: 'OK',
          data: response.data,
          axiosData: response,
          statusCode: response?.status,
        };

        return okResponse;
      })
      .catch((error) => {
        const maybeErrorDetail = getErrorTextFromResponse(error);
        const normalizedError = maybeErrorDetail ? new Error(maybeErrorDetail) : error;
        const errorResponse: HttpRequestErrorResponse<T> = {
          ...error,
          status: 'ERROR',
          error: normalizedError as AxiosError<ErrorResponseType>,
          axiosError: error,
          statusCode: error?.status,
        };

        return errorResponse;
      });
  }

  async delete<T>(url: string): HttpResponse<T> {
    try {
      const response = await instance.delete<T>(url, { headers: weakHeader() });
      return {
        ...response,
        status: 'OK',
        data: response.data,
        axiosData: response,
        statusCode: response?.status,
      };
    } catch (error) {
      return {
        ...error,
        status: 'ERROR',
        error: error as AxiosError<ErrorResponseType>,
        axiosError: error,
        statusCode: error?.status,
      };
    }
  }

  async patch<T, U>(url: string, body: U): HttpResponse<T> {
    try {
      const response = await instance.patch<T>(url, body, { headers: weakHeader() });
      return {
        ...response,
        status: 'OK',
        data: response.data,
        axiosData: response,
        statusCode: response?.status,
      };
    } catch (error) {
      return {
        ...error,
        status: 'ERROR',
        error: error as AxiosError<ErrorResponseType>,
        axiosError: error,
        statusCode: error?.status,
      };
    }
  }
}
