import { HttpRequest, HttpResponse } from '../../http-request/model';
import { IAlert, IAlertService } from './model';

export class AlertService implements IAlertService {
  http: HttpRequest;

  constructor(http: HttpRequest) {
    this.http = http;
  }

  getReadAlerts(): HttpResponse<IAlert[]> {
    return this.http.get('/alerts/read/');
  }

  getUnreadAlerts(): HttpResponse<IAlert[]> {
    return this.http.get('/alerts/unread/');
  }

  getUnseenAlerts(): HttpResponse<IAlert[]> {
    return this.http.get('/alerts/unseen/');
  }

  markAlertsAsRead(alertIds: number[]): HttpResponse<void> {
    return this.http.patch('/alerts/unread/', { alert_ids: alertIds });
  }

  markAlertsAsSeen(alertIds: number[]): HttpResponse<void> {
    return this.http.patch('/alerts/unseen/', { alert_ids: alertIds });
  }
}
