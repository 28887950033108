import React from 'react';
import { createPortal } from 'react-dom';
import { LoadingBarIcon } from '../icons/loading-bar';
import './index.scss';

export interface GlobalLoaderProps {
    visible: boolean;
}

export const GlobalLoader = (props: GlobalLoaderProps): React.JSX.Element => {
  const { visible } = props;

  if (!visible) {
    return <></>;
  }

  return (
    createPortal((
      <div className="global-loader-root">
        <div className="global-loader-spinner">
          <LoadingBarIcon />
        </div>
      </div>
    ), document.getElementById('loader')) as React.JSX.Element
  );
};
