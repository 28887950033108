import React from 'react';

export const WarningIcon = (): JSX.Element => (
  <svg
    width="47"
    height="44"
    viewBox="0 0 47 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.5312 0.140627C19.7355 0.142913 16.8789 2.12463 14.6025 6.14063L3.24549 26.9269C1.48778 29.992 0.657833 32.1475 0.674061 34.4263C0.710404 39.5281 4.81212 43.5509 9.81692 43.5692H37.2455C42.2565 43.5692 46.3694 39.6058 46.3883 34.4263C46.3966 32.1543 45.5024 29.8618 43.7454 26.9269L32.5312 6.2846C30.4263 2.26403 27.3787 0.138341 23.5312 0.140627ZM23.5312 13.8549C24.7936 13.8549 25.8169 14.8789 25.8169 16.1406V25.2835C25.8169 26.5452 24.7936 27.5692 23.5312 27.5692C22.2688 27.5692 21.2455 26.5452 21.2455 25.2835V16.1406C21.2455 14.8789 22.2688 13.8549 23.5312 13.8549ZM23.5312 29.8549C24.7936 29.8549 25.8169 30.8789 25.8169 32.1406C25.8169 33.4023 24.7936 34.4263 23.5312 34.4263C22.2688 34.4263 21.2455 33.4023 21.2455 32.1406C21.2455 30.8789 22.2688 29.8549 23.5312 29.8549Z" fill="#72BF44" />
  </svg>
);
