import React, { createContext, FunctionComponent, useMemo } from 'react';
import { IApplicationContext } from './model';
import { ApplicationContextImpl } from './application-context-impl';
import { bootstrapApplicationContext } from './bootstrap-application-context';

const defaultApplicationContext: IApplicationContext = new ApplicationContextImpl(
  new Map<string, unknown>(),
);

export const ApplicationContext = createContext<IApplicationContext>(defaultApplicationContext);

export const ApplicationContextProvider: FunctionComponent = (props): JSX.Element => {
  const { children } = props;
  const applicationContext = useMemo<IApplicationContext>(
    () => new ApplicationContextImpl(bootstrapApplicationContext()),
    [],
  );

  function getBean<T>(beanName: string): T {
    return applicationContext.getBean<T>(beanName);
  }

  return (
    <ApplicationContext.Provider
      value={{ getBean }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
