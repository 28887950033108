import { MutableRefObject } from 'react';
import useEventListener from './useEventListener';

type Handler = (event: MouseEvent) => void

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: T | MutableRefObject<T> | any,
  handler: Handler,
  parentRef?: T | MutableRefObject<T> | any,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useEventListener(mouseEvent, (event: any) => {
    const el = ref?.current || ref;
    const elParent = parentRef?.current || parentRef;

    if (!el || el.contains(event.target as Node) || elParent?.contains(event.target as Node)) {
      return;
    }

    handler(event);
  });
}

export default useOnClickOutside;
