import React from 'react';

export const BanIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.477 14.89C12.3213 15.7114 10.9121 16.0971 9.49925 15.9785C8.08636 15.86 6.7611 15.245 5.75847 14.2424C4.75584 13.2399 4.14063 11.9148 4.02193 10.5019C3.90322 9.08901 4.2887 7.67975 5.11 6.524L13.477 14.892V14.89ZM14.891 13.476L6.524 5.11C7.67971 4.28801 9.08923 3.90197 10.5025 4.02037C11.9158 4.13877 13.2414 4.75395 14.2442 5.75678C15.247 6.75961 15.8622 8.08525 15.9806 9.49851C16.099 10.9118 15.713 12.3213 14.891 13.477V13.476ZM18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10Z"
      fill="currentColor"
      fillOpacity="0.5"
    />
  </svg>
);
