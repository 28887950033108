import React from 'react';

export const MoonFillIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5242 2.07481C11.7967 1.84564 11.1751 2.64064 11.5609 3.29897C12.1701 4.3373 12.4984 5.50897 12.4984 6.68397C12.4984 10.3656 9.51341 13.3506 5.83174 13.3506C5.13849 13.3506 4.43733 13.2381 3.80049 13.0381C3.07308 12.809 2.42524 13.604 2.81091 14.2623C4.29216 16.7889 6.99941 18.3506 9.99841 18.3506C14.6009 18.3506 18.3317 14.6198 18.3317 10.0173C18.3317 6.3498 15.9642 3.15647 12.5242 2.07481Z"
    />
  </svg>
);
