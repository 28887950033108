import React, { useContext } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
// import { LogoDark } from '../icons/logo-dark';
// import { ToggleSwitch } from '../theme-toggle-switch';
// import { SunFillIcon } from '../icons/sun-fill';
// import { MoonFillIcon } from '../icons/moon-fill';
import {
  hasUserPermissionToDashboard, hasUserPermissionToHandbooks, hasUserPermissionToOrders,
} from '@pages/utils/page-permission-validators';
import {
  LogoutControlContainer,
} from '@/containers/logout-control-container';
import { globalStateContext } from '@/containers/global-state';
import { useThemeContext } from '@/containers/theme-context';
import { RenderIf } from '../render-if';
import { HeaderLink, HeaderLinks } from '../header-link';
import { NotificationComponent } from '../notifications-component';
import './page-header.scss';

export const PageHeaderComponent = (): JSX.Element => {
  // const { isLightMode, setLightMode } = useContext(globalStateContext);
  const { organizationUserInfo } = useContext(globalStateContext);
  const { currentTheme } = useThemeContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = (pattern: string): boolean => Boolean(matchPath(pattern, pathname));

  const redirectTo: (_pathname: string) => () => void = (_pathname) => () => {
    navigate(_pathname);
  };

  /*   const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
   setLightMode(e.target.checked);
   }; */

  return (
    <div className={`row no-gutters align-items-center page-header-theme__${currentTheme}`}>
      <div className="col-auto flex-grow-0">
        {/* <LogoDark /> */}
      </div>
      <div className="col-auto flex-grow-1">
        <HeaderLinks>
          <RenderIf clause={hasUserPermissionToDashboard()}>
            <HeaderLink
              active={isActive('/dashboard/*')}
              onClick={redirectTo('/dashboard')}
            >
              Дашборд
            </HeaderLink>
          </RenderIf>
          <RenderIf clause={hasUserPermissionToOrders()}>
            <HeaderLink
              active={isActive('/order/*')}
              onClick={redirectTo('/order')}
            >
              Заявки
            </HeaderLink>
          </RenderIf>
          <RenderIf clause={hasUserPermissionToHandbooks(organizationUserInfo)}>
            <HeaderLink
              active={isActive('/handbook/*')}
              onClick={redirectTo('/handbook/users')}
            >
              Справочник
            </HeaderLink>
          </RenderIf>
        </HeaderLinks>
      </div>
      <div className="col-auto flex-grow-0">
        <div className="page-header-controls">
          <NotificationComponent />
          <div className="page-header-control">
            {/* <ToggleSwitch
             checked={isLightMode}
             onChange={handleChange}
             checkIcon={MoonFillIcon}
             uncheckIcon={SunFillIcon}
             /> */}
          </div>
          <div className="page-header-control">
            <LogoutControlContainer />
          </div>
        </div>
      </div>
    </div>
  );
};
