export enum ENotificationsTypes {
  credit_responsible_update = 'credit_responsible_update',
  credit_to_confirm = 'credit_to_confirm',
  credit_responsible_update_many = 'credit_responsible_update_many',
  credit_to_confirm_many = 'credit_to_confirm_many',
  load_from_1C = 'load_from_1C',
  load_to_core = 'load_to_core',
  gbdfl_data_update = 'gbdfl_data_update',
}

// типы данных веб сокет

export interface ISocketResponseData {
  notification_type:
    | ENotificationsTypes.credit_responsible_update
    | ENotificationsTypes.credit_to_confirm;
  data: {
    credits_id: number[];
  };
}

export interface ISocketResponseDataMany {
  notification_type:
    | ENotificationsTypes.credit_responsible_update_many
    | ENotificationsTypes.credit_to_confirm_many
    | ENotificationsTypes.load_from_1C;
  data: {
    credits_count: number;
  };
}

export interface ILoadPortfolioSocketData {
  notification_type: ENotificationsTypes.load_to_core;
  data: {
    count_total: number;
    count_success: number;
    count_failure: number;
  };
}

export interface IGbdflUpdatedSocketData {
  notification_type: ENotificationsTypes.gbdfl_data_update;
  data: {
    result: 'SUCCESS' | 'FAILED';
  };
}

export type TypeSocketMapResponse = Map<string, any>;

export type WebSocketResponse =
  | ISocketResponseData
  | ISocketResponseDataMany
  | ILoadPortfolioSocketData
  | IGbdflUpdatedSocketData;
