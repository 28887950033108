import {
  IDashboardResponse,
  IDashboardService,
} from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class DashboardServiceImpl implements IDashboardService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getDashboard(): HttpResponse<IDashboardResponse> {
    return this.strictHttpRequest.get<IDashboardResponse>('/credits/dashboard/main/');
  }
}
