import React, {
  lazy, Suspense, useCallback, useContext,
} from 'react';
import { Route, Routes } from 'react-router-dom';
import { isDevelopment } from '@/utils/environment-util';
import { globalStateContext } from '@/containers/global-state';
import { LoginPageLazy } from '@pages/login';
import { OtpPageLazy } from '@pages/otp';
import { GlobalLoader } from '@/components/global-loader';
import { CHANGE_PASSWORD_PATHNAME } from '@/constants/router-pathnames.contants';
import { LocalLoader } from '@/components/local-loader';
import { ChangePasswordLazy } from '@pages/change-password';
import { ArbitratorAttachmentsPageLazy } from './arbitrator-atachments-page';
import { ForgotPasswordLazy } from './forgot-password';
import PublicRoutes from '../routes/public-routes/public-routes';
import PrivateRoutes from '../routes/private-routes/private-routes';
import ProtectedRoutes from '../routes/protected-routes/protected-routes';
import { ErrorPage } from './page-404';
import { LoginOrganizationSelectPageLazy } from './login-organization-select';
import { RedirectAttachmentsPageLazy } from './redirect-attachments-page';
import { MediatorAttachmentsPageLazy } from './mediator-atachments-page';

export const Pages = (): React.JSX.Element => {
  const { authMetaService } = useContext(globalStateContext);

  const renderDemoPages = useCallback((): JSX.Element => {
    if (isDevelopment()) {
      const Demo = lazy(() => import('../demo'));

      return (
        <Route
          path="/demo/*"
          element={(
            <Suspense fallback={null}>
              <Demo />
            </Suspense>
          )}
        />
      );
    }

    return <></>;
  }, []);

  return (
    <Routes>
      <Route
        path="/login"
        element={<PublicRoutes isAuthenticated={authMetaService.hasAllMetas()} />}
      >
        <Route
          path="/login"
          element={(
            <Suspense fallback={<GlobalLoader visible />}>
              <LoginPageLazy />
            </Suspense>
        )}
        />
      </Route>

      <Route
        path="/otp"
        element={(
          <PublicRoutes isAuthenticated={authMetaService.hasAllMetas()} />
        )}
      >
        <Route
          path="/otp"
          element={(
            <Suspense fallback={<GlobalLoader visible />}>
              <OtpPageLazy />
            </Suspense>
)}
        />
      </Route>
      <Route
        path="/organization-select"
        element={(
          <PublicRoutes isAuthenticated={authMetaService.hasAllMetas()} />
        )}
      >
        <Route
          path="/organization-select"
          element={(
            <Suspense fallback={<GlobalLoader visible />}>
              <LoginOrganizationSelectPageLazy />
            </Suspense>
          )}
        />
      </Route>

      <Route
        path="/forgot-password"
        element={(
          <PublicRoutes isAuthenticated={authMetaService.hasAllMetas()} />
        )}
      >
        <Route
          path="/forgot-password"
          element={(
            <Suspense fallback={<GlobalLoader visible />}>
              <ForgotPasswordLazy />
            </Suspense>
        )}
        />
      </Route>
      <Route
        path={`/${CHANGE_PASSWORD_PATHNAME}`}
        element={(
          <Suspense fallback={<LocalLoader isActive />}>
            <ChangePasswordLazy />
          </Suspense>
        )}
      />
      <Route
        path="/redirect/attachments/:attachment_id"
        element={(
          <Suspense fallback={<GlobalLoader visible />}>
            <RedirectAttachmentsPageLazy />
          </Suspense>
        )}
      />
      <Route
        path="/mediator_attachments/:uuid"
        element={(
          <Suspense fallback={<GlobalLoader visible />}>
            <MediatorAttachmentsPageLazy />
          </Suspense>
        )}
      />
      <Route
        path="/arbitrator_attachments/:uuid"
        element={(
          <Suspense fallback={<GlobalLoader visible />}>
            <ArbitratorAttachmentsPageLazy />
          </Suspense>
        )}
      />
      <Route
        path="/*"
        element={(
          <PrivateRoutes
            isAuthenticated={authMetaService.hasAllMetas()}
          />
        )}
      >
        <Route
          path="*"
          element={<ProtectedRoutes />}
        />
      </Route>
      <Route
        path="*"
        element={<ErrorPage />}
      />
      {renderDemoPages()}
    </Routes>
  );
};
