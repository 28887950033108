import React, { useContext } from 'react';
import { globalStateContext } from '../global-state';
import { LogoutControl } from '../../components/logout-control';
import { logout } from '../../utils/logout';

export const LogoutControlContainer = (): JSX.Element => {
  const { setOrganizationUserInfo, organizationUserInfo } = useContext(globalStateContext);

  const handleLogout = (): void => {
    setOrganizationUserInfo(undefined);
    logout();
  };

  return <LogoutControl organizationUser={organizationUserInfo} onLogout={handleLogout} />;
};
