import React, { FunctionComponent } from 'react';
import { TUserAction, UserActionList } from '@/containers/user-action-list';
import { EOrderFilter } from '@pages/order-v2/model';
import { SUPERVISOR_ACTIONS, SUPERVISOR_CONFIRM_ACTIONS } from '@/containers/user-action-list/model';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';
import { LayoutHead } from './shared';
import './index.scss';

export const Layout: FunctionComponent = (props): JSX.Element => {
  const {
    children,
  } = props;
  const { currentTheme } = useThemeContext();

  return (
    <>
      <LayoutHead />
      <div className={`layout-body layout-body-theme__${currentTheme}`}>
        <div className="container-fluid container-fluid-custom-limiter height-100 px-5">
          {children}
        </div>
      </div>
    </>
  );
};

interface IActionOutsideComponent {
  isActionActive?: boolean;
  onAction?: (action: TUserAction) => void
  onCancel?: () => void;
  filter?: EOrderFilter;
  isAdmin?: boolean;
}

const ActionOutsideComponent = (props: IActionOutsideComponent): JSX.Element => {
  const {
    isActionActive = false,
    onAction,
    onCancel,
    isAdmin,
    filter,
  } = props;
  const { currentTheme } = useThemeContext();

  return (
    <div
      className={classnames(
        'layout-actions-outside', {
          'layout-actions-outside-theme__p2p': currentTheme === 'p2p',
          'layout-actions-outside-theme__dark': currentTheme === 'dark',
          'layout-actions-outside-theme__light': currentTheme === 'light',
          [`layout-actions-outside_${isActionActive}`]: isActionActive,
        },
      )}
    >
      <div className="row no-gutters align-items-center  container-fluid
          container-fluid-custom-limiter px-5"
      >
        {isAdmin && (
          filter !== EOrderFilter?.TO_CONFIRM && (
            <UserActionList
              actionList={SUPERVISOR_ACTIONS}
              onAction={onAction}
              onCancel={onCancel}
            />
          )
        )}
        {isAdmin && (
          filter === EOrderFilter?.TO_CONFIRM && (
            <UserActionList
              actionList={SUPERVISOR_CONFIRM_ACTIONS}
              onAction={onAction}
              onCancel={onCancel}
            />
          )
        )}
      </div>
    </div>
  );
};

ActionOutsideComponent.defaultProps = {
  isActionActive: false,
  onAction: () => {},
  onCancel: () => {},
  filter: '',
  isAdmin: false,
};
