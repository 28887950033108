import React, { FunctionComponent, MouseEvent } from 'react';
import classnames from 'classnames';
import { useThemeContext } from '@/containers/theme-context';
import { Typography } from '../typography';
import './header-link.scss';

interface HeaderLinkProps {
    // eslint-disable-next-line react/require-default-props
    active?: boolean;
    // eslint-disable-next-line react/require-default-props
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    // eslint-disable-next-line react/require-default-props
    children?: string;
}

export const HeaderLink = (props: HeaderLinkProps): React.JSX.Element => {
  const {
    onClick, active = false, children,
  } = props;
  const { currentTheme } = useThemeContext();

  return (
    <button
      onClick={onClick}
      type="button"
      className={classnames('header-link', {
        [`header-link-theme__${currentTheme}`]: currentTheme,
        active,
      })}
    >
      <Typography variant="bodyRegularText">{children}</Typography>
      {active ? (<div className="header-link__active-true" />) : (<></>)}
    </button>
  );
};

export const HeaderLinks: FunctionComponent = (props): JSX.Element => {
  const { children } = props;

  return <div className="page-header-links">{children}</div>;
};
