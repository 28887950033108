import React from 'react';

export const NoDataIcon = (): JSX.Element => (
  <svg
    width={227}
    height={247}
    viewBox="0 0 227 247"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={0.5} clipPath="url(#clip0_0_1)">
      <path
        d="M22.8398 173.516H205.318V237.808C205.318 240.143 204.402 242.383 202.773 244.035C201.143 245.687 198.933 246.615 196.628 246.615H31.5293C29.2247 246.615 27.0145 245.687 25.3849 244.035C23.7553 242.383 22.8398 240.143 22.8398 237.808V173.516Z"
        fill="#191919"
      />
      <path
        d="M23.8404 173.017L114.079 52.8114L204.317 173.017H23.8404Z"
        fill="#191919"
        stroke="#E7EAF3"
      />
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M164.482 49.3379H62.8162C57.5372 49.3379 53.2578 53.6753 53.2578 59.0257V206.985C53.2578 212.336 57.5372 216.673 62.8162 216.673H164.482C169.761 216.673 174.041 212.336 174.041 206.985V59.0257C174.041 53.6753 169.761 49.3379 164.482 49.3379Z"
          fill="#1A1A1A"
        />
        <path
          d="M164.483 50.2188H62.8164C58.0173 50.2188 54.127 54.1618 54.127 59.0259V206.985C54.127 211.85 58.0173 215.793 62.8164 215.793H164.483C169.282 215.793 173.172 211.85 173.172 206.985V59.0259C173.172 54.1618 169.282 50.2188 164.483 50.2188Z"
          stroke="#E7EAF3"
          strokeWidth={2}
        />
      </g>
      <path
        d="M142.756 107.463H74.9785V116.27H142.756V107.463Z"
        fill="#E7EAF3"
      />
      <path
        d="M158.397 120.674H74.9785V124.197H158.397V120.674Z"
        fill="#E7EAF3"
      />
      <path
        d="M158.397 137.406H74.9785V140.929H158.397V137.406Z"
        fill="#E7EAF3"
      />
      <path
        d="M158.397 147.096H74.9785V150.619H158.397V147.096Z"
        fill="#E7EAF3"
      />
      <path
        d="M138.411 156.783H74.9785V160.306H138.411V156.783Z"
        fill="#E7EAF3"
      />
      <path
        d="M224.545 69.4453L211.65 83.8212"
        stroke="#D4D9E8"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <path
        d="M115.78 3.5108L115.349 22.9378"
        stroke="#D4D9E8"
        strokeWidth={4.00072}
        strokeLinecap="round"
      />
      <path
        d="M2.45508 69.7852L15.3493 84.161"
        stroke="#D4D9E8"
        strokeWidth={4}
        strokeLinecap="round"
      />
      <path
        d="M86.255 188.178L86.3592 188.539H86.7354H141.711H142.119L142.201 188.139L145.073 174.074H204.644V237.131H23.2812V174.074H82.1884L86.255 188.178Z"
        fill="#191919"
        stroke="#E7EAF3"
      />
    </g>
    <path
      d="M81.5625 85.6793V85.4432C81.5791 83.902 81.7324 82.6757 82.0224 81.7642C82.3207 80.8527 82.7433 80.1153 83.2901 79.5518C83.837 78.9884 84.4957 78.4747 85.2663 78.0107C85.7635 77.6958 86.2109 77.3436 86.6087 76.9542C87.0064 76.5647 87.3213 76.1173 87.5533 75.6119C87.7853 75.1064 87.9013 74.5471 87.9013 73.9339C87.9013 73.1965 87.7273 72.5585 87.3793 72.0199C87.0312 71.4813 86.5672 71.067 85.9872 70.777C85.4155 70.4787 84.7775 70.3295 84.0732 70.3295C83.4351 70.3295 82.8261 70.4621 82.2461 70.7273C81.6661 70.9924 81.1855 71.4067 80.8043 71.9702C80.4232 72.5253 80.2036 73.2421 80.1456 74.1204H76.3672C76.4252 72.6289 76.8022 71.3694 77.4982 70.342C78.1942 69.3062 79.114 68.5232 80.2575 67.9929C81.4092 67.4626 82.6811 67.1974 84.0732 67.1974C85.5978 67.1974 86.9318 67.4833 88.0753 68.055C89.2188 68.6185 90.1054 69.4098 90.7351 70.429C91.3731 71.4399 91.6921 72.6206 91.6921 73.9712C91.6921 74.8993 91.5471 75.7362 91.2571 76.4819C90.9671 77.2193 90.5528 77.8781 90.0142 78.4581C89.4839 79.0381 88.8459 79.5518 88.1001 79.9993C87.3958 80.4384 86.8241 80.8942 86.3849 81.3665C85.9541 81.8388 85.6392 82.3981 85.4403 83.0444C85.2415 83.6907 85.1338 84.4903 85.1172 85.4432V85.6793H81.5625ZM83.4393 93.2362C82.7598 93.2362 82.1757 92.9959 81.6868 92.5153C81.1979 92.0264 80.9535 91.4381 80.9535 90.7504C80.9535 90.0709 81.1979 89.4909 81.6868 89.0103C82.1757 88.5214 82.7598 88.277 83.4393 88.277C84.1104 88.277 84.6905 88.5214 85.1793 89.0103C85.6765 89.4909 85.9251 90.0709 85.9251 90.7504C85.9251 91.2061 85.8091 91.6245 85.5771 92.0057C85.3533 92.3786 85.055 92.6768 84.6822 92.9006C84.3093 93.1243 83.895 93.2362 83.4393 93.2362Z"
      fill="#72BF44"
    />
    <defs>
      <filter
        id="filter0_d_0_1"
        x={47.127}
        y={45.2188}
        width={133.045}
        height={179.574}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.078 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_0_1"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_0_1"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_0_1">
        <rect width={227} height={246.614} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
