import React, { FC } from 'react';
import { LoadingBarIcon } from '../icons/loading-bar';
import { Typography } from '../typography';

export type LocalLoaderProps = {
  isActive: boolean;
  progress?: ProgressEvent;
  withText?: boolean;
  text?: string;
}

export const LocalLoader: FC<LocalLoaderProps> = ({
  isActive,
  text = 'Идет загрузка...',
  withText,
}): JSX.Element => (
  isActive ? (
    <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      background: 'rgba(0, 0, 0, 0.5)',
      zIndex: 20,
    }}
    >
      <div className="global-loader-spinner">
        <LoadingBarIcon />
      </div>
      {
        withText ? (
          <Typography
            as="div"
            variant="subtitleRegular"
            extraClass="tc-ffffff_87 text-center mt-30"
          >
            {text}
          </Typography>
        ) : <></>
      }
    </div>
  ) : <></>
);
