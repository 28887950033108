export enum EUserAction {
  IDLE = 'IDLE',
  DISTRIBUTE = 'DISTRIBUTE',
  IN_PROGRESS = 'IN_PROGRESS',
  ADD_ORDER_NUMBER = 'ADD_ORDER_NUMBER',
  GET_MERGED_PDF = 'GET_MERGED_PDF',
  TO_CONFIRM = 'TO_CONFIRM',
  TO_REJECT = 'TO_REJECT',
  UNARCHIVE = 'UNARCHIVE',
  EXECUTIVE_INSCRIPTION = 'EXECUTIVE_INSCRIPTION',
}

export interface IOrderIds {
  statusID: string;
  personalDataID: string;
  creditDataID: string;
  documentID: string;
  commentID: string;
  historyID: string;
  regionID: string;
}
