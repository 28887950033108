type NOTIFICATIONS_TYPE = Record<
  string,
  { label: string; message(msg: string): string }
>;

type NOTIFICATIONS_MESSAGE_OBJECT = {
  label: string;
  message: (data: string) => string;
};

// генерирует объект уведомления
/* @param {string} label - Заголовок уведомления
 * @param {requestCallback} message - Функция которая принимает
 * данные с вебсокета (number или string) и интерполирует их с сообщением  */
const generateMessageObject = (
  label: string,
  message: string,
): NOTIFICATIONS_MESSAGE_OBJECT => ({
  label,
  message: (data) => `${message}${data}`,
});

/* Объект которы по ключу (тип ENotificationsTypes)
(path=src/components/socket-notification/model.ts)
возвращает
объект типа NOTIFICATIONS_MESSAGE_OBJECT */
export const NOTIFICATIONS: NOTIFICATIONS_TYPE = {
  credit_responsible_update: generateMessageObject(
    'Назначена новая заявка',
    'Вам назначена заявка(-ки)',
  ),
  credit_to_confirm: {
    label: 'Поступила новая заявка',
    message(msg: string): string | never {
      if (!msg) return 'Поступила заявка(-ки) на подтверждение';
      return `Поступила заявка(-ки) на подтверждение: ${msg}`;
    },
  },
  credit_responsible_update_many: {
    label: 'Назначены новые заявки',
    message(msg: string): string | never {
      if (!msg) return 'Вам назначены заявки.';
      return `Вам назначены заявки. К-во: ${msg}`;
    },
  },
  credit_to_confirm_many: {
    label: 'Поступили новые заявки',
    message(msg: string): string | never {
      if (!msg) return 'Поступили заявки на подтверждение.';
      return `Поступили заявки на подтверждение. К-во: ${msg}`;
    },
  },
  load_from_1C: {
    label: 'Загружено из 1С',
    message(msg: string): string | never {
      if (!msg) return '';
      return `К-во: ${msg}`;
    },
  },
  gbdfl_data_update: {
    label: 'Данные из ГБДФЛ',
    message(msg: string): string {
      return msg;
    },
  },
};

export const SOCKET_MAP = new Map<string, any>();
