import React, { useEffect } from 'react';
import { useThemeContext } from '@/containers/theme-context';

export const AppTheme = (): React.JSX.Element => {
  const { currentTheme } = useThemeContext();

  useEffect(() => {
    const themeClass = `app-theme__${currentTheme}`;
    document.body.classList.add(themeClass);
    return () => document.body.classList.remove(themeClass);
  }, [currentTheme]);

  return <></>;
};
