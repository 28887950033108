import React, { DetailedHTMLProps, LabelHTMLAttributes } from 'react';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';
import { Typography, TypographyProps } from '../typography';

interface LabelProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  extraClass?: string;
  [key: string]: any;
}

export const Label: React.FC<LabelProps & TypographyProps> = (
	props,
): React.JSX.Element => {
	const {
		extraClass, children, className, ...labelProps
	} = props;
	const { isDark, isP2P } = useThemeContext();

	return (
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
		<Typography
			variant="bodyRegular"
			extraClass={
				classnames('display-sub-title-1-entity', {
					'typography__theme-dark': isDark,
					'typography__theme-p2p': isP2P,
				}, [extraClass, className])
			}
			{...labelProps}
		>
			{children}
		</Typography>
	);
};
