import { HttpRequest, HttpResponse } from '@/http-request/model';
import {
  IOrganizationAttachmentData,
  IOrganizationsAttachments,
} from './model';

export class OrganizationsAttachmentsImpl implements IOrganizationsAttachments {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getAttachmentsList(): HttpResponse<IOrganizationAttachmentData[]> {
    return this.strictHttpRequest.get<IOrganizationAttachmentData[]>('/organizations/attachments/');
  }

  postAttachment(formData: FormData): HttpResponse<IOrganizationAttachmentData> {
    return this.strictHttpRequest.post('/organizations/attachments/', formData, { 'Content-type': 'multipart/form-data' });
  }

  deleteAttachment(pk: number): HttpResponse<void> {
    return this.strictHttpRequest.delete(`/organizations/attachments/${pk}/`);
  }
}
