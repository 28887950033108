import { AxiosRequestHeaders } from 'axios';
import { getItemStorage } from '@/helpers/storage-helper';
import { TAuthMeta, TAuthOrgToken } from '@/services/auth-meta-service/model';

export function weakHeader(): AxiosRequestHeaders {
  const authMeta = getItemStorage<TAuthMeta>('authMeta');
  let jwt = null;
  if (authMeta) jwt = authMeta;
  if (jwt && jwt.access_token) {
    return { authorization: `${jwt.token_type} ${jwt.access_token}` };
  }
  return {};
}

export function strictOrgHeader(): AxiosRequestHeaders {
  const authMeta = getItemStorage<TAuthMeta>('authMeta');
  const orgMeta = getItemStorage<TAuthOrgToken>('orgMeta');
  let jwt = null;
  let orgJwt = null;
  let headers: AxiosRequestHeaders = {};
  if (authMeta) jwt = authMeta;
  if (orgMeta) orgJwt = orgMeta;

  const access_token = jwt?.access_token;
  const token_type = jwt?.token_type;

  if (jwt && access_token && orgJwt) {
    headers = {
      authorization: `${token_type} ${access_token}`,
      'org-token': `${orgJwt}`,
    };
  }
  if (jwt && access_token && !orgJwt) {
    headers = {
      authorization: `${token_type} ${access_token}`,
    };
  }

  if (!(jwt || access_token || token_type) && orgJwt) {
    headers = {
      'org-token': `${orgJwt}`,
    };
  }
  return headers;
}
