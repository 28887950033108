import { HandbookSignatoryService, THandbookSignatory } from './model';
import { HttpRequestErrorResponse, HttpRequestOkResponse } from '../../http-request/model';

export class HandbookSignatoryMockService implements HandbookSignatoryService {
  getAll(): Promise<HttpRequestOkResponse<Array<THandbookSignatory>> | HttpRequestErrorResponse> {
    return new Promise<HttpRequestOkResponse<Array<THandbookSignatory>> | HttpRequestErrorResponse>(
      (resolve) => {
        const to = setTimeout(() => {
          clearTimeout(to);
          resolve({
            status: 'OK',
            data: [
              {
                id: 1,
                fullName: 'full name 1',
                position: 'position 1',
              },
              {
                id: 2,
                fullName: 'full name 2',
                position: 'position 2',
              },
              {
                id: 3,
                fullName: 'full name 3',
                position: 'position 3',
              },
              {
                id: 4,
                fullName: 'full name 4',
                position: 'position 4',
              },
              {
                id: 5,
                fullName: 'full name 5',
                position: 'position 5',
              },
            ],
          });
        }, 1000);
      },
    );
  }
}
