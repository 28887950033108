import { TRegion } from '@/services/region-service/model';

export type TPermissionDetail = {
  id: number;
  title: string;
  code: string;
  entity_type: string;
}

export type TOrganizationUserInfo = {
  id: number;
  is_active: boolean;
  organization_id: number;
  status_id: number;
  user: TUserBase;
  role: TUserRole;
  role_id: number;
  regions: Array<TRegion>;
  created_at?: string;
  updated_at?: string;
}

export enum EUserRole {
  SUPERVISOR = 'supervisor',
  COLLECTOR = 'collector',
  BAILIFF = 'bailiff',
  NOTARY = 'notary',
  SECURITY = 'security',
  LAWYER = 'lawyer',
  ADMIN = 'admin',
  SPECTATOR = 'spectator',
  MEDIATOR = 'mediator',
  ARBITRATION = 'arbitration',
  JUDGE = 'judge',
  DEFAULT = '',
}

export type TUserRole = {
  id: number;
  title: string;
  is_admin: boolean;
  slug: EUserRole;
  permissions?: Array<TPermissionDetail>;
}

export type TUserBase = {
  email: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  iin: string;
  id: number;
  full_name: string;
  license_number: string;
  license_date: Date;
  office_address: string;
  contacts: Array<string>;
  password: string;
}

export const defaultOrganizationUserInfo: TOrganizationUserInfo = {
  id: null,
  is_active: false,
  organization_id: null,
  status_id: null,
  created_at: '',
  updated_at: '',
  user: {
    email: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    iin: '',
    id: 0,
    full_name: '',
    license_number: '',
    license_date: undefined,
    office_address: '',
    contacts: [''],
    password: '',
  },
  role: {
    id: null,
    slug: EUserRole.DEFAULT,
    title: '',
    is_admin: false,
    permissions: [
      {
        title: '',
        code: '',
        entity_type: '',
        id: null,
      },
    ],
  },
  role_id: null,
  regions: [{
    id: 0,
    name: 'Регион',
    slug: ['Регион'],
  }],
};
