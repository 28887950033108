import {
  Dispatch, SetStateAction, useCallback, useMemo, useState,
} from 'react';
import { useIsComponentMounted } from './useIsComponentMounted';

/**
 * Function to create a state if the component is mounted.
 *
 * @param {T} initialValue - the initial value for the state
 * @return {[T, Dispatch<SetStateAction<T>>]} the state and its setter function
 */
export const useStateIfMounted = <T>(initialValue?: T): [T, Dispatch<SetStateAction<T>>] => {
  const isComponentMounted = useIsComponentMounted();
  const memoizedInitialValue = useMemo(() => initialValue, [JSON.stringify(initialValue)]);

  return isComponentMounted
    ? useState<T>(memoizedInitialValue)
    : [memoizedInitialValue, useCallback(() => memoizedInitialValue, [memoizedInitialValue])];
};
