import { HandbookStatusService, THandbookStatus } from './model';
import { HttpRequestOkResponse, HttpRequestErrorResponse } from '../../http-request/model';

export class HandbookStatusMockService implements HandbookStatusService {
  getAll(): Promise<HttpRequestOkResponse<Array<THandbookStatus>> | HttpRequestErrorResponse> {
    return new Promise<HttpRequestOkResponse<Array<THandbookStatus>> | HttpRequestErrorResponse>(
      (resolve) => {
        const to = setTimeout(() => {
          clearTimeout(to);
          resolve({
            status: 'OK',
            data: [
              {
                id: 1,
                borrower: 'borrower 1',
                debtor: 'debtor 1',
                order: 'order 1',
                user: 'user 1',
              },
              {
                id: 2,
                borrower: 'borrower 2',
                debtor: 'debtor 2',
                order: 'order 2',
                user: 'user 2',
              },
              {
                id: 3,
                borrower: 'borrower 3',
                debtor: 'debtor 3',
                order: 'order 3',
                user: 'user 3',
              },
              {
                id: 4,
                borrower: 'borrower 4',
                debtor: 'debtor 4',
                order: 'order 4',
                user: 'user 4',
              },
              {
                id: 5,
                borrower: 'borrower 5',
                debtor: 'debtor 5',
                order: 'order 5',
                user: 'user 5',
              },
            ],
          });
        }, 1000);
      },
    );
  }
}
