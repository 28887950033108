import { tryResolveJSON } from '../utils/json-util';

// const DEFAULT_STORAGE_TYPE: Storage = sessionStorage;
const DEFAULT_STORAGE_TYPE: Storage = localStorage;
function storageCheck(storageValue: Storage): Storage {
  return storageValue || DEFAULT_STORAGE_TYPE;
}
export const getItemStorage = <T>(name: string, storageType?: Storage): T | any => {
  const storage = storageCheck(storageType);
  return tryResolveJSON<T>(storage.getItem(name));
};
export const setItemStorage = <T>(name: string, value: T, storageType?: Storage): void => {
  if (!name) return;
  const storage = storageCheck(storageType);
  storage.setItem(name, JSON.stringify(value));
};
export const clearItemStorage = (storageType?: Storage): void => {
  const storage = storageCheck(storageType);
  storage.clear();
};
export const removeItemStorage = (name: string, storageType?: Storage): void => {
  if (!name) return;
  const storage = storageCheck(storageType);
  storage.removeItem(name);
};
export const hasItemStorage = (name: string, storageType?: Storage): boolean => {
  const storage = storageCheck(storageType);
  return Boolean(storage.getItem(name));
};
