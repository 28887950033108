import {
  EIntegrationType,
  IActivityRuleExtraListParams,
  IActivityRuleWithExtraBody,
  IActivityRuleWithExtraListResponse,
  IAdministrationService,
  INotificationTemplateBody,
  INotificationTemplateResponse,
  INotificationTemplatesResponse,
} from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class AdministrationServiceImpl implements IAdministrationService {
  constructor(private readonly strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  createActivityRuleWithExtra(
    body: IActivityRuleWithExtraBody,
  ): HttpResponse<IActivityRuleWithExtraListResponse> {
    return this.strictHttpRequest.post('/activity/rule-with-extra/', body);
  }

  getActivityRuleWithExtraList(
    params: IActivityRuleExtraListParams,
  ): HttpResponse<IActivityRuleWithExtraListResponse[]> {
    const queryParams = new URLSearchParams(params).toString();
    return this.strictHttpRequest.get(
      `/activity/rule-with-extra/all/?${queryParams}`,
    );
  }

  getNotificationTemplate(
    queryString: EIntegrationType,
  ): HttpResponse<INotificationTemplatesResponse[]> {
    return this.strictHttpRequest.get(
      `/notifications/templates/?integration=${queryString}`,
    );
  }

  putActivityRuleWithExtra(
    id: string,
    body: IActivityRuleWithExtraBody,
  ): HttpResponse<IActivityRuleWithExtraListResponse> {
    return this.strictHttpRequest.put(`/activity/rule-with-extra/${id}/`, body);
  }

  getActivityRuleById(
    id: string,
  ): HttpResponse<IActivityRuleWithExtraListResponse> {
    return this.strictHttpRequest.get<IActivityRuleWithExtraListResponse>(
      `/activity/rule-with-extra/${id}/`,
    );
  }

  postNotificationTemplate(
    body: INotificationTemplateBody,
  ): HttpResponse<INotificationTemplateResponse> {
    return this.strictHttpRequest.post('/notifications/templates/', body);
  }
}
