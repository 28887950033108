import React, {
  createContext, FunctionComponent, useEffect, useState,
} from 'react';
import { IGlobalStateContext } from './model';
import { TOrganizationUserInfo } from '../../models/permission';
import { TAuthOrgToken } from '../../services/auth-meta-service/model';
import { AuthMetaServiceImpl } from '../../services/auth-meta-service/auth-meta-service-impl';
import { setUserPermissions } from '../../utils/setUserPermissions';
import { getItemStorage, setItemStorage } from '../../helpers/storage-helper';

// Глобальный контекст для хранения общего для приложения данных.
// В том числе User

const defaultGlobalStateContext: IGlobalStateContext = {
  setOrgToken: () => {},
  authMetaService: null,
  isLightMode: false,
  isAdmin: false,
  setLightMode: () => {},
  setOrganizationUserInfo: () => {},
};

export const globalStateContext = createContext(defaultGlobalStateContext);

export const GlobalStateProvider: FunctionComponent = (props): JSX.Element => {
  const { children } = props;
  const [isLightMode, setLightMode] = useState(false);
  const [orgToken, setOrgToken] = useState<TAuthOrgToken>();
  const authMetaService = new AuthMetaServiceImpl();
  const [
    organizationUserInfo, setOrganizationUserInfo,
  ] = useState<TOrganizationUserInfo | undefined>();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (organizationUserInfo?.id) {
      authMetaService.setUser(organizationUserInfo);
      setIsAdmin(organizationUserInfo?.role?.is_admin);
      setUserPermissions();
    }
    if (!organizationUserInfo?.id && getItemStorage('organizationUserInfo')) {
      const orgUser = authMetaService.getUser();
      setOrganizationUserInfo(orgUser);
      setIsAdmin(orgUser?.role?.is_admin);
      setUserPermissions();
    }
  }, [organizationUserInfo?.id]);

  useEffect(() => {
    if (orgToken?.token) {
      setItemStorage('user', orgToken?.token);
    }
  }, [orgToken]);

  return (
    <globalStateContext.Provider
      value={{
        setOrgToken,
        orgToken,
        organizationUserInfo,
        setOrganizationUserInfo,
        setLightMode,
        isLightMode,
        authMetaService,
        isAdmin,
      }}
    >
      {children}
    </globalStateContext.Provider>
  );
};
