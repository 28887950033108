import React from 'react';

export const LoadingBarIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 12.25C4.1125 12.25 1.75 9.8875 1.75 7C1.75 4.375 3.7625 2.1 6.3 1.75L6.0375 0C2.625 0.525 0 3.5 0 7C0 10.85 3.15 14 7 14C10.5 14 13.475 11.4625 13.9125 7.9625L12.1625 7.7C11.9 10.2375 9.625 12.25 7 12.25Z"
    />
  </svg>
);
