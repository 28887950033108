import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { v4 } from 'uuid';
import { globalStateContext } from '@/containers/global-state';
import { useSocket } from '@/hooks/useSocket';
import { isJSON } from '@/utils/json-util';
import { socketContext } from '@/containers/socket-container';
import { CustomToastContent } from '../custom-toasts/custom-toast-content';
import { NOTIFICATIONS, SOCKET_MAP } from './constant';
import { ENotificationsTypes, ILoadPortfolioSocketData, WebSocketResponse } from './model';
import { LoadingIndicator } from '../loading-indicator';

// const key = Date.now().toString();
/* Функция принимает аргументом данные веб-сокета
 * проверяет на наличие ключа в объекте данных и в
 * зависимости от этого переводит в string */
const getDataFromSocketResponse = (
  data: WebSocketResponse['data'],
): string | never => {
  if ('credits_count' in data) {
    return data?.credits_count?.toString();
  }
  if ('credits_id' in data) {
    return data?.credits_id?.join(', ');
  }
  if (
    'result' in data
    && ENotificationsTypes.gbdfl_data_update
    && data?.result === 'SUCCESS'
  ) {
    return 'Данные из ГБДФЛ Успешно обновились';
  }
  if (
    'result' in data
    && ENotificationsTypes.gbdfl_data_update
    && data?.result === 'FAILED'
  ) {
    return 'Данные из ГБДФЛ не удалось обновить';
  }
  return null;
};

// Компонент отображения всплывающих уведомлений веб сокета
export const SocketNotification = (): JSX.Element => {
  const { organizationUserInfo, authMetaService } = useContext(globalStateContext);
  const { setSocketData } = useContext(socketContext);
  const [wsUrl, setWsUrl] = useState<string>('');
  const toastIdRef = useRef(null);
  const {
    addToast, updateToast, removeToast, toastStack,
  } = useToasts();

  // Если авторизован, то по ID пользователя подключиться к веб-сокету
  useEffect(() => {
    if (authMetaService.hasAllMetas() && organizationUserInfo?.id) {
      setWsUrl(`/sub/${organizationUserInfo?.id}`);
    }
  }, [organizationUserInfo?.id]);

  const setSocketDataResponse = (data: WebSocketResponse): void => {
    if (SOCKET_MAP.has(data?.notification_type)) {
      SOCKET_MAP.delete(data?.notification_type);
    }
    SOCKET_MAP.set(data?.notification_type, data?.data);
    setSocketData(data);
  };

  // Кастомный хук взаимодействий с веб сокетом
  const { connect, lastSocketData, readyState } = useSocket({
    withReconnect: true,
    reconnectCount: 2,
  });

  useEffect(() => {
    if (wsUrl && window.WebSocket) {
      connect(wsUrl);
    }
  }, [wsUrl]);

  const handleData = (data: ILoadPortfolioSocketData['data']): void => {
    const loaded = (data?.count_success ?? 0) + (data?.count_failure ?? 0);
    addToast(
      <LoadingIndicator
        title="Загрузка портфеля:"
        total={data?.count_total}
        loaded={loaded}
      />,
      {
        appearance: 'info',
        autoDismiss: false,
        onDismiss: (id) => removeToast(id),
        id: toastIdRef.current,
      },
    );
  };

  // Обработка сообщений с веб-сокета, валидация JSON, если прошло
  // то распарсить и записать в глобальный контекст,
  /* проверить тип notification_type и в зависимости от типа вывести сообщение */
  useEffect(() => {
    if (lastSocketData?.data && isJSON(lastSocketData?.data)) {
      const response: WebSocketResponse = JSON.parse(lastSocketData?.data);

      setSocketDataResponse(response);

      if (response.notification_type === ENotificationsTypes.load_to_core) {
        const { data } = response;
        if (
          toastStack?.length > 0
          && toastStack.some((toast) => toast.id === toastIdRef.current)
        ) {
          const loaded = (data?.count_success ?? 0) + (data?.count_failure ?? 0);
          updateToast(toastIdRef.current, {
            content: (
              <LoadingIndicator
                title="Загрузка портфеля:"
                total={data?.count_total}
                loaded={loaded}
              />
            ),
          });
        } else {
          toastIdRef.current = v4();
          handleData(data);
        }
      } else {
        const type = response.notification_type;
        const data = getDataFromSocketResponse(response?.data);

        if (data && type) {
          addToast(
            <CustomToastContent
              label={NOTIFICATIONS[type]?.label}
              message={NOTIFICATIONS[type]?.message(data)}
            />,
            {
              appearance: 'success',
              autoDismiss: true,
              autoDismissTimeout: 7000,
            },
          );
        }
      }
    }
  }, [lastSocketData, readyState]);

  return <></>;
};
