import React, { ChangeEvent } from 'react';
import { v4 } from 'uuid';

import './rounded-checkbox.scss';
import { Tooltip } from '../tooltip';
import { Typography } from '../typography';

interface IRoundedCheckboxProps {
  checked: boolean;
  disabled?: boolean;
  id?: string;

  onChange?(event: ChangeEvent<HTMLInputElement>): void;

  onClick?(event: React.MouseEvent<HTMLLabelElement>): void;
}

const RoundedCheckbox: React.FC<IRoundedCheckboxProps> = (props) => {
  const {
    onChange,
    onClick,
    id = v4(),
    checked,
    disabled = false,
  } = props;

  return (
    <label
      role="presentation"
      onClick={onClick}
      className="rounded-checkbox"
      htmlFor={id}
    >
      <Tooltip
        placement="auto-end"
        tip={(
          <Typography
            variant="captionBold"
            extraClass="rounded-checkbox__tooltip"
          >
            {checked ? 'Отметить как прочитанное' : ''}
          </Typography>
        )}
      >
        <input
          onChange={onChange}
          className="rounded-checkbox__input"
          id={id}
          type="checkbox"
          defaultChecked={checked}
          disabled={disabled}
        />
        <span />
      </Tooltip>
    </label>
  );
};

export default RoundedCheckbox;
