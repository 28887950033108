import { IPrintForm, IPrintFormResponse, IRenderPrintFormParams } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class PrintFormServiceImpl implements IPrintForm {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getPrintFormList(): HttpResponse<IPrintFormResponse[]> {
    return this.strictHttpRequest.get('/print_form/');
  }

  createPrintForm(body: FormData): HttpResponse<IPrintFormResponse> {
    return this.strictHttpRequest.post('/print_form/', body, { ContentType: 'multipart/form-data' });
  }

  renderPrintForm(params: IRenderPrintFormParams): HttpResponse<IPrintFormResponse> {
    const {
      creditID, printFormID, body, extension,
    } = params;

    const newURLSearchParams = new URLSearchParams();
    if (extension) {
      newURLSearchParams.append('extension', extension);
    }

    return this.strictHttpRequest.post(`/credits/${creditID}/print_form/${printFormID}/${`?${newURLSearchParams.toString()}`}`,
      body);
  }
}
