import React, { MouseEvent } from 'react';
import { useThemeContext } from '@/containers/theme-context';
import { LogoutIcon } from '../icons/logout';

export interface LogoutButtonProps {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const LogoutButton = (props: LogoutButtonProps): JSX.Element => {
  const { onClick } = props;
  const { isDark, isP2P } = useThemeContext();

  return (
    <button
      onClick={onClick}
      type="button"
      style={{
        width: 24,
        height: 24,
        color: isDark ? 'rgba(255, 255, 255, 0.5)' : isP2P ? '#1A1A1A' : '#000',
        display: 'block',
      }}
    >
      <LogoutIcon />
    </button>
  );
};
