import React, { ReactNode } from 'react';
import './index.scss';

export interface NotFoundProps {
  title?: string;
  description?: ReactNode;
  icon?: JSX.Element;
}
export const NotFound = (props:NotFoundProps): JSX.Element => {
  const { title, description, icon } = props;
  return (
    <div className="not-found">
      <div className="not-found__container">
        <div className="not-found__icon mb-2">
          {icon}
        </div>
        <p className="not-found__title">{title}</p>
        <div className="not-found__description">{description}</div>
      </div>
    </div>
  );
};
