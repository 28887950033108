import React from 'react';
import { TOrganizationUserInfo } from '../../models/permission';
import { Typography } from '../typography';

export interface OrganizationShortInfoProps {
  organizationUser: TOrganizationUserInfo;
}

export const OrganizationUserShortInfo = (props: OrganizationShortInfoProps): JSX.Element => {
  const { organizationUser } = props;

  return (
    <div className="container-fluid p-0">
      <div className="row mb-2">
        <div style={{ color: '#4F9D3A' }} className="col-12">
          <Typography variant="subBodyRegular">
            {`${organizationUser?.user?.first_name} ${organizationUser?.user?.last_name}`}
          </Typography>
        </div>
      </div>
      <div className="row">
        <div className="col-12 tc-b7bbbd">
          <Typography variant="captionRegular">{`${organizationUser?.role?.title}`}</Typography>
        </div>
      </div>
    </div>
  );
};
