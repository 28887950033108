import {
  IMediatorAttachment, IMediatorAttachmentService,
} from '@/services/mediator-attachments/model';
import { HttpRequest, HttpResponse } from '@/http-request/model';

export class MediatorAttachmentsImpl implements IMediatorAttachmentService {
  private http: HttpRequest;

  constructor(http: HttpRequest) {
    this.http = http;
  }

  getMediatorAttachments(uuid: string): HttpResponse<IMediatorAttachment[]> {
    return this.http.get(`/credits/mediator_attachments/${uuid}/`);
  }

  sendMediatorSms(creditID: string): HttpResponse<any> {
    return this.http.get(`/credits/${creditID}/mediator_attachments/send-sms/`);
  }
}
