import React, { ComponentProps, FC, PropsWithChildren } from 'react';

const combineComponents = (...components: FC<PropsWithChildren<any>>[]) : FC<PropsWithChildren<any>> => components
  .reduceRight(
    (AccumulatedComponents, CurrentComponent) => function ContextMultiProvider(
      { children }: ComponentProps<FC<PropsWithChildren<any>>>,
    ): React.JSX.Element {
      return (
        <AccumulatedComponents>
          <CurrentComponent>{children}</CurrentComponent>
        </AccumulatedComponents>
      );
    },
    ({ children }) => <>{children}</>,
  );

export default combineComponents;
