import { IAuthMetaService } from '../auth-meta-service/model';
import { IVerifyForgotPasswordResponse, OTPService } from './model';
import instance from '../../api/http-axios';
import { HttpResponse } from '../../http-request/model';

export class OtpServiceImpl implements OTPService {
  private readonly authMeta: IAuthMetaService;

  constructor(authMeta: IAuthMetaService) {
    this.authMeta = authMeta;
  }

  otpVerified(code: string, token: string): HttpResponse<void> {
    return instance.post(
      '/auth/access-token/',
      {
        code,
        token,
      },
    )
      .then((response) => {
        if (response.status !== 200) {
          return {
            status: 'ERROR',
            error: response?.data,
          };
        }
        this.authMeta.setAuthMeta(response.data);
        return {
          status: 'OK',
          data: response?.data,
        };
      });
  }

  forgotPasswordOtp(token: string, code: string): HttpResponse<IVerifyForgotPasswordResponse> {
    return instance.post(`${process.env.BASE_URL}/auth/verify-forgot-password/`, {
      token,
      code,
    })
      .then((response) => {
        if (response.status !== 200) {
          return {
            status: 'ERROR',
            error: response?.data,
          };
        }

        return {
          status: 'OK',
          data: response?.data,
        };
      });
  }
}
