import React, { FC } from 'react';
import {
  Navigate, Outlet, useLocation,
} from 'react-router-dom';

const PublicRoutes: FC<{isAuthenticated: boolean}> = ({ isAuthenticated }) => {
  const location = useLocation();
  return isAuthenticated ? <Navigate to="/dashboard" state={{ from: location }} replace /> : <Outlet />;
};

export default PublicRoutes;
