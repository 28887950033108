import { HttpRequest, HttpResponse } from '@/http-request/model';
import instance from '@/api/http-axios';
import { AuthService, ICurEmailSenderResponse } from './model';
import { IAuthMetaService, TAuthOrgToken } from '../auth-meta-service/model';

export class AuthServiceImpl implements AuthService {
    private readonly authMeta: IAuthMetaService;

    private readonly http: HttpRequest;

    constructor(authMeta: IAuthMetaService, http: HttpRequest) {
      this.authMeta = authMeta;
      this.http = http;
    }

    auth(login: string, password: string): HttpResponse<TAuthOrgToken> {
      const requestParams = new FormData();
      requestParams.set('username', login);
      requestParams.set('password', password);

      return instance.post(
        '/auth/sign-in/',
        requestParams,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      ).then((response) => {
        this.authMeta.setUser(response.data);
        if (response.status === 200) {
          return {
            ...response,
            status: 'OK',
            data: response.data,
            statusCode: response.status,
          };
        }
        return response.data;
      })
        .catch((error) => ({
          ...error,
          status: 'ERROR',
          error,
          statusCode: error.status,
        }));
    }

    forgotPassword(email: string): HttpResponse<TAuthOrgToken> {
      return instance.post('/auth/forgot-password/', {
        email,
      })
        .then((response) => {
          if (response.status !== 200) {
            return {
              ...response,
              status: 'ERROR',
              error: response?.data,
              statusCode: response.status,
            };
          }

          return {
            ...response,
            status: 'OK',
            data: response.data,
            statusCode: response.status,
          };
        });
    }

    // eslint-disable-next-line max-len
    completeForgotPassword(link: string, new_password: string, confirm_password: string): HttpResponse<void> {
      return instance.post(`/auth/complete-forgot-password/?token=${link}`, {
        new_password,
        confirm_password,
      })
        .then((response) => {
          if (response.status !== 200) {
            return {
              ...response,
              status: 'ERROR',
              error: response?.data,
              statusCode: response.status,
            };
          }

          return {
            ...response,
            status: 'OK',
            data: response.data,
            statusCode: response.status,
          };
        });
    }

    blockUser(email: string): HttpResponse<void> {
      return this.http.post('/auth/block-user/', { email });
    }

    changePassword(
      token: string,
      new_password: string,
      confirm_password: string,
    ): HttpResponse<void> {
      return instance.post(
        '/auth/change-password/',
        {
          new_password,
          confirm_password,
        },
        {
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/json',
            authorization: `Bearer ${token}`,
          },
        },
      )
        .then(
          (response) => {
            if (response.status !== 200) {
              return {
                ...response,
                status: 'ERROR',
                error: response?.data?.error,
                statusCode: response.status,
              };
            }

            return {
              ...response,
              status: 'OK',
              data: response.data,
              statusCode: response.status,
            };
          },
          (reject) => ({
            ...reject,
            status: 'ERROR',
            error: reject,
            statusCode: reject.status,
          }),
        );
    }

    getCurrentMailSender(): HttpResponse<ICurEmailSenderResponse> {
      return this.http.get('/auth/get-current-mail-sender/');
    }

    setMailSender(email: string): HttpResponse<void> {
      return this.http.post('/auth/set-mail-sender/', { email });
    }

    unblockUser(email: string): HttpResponse<void> {
      return this.http.post('/auth/unblock-user/', { email });
    }
}
