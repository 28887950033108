import React from 'react';
import { RenderIfProps } from './model';

export const RenderIf = (props: RenderIfProps): JSX.Element => {
  const { clause, children } = props;

  if (clause) {
    return <>{children}</>;
  }

  return <></>;
};
