import React from 'react';
import { Typography } from '../typography';

interface TitleProps {
  extraClass?: string;
}
export const Title: React.FC<TitleProps> = (
  props,
): JSX.Element => {
  const { extraClass, children } = props;
  return (
    <Typography variant="subtitleBold" extraClass={`display-title-1-entity ${extraClass}`}>
      {children}
    </Typography>
  );
};

Title.defaultProps = {
  extraClass: '',
};
