import { OrderCommentService, TComment, TCommentResponse } from './model';
import { HttpRequest, HttpResponse } from '../../http-request/model';

export class OrderCommentsServiceImpl implements OrderCommentService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  add(orderID: string, userID: number, text: string): HttpResponse<TComment> {
    return this.strictHttpRequest.post('/comments/', {
      text,
      credit_id: orderID,
    });
  }

  deleteByID(commentID: number): HttpResponse<TComment> {
    return this.strictHttpRequest.delete(`/comments/${commentID}/`);
  }

  getAll(orderID: string): HttpResponse<Array<TComment>> {
    return this.strictHttpRequest.get<Array<TComment>>(`/credits/${orderID}/comments/`);
  }

  updateByID(commentID: number, userID: number, text: string): HttpResponse<TComment> {
    return this.strictHttpRequest.patch<TComment, any>(
      `/comments/${commentID}/`,
      { text },
    );
  }

  addMany(orderIdList: string[], text: string): HttpResponse<TCommentResponse> {
    return this.strictHttpRequest.post('/comments/many/', {
      text,
      credits_id_list: orderIdList,
    });
  }
}
