import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { getItemStorage, setItemStorage } from '@/helpers/storage-helper';

// Определение типа для фич
export const FEATURE_ORG_THEME = 'ORG_THEME';
type org_theme = typeof FEATURE_ORG_THEME;

export type FeatureKey = org_theme;
export type FeatureValue = 'FD' | 'P2P';

const FEATURE_STORAGE = 'features';

export type Features = Record<FeatureKey, FeatureValue>;

// Определение контекста для хранения состояния фич
const FeatureContext = createContext<{
  features: Features;
  initializeFeature:(organizationId: number) => Features;
  resetFeature:() => void;
  updateFeature:(feature: FeatureKey, value: FeatureValue) => void;
    } | undefined>(undefined);

// Провайдер контекста
export const FeatureProvider: React.FC = ({ children }) => {
  const [features, setFeatures] = useState<Features>({} as Features);

  const updateFeature = (feature: FeatureKey, value: FeatureValue): void => {
    setItemStorage(FEATURE_STORAGE, { ...features, [feature]: value });
    setFeatures((prevFeatures) => ({ ...prevFeatures, [feature]: value }));
  };

  const initializeFeature = (organizationId: number): Features => {
    if (!organizationId) return null;

    const feature: Features = {
      [FEATURE_ORG_THEME]: organizationId === 1 ? 'P2P' : 'FD',
    };

    if (!feature) return null;

    updateFeature(FEATURE_ORG_THEME, feature[FEATURE_ORG_THEME]);

    return feature;
  };

  const resetFeature = (): void => {
    setItemStorage(FEATURE_STORAGE, {});
    setFeatures(() => ({} as Features));
  };

  useEffect(() => {
    const featuresFromStorage = getItemStorage(FEATURE_STORAGE) || {};
    if (features && Object.keys(features)?.length <= 0 && Object.keys(featuresFromStorage).length) {
      setFeatures(featuresFromStorage);
    }
  }, []);

  return (
    <FeatureContext.Provider value={{
      features,
      updateFeature,
      initializeFeature,
      resetFeature,
    }}
    >
      {children}
    </FeatureContext.Provider>
  );
};

export const useFeatureContext = (): {
  features: Features;
  updateFeature: (feature: FeatureKey, value: FeatureValue) => void
} => {
  const context = useContext(FeatureContext);
  if (!context) {
    throw new Error('useFeatureContext must be used within a FeatureProvider');
  }
  return context;
};

// Хук для использования фич
export const useFeatureToggle = (featureKey: FeatureKey): FeatureValue => {
  const context = useFeatureContext();
  if (!context) {
    throw new Error('useFeatureToggle must be used within a FeatureProvider');
  }
  return context.features[featureKey];
};

export const useFeatureUpdate = (): ((feature: FeatureKey, value: FeatureValue) => void) => {
  const context = useFeatureContext();
  if (!context) {
    throw new Error('useFeatureUpdate must be used within a FeatureProvider');
  }
  return context.updateFeature;
};

export const useFutureInitialize = (): (organizationId: number) => Features => {
  const context = useContext(FeatureContext);
  if (!context) {
    throw new Error('useFeatureContext must be used within a FeatureProvider');
  }
  return context.initializeFeature;
};
