import { AxiosPromise } from 'axios';
import { HttpRequest, HttpResponse } from '@/http-request/model';
import { strictOrgHeader } from '@/api/http-header';
import { TOrder } from '@pages/order-v2/model';
import {
  IArchiveManyBody,
  IArchiveManyResponse,
  IChangeExecutorManyBody,
  IRejectManyBody,
  IUnarchiveManyBody,
  OrderService,
  TOrderListSchema,
  TOrderServiceQuery,
  TUnarchiveResponseData,
} from './model';
import { OrderGetAllQueryBuilder } from './order-get-all-query-builder';
import instance from '../../api/http-axios';

export class OrderServiceImpl implements OrderService {
  private readonly strictHttpRequest: HttpRequest;

  private buildGetAllParams(query: TOrderServiceQuery): string {
    return new OrderGetAllQueryBuilder(query).build();
  }

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getAll(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/${this.buildGetAllParams(query)}`);
  }

  getOrderById(id: number): HttpResponse<TOrder> {
    return this.strictHttpRequest.get(`/credits/${id}/`);
  }

  getAllArchive(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/archive/${this.buildGetAllParams(query)}`);
  }

  getAllDistributed(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/distributed/${this.buildGetAllParams(query)}`);
  }

  getAllDone(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/done/${this.buildGetAllParams(query)}`);
  }

  getAllInProgress(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/in-work/${this.buildGetAllParams(query)}`);
  }

  getAllNotDistributed(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/not-distributed/${this.buildGetAllParams(query)}`);
  }

  getAllToConfirm(query: TOrderServiceQuery): HttpResponse<TOrderListSchema> {
    return this.strictHttpRequest.get(`/credits/to_confirm/${this.buildGetAllParams(query)}`);
  }

  // Проверка изменить статус несколько или только одну
  // eslint-disable-next-line max-len
  changeStatus(orderID: string, statusID: string, responsibleID?: number): HttpResponse<void> {
    const status_id = statusID === '' ? null : statusID;
    const responsible_id = !responsibleID ? null : responsibleID;
    const URLParam = `/credits/${orderID}/status/`;

    const body = {
      status_id,
      responsible_id,
    };

    return this.strictHttpRequest.patch<void, {
      status_id: string,
      responsible_id: number | null
    }>(
      URLParam,
      body,
    );
  }

  // eslint-disable-next-line max-len
  changeManyStatus(orderID: string[], statusID: string, responsibleID?: number): HttpResponse<void> {
    const status_id = statusID === '' ? null : statusID;
    const responsible_id = !responsibleID ? null : responsibleID;
    const URLParam = '/credits/status/';

    const body = {
      status_id,
      responsible_id,
      credits_id_list: orderID,
    };

    return this.strictHttpRequest.patch<void, {
      status_id: string,
      responsible_id: number | null,
      credits_id_list?: string[] }>(
        URLParam,
        body,
      );
  }

  addCessionNumber(formData: FormData): HttpResponse<void> {
    return this.strictHttpRequest.post<void,
      FormData>(
        '/credits/cession-number/',
        formData,
        { ContentType: 'multipart/form-data' },
      );
  }

  confirmByID(orderID: string): HttpResponse<void> {
    return this.strictHttpRequest.patch<void, string>(`/credits/${orderID}/archive/`);
  }

  confirmMany(orderIDs: string[]): HttpResponse<void> {
    return this.strictHttpRequest.put<void, {
      credits_id_list: string[]
    }>('/credits/archive-many/', {
      credits_id_list: orderIDs,
    });
  }

  getMergedPDF(orderIDs: string[]): AxiosPromise<Blob> {
    return instance.post('/credits/bulk/merged_pdf/', {
      credits_ids: orderIDs,
    }, {
      headers: strictOrgHeader(),
      responseType: 'arraybuffer',
    });
  }

  changeExecutor(executor: string, orderIDList: Array<string>): HttpResponse<void> {
    return this.strictHttpRequest.put<void, {
      credits_id_list: Array<string>, responsible_id: string
    }>(
      '/credits/distribution/',
      {
        credits_id_list: orderIDList,
        responsible_id: executor,
      },
    );
  }

  creditReject(executor: string, orderIDList: Array<string>): HttpResponse<void> {
    return this.strictHttpRequest.put<void, {
      credits_id_list: Array<string>,
      responsible_id: string
    }>(
      '/credits/reject/',
      {
        credits_id_list: orderIDList,
        responsible_id: executor,
      },
    );
  }

  // Проверка вернуть из архива несколько или только одну
  unArchiveByID(orderID: string, statusID: string, responsibleID: string): HttpResponse<
    TUnarchiveResponseData> {
    const status_id = statusID === '' ? null : statusID;
    const responsible_id = responsibleID === '' ? null : responsibleID;
    const URLParam = `/credits/${orderID}/unarchive/`;

    const body = {
      status_id,
      responsible_id,
    };

    return this.strictHttpRequest.patch<TUnarchiveResponseData, {
        status_id: string,
        responsible_id: string | null}>(
          URLParam,
          body,
        );
  }

  unArchiveMany(orderID: string[], statusID: string, responsibleID: string): HttpResponse<
    TUnarchiveResponseData> {
    const status_id = statusID === '' ? null : statusID;
    const responsible_id = responsibleID === '' ? null : responsibleID;
    const URLParam = '/credits/unarchive/';

    const body = {
      status_id,
      responsible_id,
      credits_id_list: orderID,
    };

    return this.strictHttpRequest.patch<TUnarchiveResponseData, {
      status_id: string,
      responsible_id: string | null,
      credits_id_list?: string[] }>(
        URLParam,
        body,
      );
  }

  putOrderRegion(orderID: string, body: { name: string; slug: string[] }): HttpResponse<void> {
    return this.strictHttpRequest.put<void, {
      name: string,
      slug: string[]
    }>(
      `/catalogs/regions/${orderID}`,
      body,
    );
  }

  archiveMany(body: IArchiveManyBody): HttpResponse<IArchiveManyResponse> {
    return this.strictHttpRequest.put('/credits/archive-many/', body);
  }

  unarchiveAll(body: IUnarchiveManyBody): HttpResponse<void> {
    return this.strictHttpRequest.put('/credits/unarchive/', body);
  }

  rejectMany(body: IRejectManyBody): HttpResponse<any> {
    return this.strictHttpRequest.put('/credits/reject/', body);
  }

  changeExecutorMany(body: IChangeExecutorManyBody): HttpResponse<void> {
    return this.strictHttpRequest.put('/credits/distribution/', body);
  }

  postRunEnisBot(form: FormData, pk: string): HttpResponse<void> {
    return this.strictHttpRequest.post(`/credits/${pk}/bot/enis/run/`, form, { ContentType: 'multipart/form-data' });
  }

  postRunAisoipBot(form: FormData, pk: string): HttpResponse<void> {
    return this.strictHttpRequest.post(`/credits/${pk}/bot/aisoip/run/`, form, { ContentType: 'multipart/form-data' });
  }

  postRunEnisBotMany(form: FormData): HttpResponse<void> {
    return this.strictHttpRequest.post('/credits/bot/enis/run/', form, { ContentType: 'multipart/form-data' });
  }
}
