import { AuthMetaServiceImpl } from '../../services/auth-meta-service/auth-meta-service-impl';
import { TOrganizationUserInfo } from '../../models/permission';
import { reduceUserPermissions } from './reduce-user-permissions';

// Запись данных permissions в ассоциативный массив currentUserPermissionsMap.
// Где ключ это entity_type, значение code []
export const getUserPermissions = (map: Map<string, string[]>): void => {
  // AuthMetaServiceImpl (Singleton) - получает доступ к мета данным.
  const currentUser = new AuthMetaServiceImpl();
  if (!currentUser?.hasUser()) return;

  const decodeOrganizationUserInfo: TOrganizationUserInfo = currentUser.getUser();
  reduceUserPermissions(map, decodeOrganizationUserInfo?.role?.permissions);

  console.log('CURRENT_USER_PERMISSION_MAP', map);
};
