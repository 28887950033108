import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { globalStateContext } from '@/containers/global-state';
import { SocketNotification } from '../../components/socket-notification';
import { Layout } from '../../layout';

interface PageGuardProps {
    // eslint-disable-next-line react/require-default-props
    canActivate?: () => boolean;
    page: FunctionComponent;
}

export const PageGuard = (props: PageGuardProps): JSX.Element => {
  const {
    canActivate,
    page: Page,
  } = props;
  const { organizationUserInfo } = useContext(globalStateContext);
  const [authorized, setAuthorized] = useState<boolean>(true);
  const location = useLocation();

  useEffect(() => {
    if (organizationUserInfo && organizationUserInfo?.user) {
      if (!canActivate()) {
        setAuthorized(false);
      }
      if (canActivate()) {
        setAuthorized(true);
      }
    }
  }, [location.pathname, organizationUserInfo?.id, authorized]);

  return (
    authorized ? (
      <>
        <Layout>
          <Page />
          <SocketNotification />
        </Layout>
      </>
    ) : <Navigate to="/login" replace />);
};
