import { HttpRequest, HttpResponse } from '@/http-request/model';
import {
	ICreateMediatorParams, ICreditAISOIPSchema, OrderIdCreditDataService, TOrderIdCreditData,
} from './model';

export class OrderIdCreditDataServiceImpl implements OrderIdCreditDataService {
	private readonly strictHttpRequest: HttpRequest;

	constructor(strictHttpRequest: HttpRequest) {
		this.strictHttpRequest = strictHttpRequest;
	}

	get(orderID: string): HttpResponse<TOrderIdCreditData> {
		return this.strictHttpRequest.get<TOrderIdCreditData>(`/credits/${orderID}/info/`);
	}

	putUpdateDaily(orderID: string): HttpResponse<TOrderIdCreditData> {
		return this.strictHttpRequest.put(`/credits/${orderID}/update-daily/`);
	}

	getCreditAISOIP(creditID: string): HttpResponse<ICreditAISOIPSchema> {
		return this.strictHttpRequest.get(`/credits/${creditID}/data/aisoip/`);
	}

	createCreditMediator(params: ICreateMediatorParams): HttpResponse<void> {
		const {
			credit_id,
			...body
		} = params;
		return this.strictHttpRequest.put(`/credits/${credit_id}/mediator-supervisor/`, body);
	}

	mediatorAttachmentsConfirmOtp(creditID: string): HttpResponse<void> {
		return this.strictHttpRequest.get(`/credits/${creditID}/mediator_attachments/confirm-otp/`);
	}

	mediatorAttachmentsAgreement(creditID: string, body: { agree: boolean }): HttpResponse<void> {
		return this.strictHttpRequest.post(`/credits/${creditID}/mediator_attachments/agreement/`, body);
	}
}
