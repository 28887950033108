import React from 'react';

export const LogoutIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 24 24"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M17 16L21 12M21 12L17 8M21 12H7M13 16V17C13 17.7956 12.6839 18.5587
      12.1213 19.1213C11.5587 19.6839 10.7956 20 10 20H6C5.20435
      20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V7C3
      6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6
      4H10C10.7956 4 11.5587 4.31607 12.1213 4.87868C12.6839 5.44129 13 6.20435 13 7V8`}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
