import React, { ReactNode } from 'react';
import './index.scss';

export interface INotificationProps{
  text:ReactNode
  icon:JSX.Element
}

const ErrorNotification = (props:INotificationProps):JSX.Element => {
  const {
    text, icon,
  } = props;
  return (
    <div className="notification">
      <div className="notification__icon">
        {icon}
      </div>
      {text}
    </div>
  );
};

export default ErrorNotification;
