export function b64DecodeUnicode(str: string): string {
  if (!str) return '';
  return decodeURIComponent(atob(str).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
}

export function b64EncodeUnicode(str: string): string {
  if (!str) return '';
  return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(Number(`0x${p1}`))));
}
