import React, { useEffect, useState } from 'react';
import { IAlert } from '@/services/alerts-service/model';
import { useAlertContainer } from '@/containers/alert-container/alert-container';
import RoundedCheckbox from '../../rounded-checkbox/rounded-checkbox';
import { RenderIf } from '../../render-if';
import { NoData } from '../../no-data';
import { LocalLoader } from '../../local-loader';

interface INotificationListProps {
  isOpen: boolean;
}

const NotificationList: React.FC<INotificationListProps> = (props) => {
  const { isOpen } = props;

  const {
    getUnseenAlerts,
    getReadAlerts,
    getUnreadAlerts,
    isLoading,
    markAsReadAlerts,
    markAsSeenAlerts,
  } = useAlertContainer();

  const [unreadAlerts, setUnreadAlerts] = useState<IAlert[]>([]);
  const [readAlerts, setReadAlerts] = useState<IAlert[]>([]);
  const [unseenAlerts, setUnseenAlerts] = useState<IAlert[]>([]);

  useEffect(() => {
    getUnreadAlerts()
      .then((res) => {
        if (res.status === 'OK') {
          setUnreadAlerts(res.data);
        }
      });

    getReadAlerts()
      .then((res) => {
        if (res.status === 'OK') {
          setReadAlerts(res.data);
        }
      });

    getUnseenAlerts()
      .then((res) => {
        if (res.status === 'OK') {
          setUnseenAlerts(res.data);
        }
      });
  }, []);

  const markAlertsAsRead = async (alertIds: number[]): Promise<void> => {
    const res = await markAsReadAlerts(alertIds);
    if (res.status === 'OK') {
      setUnreadAlerts(unreadAlerts.filter((alert) => !alertIds.includes(alert.id)));
      setReadAlerts(
        [
          ...readAlerts,
          ...unreadAlerts
            .filter((alert) => alertIds.includes(alert.id))],
      );
    }
  };

  const markAlertsAsSeen = async (alertIds: number[]): Promise<void> => {
    const res = await markAsSeenAlerts(alertIds);
    if (res.status === 'OK') {
      setUnseenAlerts(unseenAlerts.filter((alert) => !alertIds.includes(alert.id)));
      setUnreadAlerts([
        ...unreadAlerts,
        ...unseenAlerts.filter((alert) => alertIds.includes(alert.id)),
      ]);
    }
  };

  useEffect(() => {
    if (isOpen && unseenAlerts?.length > 0) {
      const unseensIds = unseenAlerts.map((alert) => alert?.id);
      markAlertsAsSeen(unseensIds)
        .then(() => {});
    }
  }, [isOpen, unseenAlerts]);

  return (
    <>
      <LocalLoader isActive={isLoading} />
      <button
        type="button"
        className="notification-popper__all-read-btn"
      >
        <span>Отметить все как прочитанное</span>
      </button>
      <ul>
        {unreadAlerts.map((alert) => (
          <li key={alert.id} className="notification-popper__alert-item">
            <article
              className="alert-article"
            >
              <div>
                {alert.notification_type}
              </div>
              <RoundedCheckbox
                disabled
                onClick={() => markAlertsAsRead([alert?.id])}
                checked={!alert?.is_read}
              />
            </article>
          </li>
        ))}
      </ul>
      <ul>
        {unseenAlerts.map((alert) => (
          <li key={alert.id} className="notification-popper__alert-item">
            <article
              className="alert-article"
            >
              <div>
                {alert.notification_type}
              </div>
              <RoundedCheckbox
                disabled
                checked={!alert?.is_read}
                onClick={() => markAlertsAsRead([alert?.id])}
              />
            </article>
          </li>
        ))}
      </ul>
      <ul>
        {readAlerts.map((alert) => (
          <li key={alert.id} className="notification-popper__alert-item">
            <article
              className="alert-article"
            >
              <div>
                {alert.notification_type}
              </div>
              <RoundedCheckbox
                disabled
                checked={!alert?.is_read}
              />
            </article>
          </li>
        ))}
      </ul>
      <RenderIf
        clause={
          unreadAlerts?.length <= 0
          && unseenAlerts?.length <= 0
          && readAlerts?.length <= 0
        }
      >
        <NoData text="Нет уведомлений" />
      </RenderIf>
    </>
  );
};

export default NotificationList;
