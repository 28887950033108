import React from 'react';
import { Typography } from '../../typography';
import './index.scss';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';

export interface CustomToastContentProps {
  label: string;
  message: string;
}

export const CustomToastContent = (props: CustomToastContentProps): React.JSX.Element => {
  const { message, label } = props;
  const { isDark, isLight, isP2P } = useThemeContext();

  return (
    <div className="container-fluid p-0 custom-toast">
      <div className="row">
        <div className={classnames('col-12 mb-2 custom-toast-label', {
          'tc-ffffff': isDark,
          'tc-000000': isLight || isP2P,
        })}
        >
          <Typography>{label}</Typography>
        </div>
        <div className={classnames('col-12 custom-toast-message', {
          'tc-ffffff': isDark,
          'tc-000000': isLight || isP2P,
        })}
        >
          <Typography>{message}</Typography>
        </div>
      </div>
    </div>
  );
};
