import React from 'react';
import classnames from 'classnames';
import { Typography, TypographyProps } from '../typography';

export const Error: React.FC<TypographyProps> = (
	props,
): React.JSX.Element => {
	const {
		extraClass, children, className, ...typographyProps
	} = props;

	return (
		<Typography
			variant="subBodyRegular"
			color="error"
			extraClass={classnames('order-wrapper-error', extraClass, className)}
			{...typographyProps}
		>
			{children}
		</Typography>
	);
};
