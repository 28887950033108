import {
  IArbitrationAttachment,
  IArbitrationClerk,
  IArbitrationCourt,
  IArbitrationCourtDetail,
  IArbitrationService,
  INotificationSmsParams,
  IPostArbitrationJudges,
} from '@/services/arbitration-service/model';
import { HttpRequest, HttpResponse } from '@/http-request/model';

export class ArbitrationServiceImpl implements IArbitrationService {
  constructor(private httpRequest: HttpRequest) {
    this.httpRequest = httpRequest;
  }

  getArbitrationCourts = (): HttpResponse<IArbitrationCourt[]> => this.httpRequest.get('/arbitration/arbitration_courts/');

  postArbitrationCourts({ court_id, credit_id }: { credit_id: number; court_id: number }): HttpResponse<any> {
    if (!credit_id || !court_id) {
      console.error('credit_id or court_id is not defined');
      return null;
    }

    return this.httpRequest.post(`/arbitration/${credit_id}/arbitration_courts/`, {
      court_id,
    });
  }

  postAssignRolesAddress({ credit_id, body }: IPostArbitrationJudges): HttpResponse<void> {
    if (!credit_id || !body?.judge_id || !body?.clerk_id || !body?.address_id) {
      console.error('credit_id or judge_id is not defined');
      return null;
    }

    return this.httpRequest.post(`/arbitration/${credit_id}/assign-roles-and-address/`, body);
  }

  getArbitrationAttachments(uuid: string): HttpResponse<IArbitrationAttachment[]> {
    return this.httpRequest.get(`/arbitration/arbitration_attachments/${uuid}/`);
  }

  sendAttachmentsSms(credit_id: number): HttpResponse<any> {
    return this.httpRequest.post(`/arbitration/${credit_id}/send-attachments-sms/`);
  }

  sendNotificationSms({ credit_id, ...body }: INotificationSmsParams): HttpResponse<any> {
    return this.httpRequest.post(`/arbitration/${credit_id}/send-notification-sms/`, body);
  }

  getArbitrationClerks(): HttpResponse<IArbitrationClerk[]> {
    return this.httpRequest.get('/arbitration/clerks/');
  }

  getArbitrationCourtDetails(): HttpResponse<IArbitrationCourtDetail> {
    return this.httpRequest.get('/arbitration/arbitration-court/details/');
  }
}
