import React, {
	ChangeEvent, FunctionComponent, memo, MouseEvent, useMemo, useState,
} from 'react';
import InputMask from 'react-input-mask';
import './index.scss';
import { v4 } from 'uuid';
import { FieldAttributes, useField } from 'formik';
import { useThemeContext } from '@/containers/theme-context';
import { EThemeType } from '@/containers/theme-context/model';
import classnames from 'classnames';
import { IconBoxWrapper } from '../icon-wrapper';

export interface InputV2PrefixSuffixProps {
  theme?: EThemeType;
  inputStatus: string;
  onClick?: (e?: MouseEvent) => void;
}

export interface InputV2Props {
  prefix?: FunctionComponent<InputV2PrefixSuffixProps>;
  suffix?: FunctionComponent<InputV2PrefixSuffixProps>;
  disabled?: boolean;
  hasError?: boolean;
  isRelatedElementActive?: boolean;
  placeholder?: string;
  value?: string | number | null;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onClick?: () => void;
  onKeyDown?: (event: any) => void;
  onPrefixClick?: () => void;
  onSuffixClick?: (e?: MouseEvent) => void;
  id?: string;
  mask?: string;
  type?: string;
  multiple?: boolean;
  name?: string;
  extraClass?: string;

  [x: string]: any;
}

export const InputV2 = memo((props: InputV2Props): React.JSX.Element => {
	const {
		suffix: SuffixComponent,
		prefix: PrefixComponent,
		isRelatedElementActive,
		disabled,
		hasError,
		placeholder,
		value = undefined,
		onChange = () => {},
		onBlur = () => {},
		onFocus = () => {},
		onClick = () => {},
		onKeyDown = () => {},
		onPrefixClick = () => {},
		onSuffixClick = () => {},
		id = v4(),
		mask,
		type,
		multiple,
		name,
		extraClass = '',
		...fields
	} = props;
	const { currentTheme } = useThemeContext();

	const [isHovered, setHovered] = useState(false);
	const [isFocused, setFocused] = useState(false);

	const hasPrefix = useMemo(() => Boolean(PrefixComponent), [PrefixComponent]);
	const hasSuffix = useMemo(() => Boolean(SuffixComponent), [SuffixComponent]);
	const isFilled = useMemo(() => Boolean(value), [value]);

	const handleMouseEnteredRoot = (): void => {
		setHovered(true);
	};
	const handleMouseLeftRoot = (): void => {
		setHovered(false);
	};
	const handleFocusOnInput = (): void => {
		setFocused(true);
	};
	const handleBlurInput = (): void => {
		setFocused(false);
	};

	const inputStatus = useMemo<string>(() => {
		if (hasError) {
			return 'has_error';
		}

		if (disabled) {
			return 'disabled';
		}

		if (isFocused || isRelatedElementActive) {
			return 'focused';
		}

		if (isHovered) {
			return 'hovered';
		}

		if (isFilled) {
			return 'filled';
		}

		return 'default';
	}, [isHovered, isFocused, disabled, hasError, isRelatedElementActive]);

	const iconColor = useMemo(() => {
		if (currentTheme === EThemeType.LIGHT || currentTheme === EThemeType.P2P) {
			return '1A1A1A';
		}
		if (currentTheme === EThemeType.DARK) {
			return 'FFFFFF';
		}
		return 'FFFFFF';
	}, [currentTheme]);

	return (
		<label
			htmlFor={id}
			onMouseEnter={handleMouseEnteredRoot}
			onMouseLeave={handleMouseLeftRoot}
			style={{ zIndex: 100 }}
			className={`input-v2 input-v2__size-medium input-v2__theme-${currentTheme} input-v2__colored_status-${inputStatus} ${extraClass}`}
		>
			{hasPrefix ? (
				<div className="input-v2-prefix">
					<IconBoxWrapper
						color={iconColor}
					>
						<PrefixComponent
							inputStatus={inputStatus}
							theme={currentTheme}
							onClick={onPrefixClick}
						/>
					</IconBoxWrapper>
				</div>
			) : (
				<></>
			)}
			<InputMask
				{...fields}
				id={id}
				name={name}
				value={value}
				onChange={onChange}
				disabled={disabled}
				onFocus={handleFocusOnInput}
				onBlur={handleBlurInput}
				onClick={(event) => {
					const target = event.target as HTMLInputElement;
					event.stopPropagation();
					target.focus();
					onClick();
				}}
				onKeyDown={onKeyDown}
				placeholder={placeholder}
				className={classnames('input-v2-input-element', {
					[`input-v2-input-element__theme-${currentTheme}`]: currentTheme,
				})}
				mask={mask}
				type={type}
				multiple={multiple}
			/>
			{hasSuffix ? (
				<div className="input-v2-suffix">
					<SuffixComponent
						inputStatus={inputStatus}
						theme={currentTheme}
						onClick={onSuffixClick}
					/>
				</div>
			) : (
				<></>
			)}
		</label>
	);
});

InputV2.displayName = 'InputV2';

type TypeMyTextInputProps = {
  label: string;
  id: string;
  name: string;
};

export const InputWithLabelValidate = ({
	label,
	id,
	name,
	...props
}: TypeMyTextInputProps): React.JSX.Element => {
	const newProps = {
		...props,
		name,
		id,
	};
	const [field, meta] = useField(newProps);
	return (
		<div className="input-v2">
			<label htmlFor={id || name}>{label}</label>
			<input className="input-v2-input-element" {...field} {...newProps} />
			{meta.touched && meta.error ? (
				<div className="error">{meta.error}</div>
			) : null}
		</div>
	);
};

const InputV2WithMask = ({ ...fields }: FieldAttributes<any>): React.JSX.Element => (
	<InputV2 mask="+99999999999" {...fields} />
);
export { InputV2WithMask };
