import { HttpRequest, HttpResponse } from '@/http-request/model';
import {
  ISignDocumentMediator, ISignDocumentWithEcpParams, OrderIdDocumentDataService, TOrderIdDocumentData,
} from './model';

export class OrderIdDocumentDataServiceImpl implements OrderIdDocumentDataService {
  private readonly strictHttpRequest: HttpRequest;

  constructor(strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  signAttachmentWithEcpSignature(params: ISignDocumentWithEcpParams): HttpResponse<TOrderIdDocumentData> {
    const { orderID, clientEcpSignature, documentID } = params;
    return this.strictHttpRequest.post(`/credits/${orderID}/sign-doc/${documentID}/`, {
      client_ecp_signature: clientEcpSignature,
    });
  }

  get(orderID: string): HttpResponse<TOrderIdDocumentData[]> {
    return this.strictHttpRequest.get<TOrderIdDocumentData[]>(`/credits/${orderID}/attachments/`);
  }

  post(orderID: string, file: FormData): HttpResponse<void> {
    return this.strictHttpRequest.post<void, FormData>(
      `/credits/${orderID}/attachments/`,
      file,
      { 'Content-type': 'multipart/form-data' },
    );
  }

  delete(orderID: string, documentID: number): HttpResponse<TOrderIdDocumentData> {
    return this.strictHttpRequest.delete<TOrderIdDocumentData>(`/credits/${orderID}/attachments/${documentID}/`);
  }

  put(orderID: string, documentID: number, file: FormData): HttpResponse<TOrderIdDocumentData> {
    return this.strictHttpRequest.put<TOrderIdDocumentData, FormData>(
      `/credits/${orderID}/attachments/${documentID}/`,
      file,
      { 'Content-type': 'multipart/form-data' },
    );
  }

  sendSmsAttachments(id: number): HttpResponse<void> {
    return this.strictHttpRequest.get(`/credits/${id}/send-sms/`);
  }

  signDocumentAttachment({ attachment_id, formData }: ISignDocumentMediator): HttpResponse<TOrderIdDocumentData> {
    return this.strictHttpRequest.post(`/credits/${attachment_id}/sign-document/`, formData,
      { ContentType: 'multipart/form-data' });
  }

  getAllDocZip(orderID: string): HttpResponse<File> {
    return this.strictHttpRequest.getFile<File>(`/credits/${orderID}/attachments/attachments/zip/`);
  }
}
