export const hasEveryTypesAndCodes = (
  types: string[],
  codes: string[],
  map: Map<string, string[]>,
): boolean => types
  ?.every((entity) => codes
    ?.every((permission) => map.get(entity)?.includes(permission)));

export const hasEveryType = (
  types: string[],
  code: string,
  map: Map<string, string[]>,
): boolean => types
  ?.every((entity) => map.get(entity)?.includes(code));

// eslint-disable-next-line max-len
export const hasTypeAndCode = (
  type: string,
  code: string,
  map: Map<string, string[]>,
): boolean => map.get(type)?.includes(code);

export const hasEveryCode = (
  type: string,
  codes: string[],
  map: Map<string, string[]>,
): boolean => codes
  ?.every((permission) => map.get(type)?.includes(permission));

export const hasSomeCode = (
  type: string,
  code: string[],
  map: Map<string, string[]>,
): boolean => code
  ?.some((permissionCode) => map.get(type)
    ?.includes(permissionCode));

export const hasSomeCodesInTypes = (
  types: string[],
  codes: string[],
  map: Map<string, string[]>,
): boolean => types
  ?.every((entity) => codes
    ?.some((permission) => map.get(entity)?.includes(permission)));

// check arrays
export function hasEveryEntity(entityType: string[], map: Map<string, string[]>): boolean {
  return entityType.every((entity) => map.has(entity));
}

export const isNotEmptyArray = (arr: string[]): boolean => !!arr?.length;
