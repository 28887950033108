import { HttpRequest, HttpResponse } from '@/http-request/model';
import {
	IDataCollectionService,
	ILoadPortfolioBody,
	ILoadPortfolioToClientBody,
	IPortfolioHavingCredits,
	IPostRoleBody,
	IPutRoleBody,
	TGetUsersParams,
	TOrderRegion,
	TOrderStatusTree,
	TOrganizationUser,
	TPermissions,
	TRole,
	TRoleByID,
	TRolesAvailablePermissions,
	TRolesAvailableStatuses,
	TUsersByRoleId,
	TUserStatus,
} from './model';
import { TOrderStatus } from '../order-status-service/model';

export class DataCollectionServiceImpl implements IDataCollectionService {
	private readonly strictHttpRequest: HttpRequest;

	constructor(strictHttpRequest: HttpRequest) {
		this.strictHttpRequest = strictHttpRequest;
	}

	loadPortfolioToClient(body: ILoadPortfolioToClientBody): HttpResponse<void> {
		return this.strictHttpRequest.post(
			'/credits/load-portfolio-to-client/',
			body,
		);
	}

	loadPortfolio(body: ILoadPortfolioBody): HttpResponse<void> {
		return this.strictHttpRequest.post('/credits/load-portfolio/', body);
	}

	portfolioHavingCredits(): HttpResponse<IPortfolioHavingCredits[]> {
		return this.strictHttpRequest.get('/credits/portfolio/having-credits/');
	}

	getOrderStatusList(): HttpResponse<Array<TOrderStatus>> {
		return this.strictHttpRequest.get('/statuses/');
	}

	getUsers(params: TGetUsersParams): HttpResponse<Array<TOrganizationUser>> {
		const newQuery = new URLSearchParams(
			params as Record<string, string>,
		).toString();

		return this.strictHttpRequest.get(
			`/catalogs/users/${params ? '?' : ''}${newQuery}`,
		);
	}

	getUsersByRoleId(role_id: string): HttpResponse<Array<TUsersByRoleId>> {
		return this.strictHttpRequest.get(`/catalogs/users/${role_id}/`);
	}

	getRegions(): HttpResponse<Array<TOrderRegion>> {
		return this.strictHttpRequest.get('/catalogs/regions/');
	}

	getRoles(): HttpResponse<Array<TRole>> {
		return this.strictHttpRequest.get('/auth/roles/');
	}

	putRoles(id: number, bodyData: IPutRoleBody): HttpResponse<void> {
		return this.strictHttpRequest.put(`/auth/roles/${id}/`, bodyData);
	}

	postRoles(bodyData: IPostRoleBody): HttpResponse<void> {
		return this.strictHttpRequest.post('/auth/roles/', bodyData);
	}

	getRoleByID(id: number): HttpResponse<TRoleByID> {
		return this.strictHttpRequest.get(`/auth/roles/${id}/`);
	}

	getRolesAvailableStatuses(
		id: number,
	): HttpResponse<TRolesAvailableStatuses[]> {
		return this.strictHttpRequest.get(`/auth/roles/${id}/available-statuses/`);
	}

	getPermissions(): HttpResponse<Array<TPermissions>> {
		return this.strictHttpRequest.get('/auth/permissions/');
	}

	getRolesAvailablePermissions(
		id: number,
	): HttpResponse<TRolesAvailablePermissions[]> {
		return this.strictHttpRequest.get(
			`/auth/roles/${id}/available-permissions/`,
		);
	}

	getStatusesByRoles(role_id?: string): HttpResponse<TOrderStatusTree[]> {
		return this.strictHttpRequest.get(
			`/catalogs/roles_with_available_statuses/${role_id}/`,
		);
	}

	getUserStatuses(): HttpResponse<Array<TUserStatus>> {
		return this.strictHttpRequest.get('/catalogs/user_statuses/');
	}

	getUserStatusById(statusId: number): HttpResponse<TUserStatus> {
		return this.strictHttpRequest.get(`/catalogs/user_statuses/${statusId}/`);
	}

	postUserStatuses(name: string): HttpResponse<TUserStatus> {
		return this.strictHttpRequest.post('/organizations/user-status/', {
			name,
		});
	}

	putUserStatuses(statusID: number, name: string): HttpResponse<TUserStatus> {
		return this.strictHttpRequest.put(
			`/organizations/user-status/${statusID}/`,
			{
				name,
			},
		);
	}

	deleteUserStatuses(statusID: number): HttpResponse<void> {
		return this.strictHttpRequest.delete(
			`/organizations/user-status/${statusID}/`,
		);
	}

	putRegions(
		regionID: number,
		body: { name: string; slug: string[] },
	): HttpResponse<TOrderRegion> {
		return this.strictHttpRequest.put(`/catalogs/regions/${regionID}/`, body);
	}
}
