import React, {
  Dispatch, SetStateAction, useCallback, useContext, useMemo, useState,
} from 'react';
import { useToasts } from 'react-toast-notifications';
import { ApplicationContext } from '@/application-context';
import { CustomToastContent } from '@/components/custom-toasts/custom-toast-content';

type toastArgs = {
  label?: string;
  message: string;
  appearance?: 'error' | 'success' | 'warning' | 'info';
};

type TServiceState<T> = {
  serviceName: string;
  id?: string;
  isLoading?: boolean;
  stateDefault?: T;
};

type TServiceStateReturn<T, K> = {
  service: T;
  isMakingAsync: boolean;
  setIsMakingAsync: Dispatch<SetStateAction<boolean>>;
  errors: boolean;
  setErrors: Dispatch<SetStateAction<boolean>>;
  state?: K;
  setState?: Dispatch<K>;
  setStateMany?: any;
  toast: (args: toastArgs) => void;
};

export function useService<T>(serviceName: string): T {
  const { getBean } = useContext(ApplicationContext);
  const memoizedService = useMemo(() => getBean<T>(serviceName), [getBean, serviceName]);
  return memoizedService;
}

type UseServiceToastReturn = (args: toastArgs) => void;

export function useServiceToast(): UseServiceToastReturn {
  const { addToast } = useToasts();

  const memoizedFn = useCallback(({
    label = '',
    message = '',
    appearance = 'error',
  }: toastArgs): void => {
    addToast(<CustomToastContent label={label} message={message} />, {
      appearance,
    });
  }, []);

  return memoizedFn;
}

function useServiceState<T, K>({
  serviceName,
  id,
  isLoading = false,
  stateDefault = null,
}: TServiceState<K>): TServiceStateReturn<T, K> {
  const service = useService<T>(serviceName);
  const toast = useServiceToast();

  const [isMakingAsync, setIsMakingAsync] = useState(isLoading);
  const [errors, setErrors] = useState(false);
  const [state, setState] = useState<K>(stateDefault);

  const memoizedValue = useMemo(() => ({
    service,
    isMakingAsync,
    errors,
    state,
    setIsMakingAsync,
    setErrors,
    setState,
    toast,
  }), [
    service,
    isMakingAsync,
    errors,
    state,
  ]);

  return memoizedValue;
}

export default useServiceState;
