import { HttpRequest, HttpResponse } from '@/http-request/model';
import { IBorrowerResponse, IBorrowerService, IPutBorrowerPhoneNumberParams } from './model';

export class BorrowerServiceImpl implements IBorrowerService {
  constructor(private readonly strictHttpRequest: HttpRequest) {
    this.strictHttpRequest = strictHttpRequest;
  }

  getBorrowerInfo(creditID: string): HttpResponse<IBorrowerResponse> {
    return this.strictHttpRequest.get<IBorrowerResponse>(`/borrowers/${creditID}/info/`);
  }

  putBorrowerPhoneNumber(params: IPutBorrowerPhoneNumberParams): HttpResponse<{
    phone_number: string
  }> {
    const { phoneNumberID, borrowerID, body } = params;
    if (!phoneNumberID) {
      throw new Error('Не передан ID номера заемщика');
    }
    if (!borrowerID) {
      throw new Error('Не передан ID заемщика');
    }
    if (!body?.phone_number) {
      throw new Error('Не передан номер заемщика');
    }

    return this.strictHttpRequest.put(
      `/borrowers/${borrowerID}/phone-number/${phoneNumberID}/`,
      body,
    );
  }
}
