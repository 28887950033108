import { IOrganizationMetaService, TOrganizationMeta } from './model';
import { getItemStorage, setItemStorage } from '../../helpers/storage-helper';

export class OrganizationMetaServiceImpl implements IOrganizationMetaService {
  setOrganizationMeta(organizationMeta: TOrganizationMeta): void {
    setItemStorage('organizationMeta', JSON.stringify(organizationMeta));
  }

  getOrganizationMeta(): TOrganizationMeta {
    if (!this.hasOrganizationMeta()) {
      throw new Error('no organization meta');
    }

    return { ...getItemStorage('organizationMeta') };
  }

  hasOrganizationMeta(): boolean {
    return Boolean(getItemStorage('organizationMeta'));
  }
}
