import { IConfig, IServiceSettings } from '@/services/config-service/model';
import { HttpRequest, HttpResponse } from '@/http-request/model';

export class ConfigServiceImpl implements IConfig {
http: HttpRequest;

constructor(http: HttpRequest) {
	this.http = http;
}

getServiceSettings(): HttpResponse<IServiceSettings> {
	return this.http.get<IServiceSettings>('/config/service-settings/');
}

postServiceSetting(body: Partial<IServiceSettings>): HttpResponse<IServiceSettings> {
	return this.http.post<IServiceSettings, Partial<IServiceSettings>>('/config/service-settings/', body);
}
}
