import React, { useCallback, useLayoutEffect } from 'react';
import { OrderWrapper } from '@/components/order-wrapper';
import { MoonFillIcon } from '@/components/icons/moon-fill';
import { SunFillIcon } from '@/components/icons/sun-fill';
import { InputV2 } from '@/components/input-v2';
import { Field, Form, Formik } from 'formik';
import { GhostButton } from '@/components/ghost-button';
import { TextButton } from '@/components/text-button';
import { useNavigate } from 'react-router-dom';
import { ToggleButton } from '@/components/toggle-button';
import { FEATURE_ORG_THEME, useFeatureUpdate } from '@/containers/feature-container/feature-context';

function FeatureToggleComponent(): React.JSX.Element {
  const [checked, setChecked] = React.useState<boolean>(false);
  const updateFeature = useFeatureUpdate();
  const [auth, setAuth] = React.useState({
    login: '',
    password: '',
  });
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const theme = localStorage.getItem('theme_fd');
    const authStorage = sessionStorage.getItem('auth');

    if (theme) {
      setChecked(JSON.parse(theme) === 'dark');
    }

    if (authStorage) {
      const { login, password, timestamp } = JSON.parse(authStorage);

      if (timestamp && Date.now() - timestamp > 1000 * 60 * 5) {
        sessionStorage.removeItem('auth');
        return;
      }

      setAuth({
        login,
        password,
      });
    }
  }, [localStorage.getItem('theme_fd'), sessionStorage.getItem('auth')]);

  const onCancel = (): void => {
    navigate('/');
  };

  const currentTheme = checked ? 'Темная' : 'Светлая';

  const onChangeToggle = useCallback((state: boolean) => {
    setChecked(state);
    saveThemeInStorage(state);
  }, []);

  const onExit = useCallback(() => {
    sessionStorage.removeItem('auth');
    onCancel();
  }, []);

  const saveThemeInStorage = (status: boolean):void => {
    if (status) {
      updateFeature(FEATURE_ORG_THEME, 'FD');
    } else {
      updateFeature(FEATURE_ORG_THEME, 'P2P');
    }
  };

  if (auth?.login !== 'admin' && auth?.password !== 'Kutur123') {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '70dvh',
        width: '100%',
      }}
      >
        <Formik
          initialValues={auth}
          onSubmit={(values) => {
            setAuth(values);
            sessionStorage.setItem('auth', JSON.stringify({ ...values, timestamp: Date.now() }));
          }}
        >
          {
            () => (
              <OrderWrapper style={{
                maxHeight: '400px',
                maxWidth: '400px',
              }}
              >
                <Form>
                  <OrderWrapper.Title extraClass="text-align-center mb-4">
                    Вход в админ панель
                  </OrderWrapper.Title>
                  <OrderWrapper.Label>
                    Логин
                  </OrderWrapper.Label>
                  <Field name="login" as={InputV2} />

                  <OrderWrapper.Label extraClass="mt-3">
                    Пароль
                  </OrderWrapper.Label>
                  <Field name="password" as={InputV2} />
                  <div className="d-flex align-items-center justify-content-around mt-4">
                    <GhostButton type="submit">
                      Войти
                    </GhostButton>
                    <TextButton onClick={onCancel}>
                      Отмена
                    </TextButton>
                  </div>
                </Form>
              </OrderWrapper>
            )
          }
        </Formik>
      </div>
    );
  }

  return (
    <OrderWrapper>
      <div className="d-flex justify-content-between align-items-center">
        <OrderWrapper.Title extraClass="mb-4">
          Настройка фич-тогглов
        </OrderWrapper.Title>

        <TextButton onClick={onExit} variant="important">
          Выйти
        </TextButton>
      </div>

      <OrderWrapper.Label>
        Тема по умолчанию
      </OrderWrapper.Label>
      <OrderWrapper.Text className="mb-3">
        {currentTheme}
      </OrderWrapper.Text>

      <ToggleButton
        defaultChecked={checked}
        onChange={onChangeToggle}
        icons={{
          checked: <MoonFillIcon />,
          unchecked: <SunFillIcon />,
        }}
      />
    </OrderWrapper>
  );
}

export default FeatureToggleComponent;
