import { EUserAction } from '../../pages/order-v2/order-v2-context/model';
import { TUserAction } from './index';
import { checkUserHasPermission } from '../../pages/utils/checkCurrentUserPermissions';

export const SUPERVISOR_ACTIONS: Array<TUserAction> = [
  {
    id: 1,
    type: EUserAction.DISTRIBUTE,
    name: 'Распределить заявки',
    renderCondition: () => true,
  },
  {
    id: 2,
    type: EUserAction.ADD_ORDER_NUMBER,
    name: 'Добавить номер договора',
    renderCondition: () => true,
  },
  {
    id: 3,
    type: EUserAction.GET_MERGED_PDF,
    name: 'Сформировать PDF',
    renderCondition: () => true,
  },
  {
    id: 4,
    type: EUserAction.IN_PROGRESS,
    name: 'Изменить статус',
    renderCondition: () => checkUserHasPermission('credit', 'change_status'),
  },
];

export const SUPERVISOR_DISTRIBUTE_ACTIONS: Array<TUserAction> = [
  {
    id: 5,
    type: EUserAction.DISTRIBUTE,
    name: 'Распределить заявки',
    renderCondition: () => true,
  },
  {
    id: 6,
    type: EUserAction.ADD_ORDER_NUMBER,
    name: 'Добавить номер договора',
    renderCondition: () => true,
  },
  {
    id: 7,
    type: EUserAction.GET_MERGED_PDF,
    name: 'Сформировать PDF',
    renderCondition: () => true,
  },
];

export const SUPERVISOR_CONFIRM_ACTIONS: Array<TUserAction> = [
  {
    id: 8,
    type: EUserAction.TO_CONFIRM,
    name: 'Подтвердить заявку',
    renderCondition: () => true,
  },
  {
    id: 9,
    type: EUserAction.TO_REJECT,
    name: 'Отклонить заявку',
    renderCondition: () => true,
  },
];

export const USERS_ACTIONS: Array<TUserAction> = [
  {
    id: 10,
    type: EUserAction.IN_PROGRESS,
    name: 'Изменить статус',
    renderCondition: () => checkUserHasPermission('credit', 'change_status'),
  },
  {
    id: 12,
    type: EUserAction.EXECUTIVE_INSCRIPTION,
    name: 'Выпуск исполнительной надписи',
    renderCondition: () => checkUserHasPermission(['credit'], ['execute_enis_bot']),
  },
];

export const ORDER_UNARCHIVE_ACTIONS: Array<TUserAction> = [
  {
    id: 11,
    type: EUserAction.UNARCHIVE,
    name: 'Вернуть из архива',
    renderCondition: () => true,
  },
];
