import React, { ElementType, ReactNode } from 'react';
import { useThemeContext } from '@/containers/theme-context';
import classnames from 'classnames';
import './index.scss';

export type TypographyVariant =
  | 'titleBold'
  | 'middleTitleBold'
  | 'subtitleBold'
  | 'subtitleRegular'
  | 'bodyBold'
  | 'bodyRegular'
  | 'bodyRegularText'
  | 'subBodyBold'
  | 'subBodyRegular'
  | 'subBodyRegularText'
  | 'captionBold'
  | 'captionRegular'
  | 'captionRegularText';

export type TypographyColor = 'default'
        | 'error'
        | 'primary'
        | 'secondary'
        | 'grey'
        | 'white'
        | 'black'
        | 'text'
        | 'label'
        | 'label-secondary';
export type TypographyWeight = '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900';

export interface TypographyProps {
  children?: ReactNode;
  variant?: TypographyVariant;
  extraClass?: string;
  as?: ElementType;
  color?: TypographyColor;
  weight?: TypographyWeight;

  [key: string]: any;
}

export const Typography = (props: TypographyProps): JSX.Element => {
	const {
		children,
		variant = 'bodyRegular',
		extraClass = '',
		as: Tag = 'p',
		color = 'default',
		weight,
		className,
		...rest
	} = props;
	const { currentTheme } = useThemeContext();

	return (
		<Tag
			className={classnames(`typography typography__variant-${variant}`, {
				'typography__theme-light': currentTheme === 'light',
				'typography__theme-dark': currentTheme === 'dark',
				'typography__theme-p2p': currentTheme === 'p2p',
				[`typography__color-${color}`]: color,
				[`typography__weight-${weight}`]: weight,
			}, [extraClass, className])}
			{...rest}
		>
			{children}
		</Tag>
	);
};
