import { HttpRequest, HttpResponse } from '@/http-request/model';
import { TOrganizationUserInfo } from '@/models/permission';
import { TOrganizationUser } from '@/services/data-collection-service/model';
import {
	IMediator,
	ISupervisor,
	OrganizationsService,
	TCreateOrganizationUserRequest,
	TOrganization,
	TOrganizationMeta,
	TOrganizationUserBody,
} from './model';
import { IAuthMetaService } from '../auth-meta-service/model';

export class OrganizationsServiceImpl implements OrganizationsService {
	private readonly strictHttpRequest: HttpRequest;

	private readonly weakHttpRequest: HttpRequest;

	private readonly authMeta: IAuthMetaService;

	// eslint-disable-next-line max-len
	constructor(strictHttpRequest: HttpRequest, weakHttpRequest: HttpRequest, authMeta: IAuthMetaService) {
		this.strictHttpRequest = strictHttpRequest;
		this.weakHttpRequest = strictHttpRequest;
		this.authMeta = authMeta;
	}

	list(): HttpResponse<TOrganization[]> {
		return this.weakHttpRequest.get('/organizations/list/');
	}

	select(organizationID: number): HttpResponse<TOrganizationMeta> {
		return this.strictHttpRequest.post<TOrganizationMeta, { id: number }>(
			'/organizations/select/',
			{ id: organizationID },
		)
			.then((response) => {
				if (response.status === 'OK') {
					this.authMeta.setOrgAuthMeta(response.data);
					return {
						status: 'OK',
						data: response.data,
					};
				}
				return {
					status: 'ERROR',
					error: response.error,
				};
			});
	}

	usersCurrentInfo(): HttpResponse<TOrganizationUserInfo> {
		return this.strictHttpRequest.get('/organizations/users/current/info/');
	}

	createUser(request: TCreateOrganizationUserRequest): HttpResponse<TOrganizationUser> {
		return this.strictHttpRequest.post(
			'/organizations/users/',
			request,
		);
	}

	deleteOrgUser(pk: number): HttpResponse<void> {
		return this.strictHttpRequest.delete(`/organizations/users/${pk}/`);
	}

	editOrgUser(pk: number, body: TOrganizationUserBody): HttpResponse<TOrganizationUser> {
		return this.strictHttpRequest.put(`/organizations/users/${pk}/`, body);
	}

	getMediators(): HttpResponse<IMediator[]> {
		return this.strictHttpRequest.get('/organizations/mediators/');
	}

	getSupervisors(): HttpResponse<ISupervisor[]> {
		return this.strictHttpRequest.get('/organizations/supervisors/');
	}
}
